import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { format } from "date-fns";
import HashLoader from "react-spinners/HashLoader";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from 'react-redux';

const SummaryHoursWiseProductivity = (props) => {
  let [loading, setLoading] = useState(false);
  const [hrsdata, sethrsdata] = useState([]);

  const [config, setconfig] = useState({});
  const istheme = useSelector(state => state.auth.currentTheme)

  useEffect(() => {
      setchartdata()  
  }, [istheme]);


  useEffect(() => {
    // getAppData(format(new Date(props.date), "yyyy-MM-dd"), props.username);
    // console.log(props.proddata.prodper);
    // console.log(props);
    if(props.attdata.present!=undefined){
      setchartdata()  
    }  
  }, [props.attdata]);
  
  const setchartdata = () => {
    setLoading(true);
    let totalmembers = props.attdata.totalmembers;
      let absent = props.attdata.absent;
      let onleave = props.attdata.onleave;
      let present = props.attdata.present;
      let arr = [Number(totalmembers),Number(present),Number(absent),Number(onleave)]
      // console.log(istheme);
      // var li = document.getElementById('html');
      // alert(li.className);
  
      if(istheme == 1){
          setconfig({
              chart: {
                type: "column",
                backgroundColor: '#1B253B',
                style: {
                  color: "#fff"
                }
              },
              plotOptions: {
                column: {
                    colorByPoint: true
                }
              },
              colors: [
                '#FFC435', 
                ],
              credits: {
                enabled: false,
              },
              title: {
                text: "Daily Attendance",
                style: {
                  color: "#fff"
                }
              },
              xAxis: {
                categories: ['Total Employee','Present Employee','Absent Employee','Onleave Employee'],
                title: {
                  text: "",
                },
              },
              series: [
                {
                  name: "Attendance",
                  // data: [String(props.proddata.prodper), String(props.proddata.prodper), String(props.proddata.idleper)],
                  data: arr,
                },
              ],
            });
          
        }else{
          setconfig({
            chart: {
              type: "column",
              backgroundColor: '#fff',
              style: {
                color: "#000"
              }
            },
            plotOptions: {
              column: {
                  colorByPoint: true
              }
          },
            colors: [
              '#FFC435', 
              ],
            credits: {
              enabled: false,
            },
            title: {
              text: "Daily Attendance",
              style: {
                color: "#000"
              }
            },
            xAxis: {
              categories: ['Total Employee','Present Employee','Absent Employee','Onleave Employee'],
              title: {
                text: "",
              },
            },
            series: [
              {
                name: "Attendance",
                // data: [String(props.proddata.prodper), String(props.proddata.prodper), String(props.proddata.idleper)],
                data: arr,
              },
            ],
          });
        }
        setLoading(false);     
  }
  return (
    <>
      {loading ? (
        <div className="mt-5 " style={{ display: "-webkit-inline-box" }}>
          <HashLoader
            color="#5755d9"
            size={30}
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        </div>
      ) : (
        <div className="intro-y grid grid-cols-12 gap-5 mt-2">
          <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
            {/* {hrsdata && hrsdata.length > 0 ? ( */}
              <HighchartsReact highcharts={Highcharts} options={config} />
            {/* ) : (
              <div className="box p-5 text-danger">No Data Available</div>
            )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default SummaryHoursWiseProductivity;
