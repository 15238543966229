import { all, takeEvery, put, fork } from "redux-saga/effects";

import {
  GETSCREENSHOT,
  GETPRODUCTIVITY,
  GETATTANDANCE,
  GETLEAVES,
} from "../constants/Constant";

import {
  SetLeaves,
  SetScreenshot,
  SetProductivity,
  hideLoading,
  SetAttandance,
} from "../actions/Action";

export function* getAllScreenshotbyDate() {
  yield takeEvery(GETSCREENSHOT, function* ({ payload }) {
    const { date } = payload;

    try {
      let logindata = JSON.parse(localStorage.getItem("isAuth"));
      const user = yield fetch("https://portal.rayvat.com/api/get-screenshot", {
        method: "POST",
        body: JSON.stringify({
          username: logindata[0]["username"],
          date,
        }),
      });

      const data = yield user.json();

      if (user) {
        yield put(SetScreenshot(data));
      }
    } catch (err) {
      yield put(SetScreenshot(err));
    }
    hideLoading();
  });
}

export function* getProductivitybyDate() {
  yield takeEvery(GETPRODUCTIVITY, function* ({ payload }) {
    const { date } = payload;

    try {
      let logindata = JSON.parse(localStorage.getItem("isAuth"));
      const user = yield fetch(
        "https://portal.rayvat.com/api/user-totalworkingtime",
        {
          method: "POST",
          body: JSON.stringify({
            username: logindata[0]["username"],
            date,
          }),
        }
      );

      const data = yield user.json();

      if (user) {
        yield put(SetProductivity(data));
      }
    } catch (err) {
      yield put(SetProductivity(err));
    }
    hideLoading();
  });
}

export function* GetAttendancedata() {
  yield takeEvery(GETATTANDANCE, function* ({ payload }) {
    const { month, year } = payload.date;

    try {
      let logindata = JSON.parse(localStorage.getItem("isAuth"));
      const user = yield fetch(
        "https://portal.rayvat.com/api/get-monthwise-attendance",
        {
          method: "POST",
          body: JSON.stringify({
            username: logindata[0]["username"],
            user_id: logindata[0]["user_id"],
            month,
            year,
            company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
          }),
        }
      );

      const data = yield user.json();

      if (user) {
        yield put(SetAttandance(data));
      }
    } catch (err) {
      yield put(SetAttandance(err));
    }
    hideLoading();
  });
}

export function* GetLeavesdata() {
  yield takeEvery(GETLEAVES, function* ({ payload }) {
    const { year } = payload.year;

    try {
      let logindata = JSON.parse(localStorage.getItem("isAuth"));
      const user = yield fetch(
        "https://portal.rayvat.com/api/get-yearwise-leavedata",
        {
          method: "POST",
          body: JSON.stringify({
            username: logindata[0]["username"],
            user_id: logindata[0]["user_id"],
            year,
          }),
        }
      );

      const data = yield user.json();

      if (user) {
        yield put(SetLeaves(data));
      }
    } catch (err) {
      yield put(SetLeaves(err));
    }
    hideLoading();
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllScreenshotbyDate),
    fork(getProductivitybyDate),
    fork(GetAttendancedata),
    fork(GetLeavesdata),
  ]);
}
