import React from "react";
import Chart from "react-apexcharts";
import equal from "fast-deep-equal";

class ApexChart extends React.Component {
  componentDidUpdate(prevProps) {
    if (!equal(this.props.data, prevProps.data)) {
      // console.log(this.props.data[0].value);
      // console.log(this.props.data);
      this.setState(
        {
          series: this.props.data[0].value,
          options: {
            chart: {
              width: "100%",
              customScale: 0.8,
              type: "pie",
            },
            legend: {
              position: "bottom",
              fontSize: "10px",
            },
            labels: this.props.data[0].appname,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 100,
                  },
                },
              },
            ],
          },
        }
      )
      // console.log(prevProps.data);
      // this.setState({ series: this.props.data[0].value });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      series: props.data[0].value,
      options: {
        chart: {
          width: "100%",
          customScale: 0.8,
          type: "pie",
        },
        legend: {
          position: "bottom",
          fontSize: "10px",
        },
        labels: props.data[0].appname,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 100,
              },
            },
          },
        ],
      },
    };
  }

  render() {
    return (
      <div id="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="pie"
        />
      </div>
    );
  }
}

export default ApexChart;
