import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { useParams } from "react-router-dom";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import "react-month-picker/css/month-picker.css";
import DatePicker from "react-date-picker";
import { format } from "date-fns";
import { FaCalendar} from "react-icons/fa";
import Modal from "react-modal";
Modal.setAppElement("#root");

const Feeds = () => {
  const [attdata, setattdata] = useState(null);
  const [imgsrc, setimgsrc] = useState("");
  const [isopen, setIsOpen] = useState(null);
  const [date, setdate] = useState(new Date());
  const params = useParams();

  useEffect(() => {
    getData("");
  }, []);

  const handledate = (e) => {
    setdate(e);
    let date = format(new Date(e), "yyyy-MM-dd");

    getData(date);
  };

  const columns = [
    { title: "Action", field: "action" },
    { title: "Date Time", field: "datetime" },
    { title: "Friendly Date", field: "friendlydate" },
  ];

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async (date) => {
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-feeds/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        uuid: params.uuid,
        date,
        type: 0,
        company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setattdata(res.data);
        } else {
          setattdata([
            {
              action: "No data Available",
              datetime: "",
              friendlydate: "",
            },
          ]);
        }
      })
      .catch((err) => console.log(err));
  };

  const rowClick = (e, row) => {
    const rowData = row._row.data;
    // console.log(rowData);
    setimgsrc(
      `https://portal.rayvat.com/assets/uploads/screenshot/${rowData.username}/${rowData.date}/${rowData.screenshot}`
    );
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center">
        <h2 className="text-lg font-medium mr-auto">Feeds</h2>
      </div>

      <div className="intro-y box p-5 mt-5">
        <div
          className="overflow-x-auto h-50 scrollbar-hidden"
          style={{ minHeight: "700px" }}
        >
          <div className="h-auto">
            <div className="text-left">
              <label>Select Date</label> <br></br>
              <DatePicker
                clearIcon
                calendarIcon={
                  <FaCalendar style={{ color: "rgb(30 64 175)" }} />
                }
                onChange={handledate}
                value={date}
              />
            </div>
          </div>
          <ReactTabulator
            columns={columns}
            data={attdata}
            events={{
              rowClick: rowClick,
            }}
            options={options}
            className="mt-5 table-report table-report--tabulator"
          />
        </div>
      </div>
    </>
  );
};

export default Feeds;
