import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { format } from "date-fns";
import DatePicker from "react-date-picker";

const PunchInForm = ({ BASEURL, userdata }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState({
    punch_start_time: "",
    punch_end_time: "",
    lunch_start_time: "",
    lunch_end_time: "",
    reason: "",
  });
  const [updateShow, setUpdateShow] = useState(false);

  const handleDateChange = (date) => setSelectedDate(date);

  const handlechange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const fetchPunchInData = async () => {
    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      if (userdata?.usertoken) {
        headers["Authorization"] = userdata.usertoken;
      }
      const response = await fetch(`${BASEURL}get-punch-in-data`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          date: formattedDate,
          username: userdata.username,
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        const attendance = responseData?.attendanceData?.[0] || {};
        const breakInfo = responseData?.breakData?.[0] || {};
        setData({
          punch_start_time: attendance.start_time || "00:00:00",
          punch_end_time: attendance.end_time || "00:00:00",
          lunch_start_time: breakInfo.break_start_time || "00:00:00",
          lunch_end_time: breakInfo.break_end_time || "00:00:00",
          reason: "",
        });
        swal("Success", "Punch-in data fetched successfully!", "success");
        setUpdateShow(true);
      } else {
        throw new Error("Failed to fetch punch-in data.");
      }
    } catch (error) {
      swal("Error", "Could not fetch punch-in data. Try again later.", "error");
    }
  };
useEffect(()=>{
    fetchPunchInData()
},[])
  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = updateShow ? `${BASEURL}update-ticket` : `${BASEURL}create-ticket`;
    const method = updateShow ? "PUT" : "POST";
    try {
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: userdata?.usertoken || "",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        swal("Success", `Ticket ${updateShow ? "updated" : "created"}!`, "success");
      } else {
        throw new Error("Failed to save ticket.");
      }
    } catch (error) {
      swal("Error", "Could not save ticket. Try again later.", "error");
    }
  };

  const handleCancel = () => {
    setData({
      punch_start_time: "",
      punch_end_time: "",
      lunch_start_time: "",
      lunch_end_time: "",
      reason: "",
    });
    setUpdateShow(false);
  };

  return (
    <div className="flex-1 mt-6 xl:mt-0 p-3 mb-2" style={{ border: "1px solid lightgray", borderRadius: "5px" }}>
      <form method="POST" onSubmit={handleSubmit}>
        <div className="grid grid-cols-12 gap-x-5">
          <div className="lg:col-span-6 md:col-span-6 2xl:col-span-2 col-span-12 mt-3 mb-3">
            <label htmlFor="date" className="form-label">
              Date
            </label>
            <DatePicker value={selectedDate} onChange={handleDateChange} name="date" />
          </div>
          <div className="lg:col-span-6 col-span-12 mt-3 mb-3">
            <label className="form-label">Punch Start Time</label>
            <input
              type="text"
              required
              name="punch_start_time"
              className="form-control"
              onChange={handlechange}
              value={data.punch_start_time}
            />
          </div>
          <div className="lg:col-span-6 col-span-12 mt-3 mb-3">
            <label className="form-label">Punch End Time</label>
            <input
              type="text"
              required
              name="punch_end_time"
              className="form-control"
              onChange={handlechange}
              value={data.punch_end_time}
            />
          </div>
          <div className="lg:col-span-6 col-span-12 mt-3 mb-3">
            <label className="form-label">Lunch Start Time</label>
            <input
              type="text"
              required
              name="lunch_start_time"
              className="form-control"
              onChange={handlechange}
              value={data.lunch_start_time}
            />
          </div>
          <div className="lg:col-span-6 col-span-12 mt-3 mb-3">
            <label className="form-label">Lunch End Time</label>
            <input
              type="text"
              required
              name="lunch_end_time"
              className="form-control"
              onChange={handlechange}
              value={data.lunch_end_time}
            />
          </div>
          <div className="col-span-12 mt-3 mb-3">
            <label className="form-label">Reason</label>
            <textarea
              name="reason"
              required
              className="form-control"
              onChange={handlechange}
              value={data.reason}
            ></textarea>
          </div>
        </div>
        <button type="submit" className={`btn ${updateShow ? "btn-success" : "btn-primary"} mt-3`}>
          {updateShow ? "Update" : "Save"}
        </button>
        <button type="button" onClick={handleCancel} className="btn btn-danger mt-3 ml-2">
          Cancel
        </button>
      </form>
    </div>
  );
};

export default PunchInForm;
