import React from "react";
import Chart from "react-apexcharts";
import equal from "fast-deep-equal";

class ApexChart extends React.Component {
  componentDidUpdate(prevProps) {
    if (!equal(this.props.data, prevProps.data)) {
      this.setState({
        series: this.props.data,
        options: {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "70%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: this.props.hrs,
          },
          yaxis: {
            title: {
              text: "Duration in Minutes",
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "Duration - " + val + " Min";
              },
            },
          },
        },
      });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      series: props.data,
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "70%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: props.hrs,
        },
        yaxis: {
          title: {
            text: "Duration in Minutes",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "Duration - " + val + " Min";
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div id="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}

export default ApexChart;
