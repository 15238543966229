import React, { useEffect, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import DatePicker from "react-date-picker";
import { format } from "date-fns";
import { BASEURL } from "../BASEURL";
import SummaryScreenshots from "./SummaryScreenshots";
import SummaryApplications from "./SummaryApplications";
import SummaryHoursWiseProductivity from "./SummaryHoursWiseProductivity";
import HashLoader from "react-spinners/HashLoader";
import { GrNext, GrPrevious } from "react-icons/gr";
import { useLoadingContext } from "react-router-loading";
import Select from "react-select";
import reload from "../../reload.png";

const Summary = React.memo((props) => {
	// const Summary = () => {
	const loadingContext = useLoadingContext();
	let [loading, setLoading] = useState(false);
	const [date, setdate] = useState(new Date());
	const [dateval, setdateval] = useState(new Date());
	const [userdatalist, setuserdata] = useState([]);
	const [username, setusername] = useState();
	const [summary, setSummary] = useState([]);
	const [weeksummary, setWeekSummary] = useState([]);
	const [weekdata, setWeekData] = useState([]);
	const [alluser, setalluser] = useState(null);
	const [selectedval, setselectedval] = useState("");
	const [orgs, setorgs] = React.useState([]);
	const [master] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).master
	);
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);
	const [orgval, setorgval] = React.useState([]);

	useEffect(() => {
		setorgval({
			value: selectedorg,
			label: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
				.name,
		});
		if (master === 1) {
			getOrganizations();
		}
		// setdate(new Date());
		getUserData();
	}, []);

	const reloadFun = () => {
		getSummary(username, date);
		getScreenshotData(username, date);
	};

	const getappdata = (data) => {};

	const getUserData = async (org = "") => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-getAllUsers/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 1,
				// uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
				uuid: org !== "" ? org : selectedorg,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				department: JSON.parse(localStorage.getItem("isAuth"))
					.department,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setuserdata(res.data);
					console.log(res.data);
					setalluser(res.alluser);
					if (res.alluser.length > 0 && res.data.length > 0) {
						setusername(res.alluser[0]["username"]);
						getSummary(res.alluser[0]["username"], date, res.data);
					} else {
						let userdata = JSON.parse(
							localStorage.getItem("isAuth")
						);
						getSummary(userdata.username, date, res.data);
					}
				}
				setLoading(false);
			})
			.catch((err) => console.log(err));

		loadingContext.done();
	};

	const getSummary = async (username, date, users = []) => {
		if (
			JSON.parse(localStorage.getItem("isAuth")).type == "0" ||
			JSON.parse(localStorage.getItem("isAuth")).type == "1"
		) {
			// console.log(users);
			if (username != "" && users && users.length > 0) {
				let res = await users.filter((el) => {
					return Number(username) === Number(el.username);
				});
				// console.log(res);
				setselectedval({
					value: username,
					label: res[0].name,
					active: "🟢",
				});
			}
		}
		setorgval({
			value: selectedorg,
			label: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
				.name,
		});
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-Summary/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				date,
				username,
				company_uuid: JSON.parse(localStorage.getItem("isAuth"))
					.companydata[0].uuid,
				master: JSON.parse(localStorage.getItem("isAuth")).master,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setusername(username);
					setSummary(res.summary);
					setWeekSummary(res.weeksummary);

					setWeekData(res.weeklydata);
				} else {
					setSummary([]);
					setWeekSummary([]);
					setWeekData([]);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};

	const getOrganizations = async () => {
		setLoading(true);

		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-organizations/`, {
			method: "POST",
			headers: myHeaders,
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setorgs(res.data);
				} else {
					setorgs([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const getorg = async (org) => {
		setorgval(org);
		org = org.value;

		setselectedorg(org);
		getUserData(org);
	};

	const handledate = (e) => {
		let date;
		if (e !== null) {
			setdate(e);

			date = format(new Date(e), "yyyy-MM-dd");
			setdateval(new Date(e));
		} else {
			date = format(new Date(), "yyyy-MM-dd");
			setdateval(new Date());
		}
		getSummary(username, date);
		getScreenshotData(username, date);
		getSummaryProductivity();
	};

	const nexTDay = () => {
		let tomorrow = date;
		tomorrow.setDate(date.getDate() + 1);
		setdate(tomorrow);
		let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
		setdateval(new Date(tomorrow));
		getScreenshotData(username, date2);
		getSummary(username, date2);
		getSummaryProductivity();
	};

	const prevDay = () => {
		let tomorrow = date;
		tomorrow.setDate(date.getDate() - 1);
		setdate(tomorrow);
		setdateval(new Date(tomorrow));
		let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
		getSummary(username, date2);
		getScreenshotData(username, date2);
		getSummaryProductivity();
	};

	const handleuser = (e) => {
		// console.log(e);return
		setselectedval(e);
		setusername(e.value);
		getSummary(e.value, date);
		getScreenshotData(e.value, date);
		getSummaryProductivity();
	};

	const getScreenshotData = (username, date1) => {
		let date = format(new Date(date1), "yyyy-MM-dd");
		return <SummaryScreenshots username={username} date={date} />;
	};

	const getSummaryApplications = () => {
		return (
			<SummaryApplications
				getappdata={getappdata}
				username={username}
				date={date}
			/>
		);
	};

	const getSummaryProductivity = () => {
		// if(selusername==""){
		//  let username = username;
		// }
		// if(seldate==""){
		//  let date = date;
		// }
		// console.log(username);
		return <SummaryHoursWiseProductivity username={username} date={date} />;
	};

	const filterByReference = (arr1, arr2) => {
		let res = [];
		res = arr1.filter((el) => {
			return !arr2.find((element) => {
				return Number(element.username) === Number(el.username);
			});
		});
		return res;
	};

	const filterByReference2 = (arr1, arr2) => {
		let res = [];
		res = arr1.filter((el) => {
			return arr2.find((element) => {
				return Number(element.username) === Number(el.username);
			});
		});
		return res;
	};
	let filterarr = filterByReference(userdatalist, alluser);
	let filterarr2 = filterByReference2(userdatalist, alluser);

	if (filterarr2 && filterarr2.length > 0) {
		for (let i = 0; i < filterarr2.length; i++) {
			filterarr2[i].active2 = true;
		}
	}

	let finalarr3 = filterarr2.concat(filterarr);
	let data = [{ value: "", label: "Select All", active: "" }];
	for (let i = 0; i < finalarr3.length; i++) {
		data.push({
			value: finalarr3[i].username,
			label: finalarr3[i].name,
			active: finalarr3[i].active2 ? "🟢" : "🔴",
		});
	}

	// console.log(data);

	return (
		<>
			<div className="grid grid-cols-12 gap-6">
				<div className="col-span-12 2xl:col-span-12">
					<div className="grid grid-cols-12 gap-6">
						<div className="col-span-12 mt-8 ">
							<h2 className="text-lg font-medium truncate text-left">
								{" "}
								Summary
							</h2>
							<div className="intro-y flex flex-col sm:flex-row1 items-center lg:mt-8">
								<div className="sm:ml-auto flex flex-col sm:flex-row items-center text-primary md:w-auto">
									<div>
										{master === 1 && (
											<Select
												value={orgval}
												className="basic-single mr-2"
												onChange={(e) => {
													getorg(e);
												}}
												options={orgs}
												// getOptionLabel={(option) =>
												//   ` ${option.active}   ${option.label}`
												// }
												styles={{
													option: (base) => ({
														...base,
														// height: "100%",
														display: "flex",
														textAlign: "left",
													}),
												}}
											/>
										)}

										{!loading && (
											<Select
												defaultValue={data[0]}
												value={selectedval}
												className="basic-single mr-2"
												onChange={(e) => {
													handleuser(e);
												}}
												options={data}
												getOptionLabel={(option) =>
													` ${option.active}   ${option.label}`
												}
												styles={{
													option: (base) => ({
														...base,

														height: "100%",
														display: "flex",
													}),
												}}
											/>
										)}

										<DatePicker
											clearIcon=""
											className="mr-2"
											calendarIcon={
												<FaCalendar
													style={{
														color: "rgb(30 64 175)",
													}}
												/>
											}
											onChange={handledate}
											value={dateval}
										/>

										<button className="ml-1 btn btn-default">
											<img
												width={15}
												onClick={reloadFun}
												src={reload}
												alt=""
												srcset=""
											/>
										</button>

										<button
											className="mx-2"
											onClick={prevDay}
										>
											<GrPrevious />
										</button>
										<button
											className="mx-2"
											onClick={nexTDay}
										>
											<GrNext />
										</button>
									</div>
								</div>
							</div>
							{loading === true ? (
								<div className="text-center m-5 p-5">
									<HashLoader
										color="#5755d9"
										size={30}
										style={{ position: "absolute" }}
									/>
								</div>
							) : (
								<div className="grid grid-cols-12 gap-6 mt-5">
									<div className="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
										<div className="zoom-in">
											<div className="box p-1 pb-3">
												<div className="text-xl font-medium leading-8 mt-4">
													{summary &&
													summary.length > 0
														? summary[0].in
														: "00:00:00"}
												</div>
												<div className="mt-1">
													Punch In
												</div>
											</div>
										</div>
									</div>
									<div className="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
										<div className="zoom-in">
											<div className="box p-1 pb-3">
												<div className="text-xl font-medium leading-8 mt-4">
													{summary &&
													summary.length > 0
														? summary[0].out
														: "00:00:00"}
												</div>
												<div className="mt-1">
													Punch Out
												</div>
											</div>
										</div>
									</div>
									<div className="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
										<div className="zoom-in">
											<div className="box p-1 pb-3">
												<div className="text-xl font-medium leading-8 mt-4">
													{summary &&
													summary.length > 0
														? summary[0].duration
														: "00:00:00"}
												</div>
												<div className="mt-1">
													Working Time
												</div>
											</div>
										</div>
									</div>
									<div className="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
										<div className="zoom-in">
											<div className="box p-1 pb-3">
												<div className="text-xl font-medium leading-8 mt-4">
													{summary &&
													summary.length > 0
														? summary[0].prodtime
														: "00:00:00"}
												</div>
												<div className="mt-1">
													Productive Time
												</div>
											</div>
										</div>
									</div>
									<div className="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
										<div className="zoom-in">
											<div className="box p-1 pb-3">
												<div className="text-xl font-medium leading-8 mt-4">
													{summary &&
													summary.length > 0
														? summary[0].idletime
														: "00:00:00"}
												</div>
												<div className="mt-1">
													Idle Time
												</div>
											</div>
										</div>
									</div>
									<div className="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
										<div className="zoom-in">
											<div className="box p-1 pb-3">
												<div className="text-xl font-medium leading-8 mt-4">
													{summary &&
													summary.length > 0 &&
													summary[0].prodper !== "NaN"
														? summary[0].prodper
														: "00:00:00"}
												</div>
												<div className="mt-1">
													Productivity (%)
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>

						<div
							className="col-span-12 lg:col-span-12 "
							style={{ position: "relative", zIndex: 0 }}
						>
							<div
								className="intro-y block sm:flex items-center h-5"
								style={{ zIndex: 0 }}
							>
								<h2 className="text-lg font-medium truncate mr-5">
									Weekly Analytics
								</h2>
							</div>

							{loading === true ? (
								<div className="text-center m-5 p-5">
									<HashLoader
										color="#5755d9"
										size={30}
										style={{ position: "absolute" }}
									/>
								</div>
							) : (
								<>
									<div className="intro-y mt-12 sm:mt-5">
										<div className="grid grid-cols-12 gap-6 mt-5">
											<div className="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
												<div className="zoom-in">
													<div className="box p-1">
														<div className="text-xl font-medium leading-8 mt-4">
															{weeksummary &&
															weeksummary.length >
																0
																? weeksummary[0]
																		.duration
																: "00:00:00"}
														</div>
														<div className="mt-1">
															Total Working
														</div>
													</div>
												</div>
											</div>
											<div className="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
												<div className="zoom-in">
													<div className="box p-1">
														<div className="text-xl font-medium leading-8 mt-4">
															{weeksummary &&
															weeksummary.length >
																0
																? weeksummary[0]
																		.prodtime
																: "00:00:00"}
														</div>
														<div className="mt-1">
															Productive Time{" "}
														</div>
													</div>
												</div>
											</div>
											<div className="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
												<div className="zoom-in">
													<div className="box p-1">
														<div className="text-xl font-medium leading-8 mt-4">
															{weeksummary &&
															weeksummary.length >
																0
																? weeksummary[0]
																		.idletime
																: "00:00:00"}
														</div>
														<div className="mt-1">
															Idle Time
														</div>
													</div>
												</div>
											</div>
											<div className="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
												<div className="zoom-in">
													<div className="box p-1">
														<div className="text-xl font-medium leading-8 mt-4">
															{weeksummary &&
															weeksummary.length >
																0 &&
															weeksummary[0]
																.prodper !==
																"NaN"
																? weeksummary[0]
																		.prodper
																: "0%"}
														</div>
														<div className="mt-1">
															Productivity (%)
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="grid grid-cols-12 gap-6 mt-5 pb-3">
											{weekdata &&
												weekdata.length > 0 &&
												weekdata.map((item, i) => {
													return (
														<div
															className="col-span-12 sm:col-span-6 xl:col-span-2 intro-y"
															key={i}
														>
															<div className="intro-y box">
																<div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
																	<small>
																		Date:{" "}
																		{
																			item.date
																		}
																	</small>
																</div>
																<div
																	id="progressbar-height"
																	className="p-3"
																>
																	<div className="preview">
																		<div className="progress h-3 mb-1 mt-0">
																			<div
																				className="progress-bar"
																				style={{
																					width:
																						item.prodper +
																						"%",
																				}}
																				role="progressbar"
																				aria-valuenow="0"
																				aria-valuemin="0"
																				aria-valuemax="100"
																			>
																				{
																					item.prodper
																				}{" "}
																				%
																			</div>
																		</div>
																	</div>
																	<small className="text-left d-flex">
																		{
																			item.duration
																		}{" "}
																		hh:mm
																	</small>
																</div>
															</div>
														</div>
													);
												})}
										</div>
									</div>
								</>
							)}
						</div>

						<div className="col-span-12 xl:col-span-6">
							<div className="intro-y block sm:flex items-center h-10">
								<h2 className="text-lg font-medium truncate mr-5">
									Most Used Application
								</h2>
							</div>
							<div className="intro-y box mt-12 sm:mt-5">
								{getSummaryApplications()}
							</div>
						</div>

						<div className="col-span-12 xl:col-span-6">
							<div className="col-span-12 lg:col-span-12 mt-5 ">
								<div className="intro-y block sm:flex items-center h-5">
									<h2 className="text-lg font-medium truncate mr-5">
										Hour Wise Productivity Statistics
									</h2>
								</div>
								<div className="intro-y sm:mt-5 pb-8">
									{getSummaryProductivity()}
								</div>
							</div>
						</div>

						<div className="col-span-12 lg:col-span-12 mt-2 ">
							<div className="intro-y block sm:flex items-center h-5">
								<h2 className="text-lg font-medium truncate mr-5">
									Hour Wise Screenshots
								</h2>
							</div>

							<div className="col-span-12 lg:col-span-12 mt-8 ">
								<div className="intro-y box mt-12 sm:mt-5 pb-8">
									{getScreenshotData(username, date)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
});

export default Summary;
