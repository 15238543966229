import { Modal } from "bootstrap";
import { requestMediaPermissions } from "mic-check";
import { toast } from "react-toastify";


/**   getObjectCount : This function will Give count of how many data store in Object .*/
export const getObjectCount = (obj) => {
    return Object.keys(obj).length;
}

/*******************   remove Object value by its key name   *************************** */
export const removeObjValByKey = (thisIsObject, objkey) => {
    Object.keys(thisIsObject).filter(fkey =>
        fkey !== objkey).reduce((obj, key) => {
            obj[key] = thisIsObject[key];
            return obj;
        }, {});
}


/// This function used for clean media when call cut or session is terminated
export const cleanupMedia = () => {
    console.debug('cleanupMedia fun calling')
    const mediaElement = document.getElementById("mediaElement");
    // mediaElement.srcObject = remoteVideoElement.srcObject = localVideoElement.srcObject = null;
    const remoteVideoElement = document.getElementById('remoteVideo');
    if (typeof (remoteVideoElement) != 'undefined' && remoteVideoElement != null) {
        remoteVideoElement.srcObject = null;
    }
    const localVideoElement = document.getElementById('localVideo');
    if (typeof (localVideoElement) != 'undefined' && localVideoElement != null) {
        localVideoElement.srcObject = null;
    }
    //If it isn't "undefined" and it isn't "null", then it exists.
    mediaElement.srcObject = null;
    mediaElement.pause();
}


/* pad - calculates whether a number needs leading zero padding or not */
export const pad = (val) => {
    return (val > 9) ? val : '0' + val;
}


/* updateCustomSettings : This function will update custom settings like DND & Autoans. */
export const updateCustomSettings = (setting, ele) => {
    let _customSettings = JSON.parse(localStorage.getItem('_customSettings'));
    _customSettings[setting] = ele;
    localStorage.setItem('_customSettings', JSON.stringify(_customSettings));
}


// this function used for call rington play or pause when call establishing or terminated
export const connectingCallRigton = (option) => {
    const connectingRigton = document.getElementById('connectinfRigton');
    if (option == 'play') {
        connectingRigton.play();
    } else {
        connectingRigton.pause();
    }
}

export const getAccessTocken = () => {
    return localStorage.getItem('access_tocken')
}
export const getReferssTocken = () => {
    return localStorage.get('refresh_tocken')
}

///Check to user Allow audion and video permission
export const detectAudioVideoPermission = async (video = false) => {
    try {
        let res = await requestMediaPermissions({ audio: true, video: video })
        console.debug('f.cmdgvc', res)
        return true
    } catch (err) {
        let message = '';
        switch (err.type) {
            case 'UserPermissionDenied':
                message = video ? 'Please Allow Audio and video Permission' : 'Please Allow Audio Permission';
                break;
            case "Generic":
                message = err.message;
                break;
            default:
                message = err.message
        }
        toast.info(message)
    }
};


//  lastSeenTime: Get last seen hours and minut
export const lastSeenTime = (lastPresenceDate) => {
    var fullDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var presenceDate = new Date(lastPresenceDate);
    var hour = (presenceDate.getHours() < 10) ? '0' + presenceDate.getHours() : presenceDate.getHours();
    var minute = (presenceDate.getMinutes() < 10) ? '0' + presenceDate.getMinutes() : presenceDate.getMinutes();
    var date = presenceDate.getDate();
    var month = months[presenceDate.getMonth()];
    var timeDiff = lastPresenceDate - Date.now();
    var diffDays = new Date().getDate() - date;
    var diffMonths = new Date().getMonth() - presenceDate.getMonth();
    var diffYears = new Date().getFullYear() - presenceDate.getFullYear();
    var onDay = "";
    if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
        onDay = "Today" + " at " + hour + ':' + minute;
    } else if (diffYears === 0 && diffDays === 1) {
        onDay = "Yesterday" + " at " + hour + ':' + minute;
    } else if (diffYears === 0 && (diffDays < -1 && diffDays > -7)) {
        onDay = "On " + fullDays[presenceDate.getDay()] + " at " + hour + ':' + minute;
    } else if (diffYears >= 1) {
        onDay = "On " + month + " " + date + ", " + new Date(lastPresenceDate).getFullYear();
    } else {
        onDay = "On " + month + " " + date;
    }

    let lastSceen = onDay;
    return lastSceen;
}




let modal = '';
export const toggleModal = (toggle) => {
    if (toggle) {
        openModel()
    } else {
        hideModal()
    }
}
const openModel = () => {
    modal = new Modal(document.getElementById('accountSettingModal'));
    modal.show()
}
const hideModal = () => {
    modal.hide();
}