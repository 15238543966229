import React, { useState, useEffect } from "react";
import { BASEURL, SITEURL } from "../BASEURL";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert2";
import HashLoader from "react-spinners/HashLoader";
import {
	FaCalendar,
	FaClone,
	FaFileDownload,
	FaPodcast,
	FaTasks,
	FaUserAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Select from "react-select";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { format } from "date-fns";
import { exportToExcel } from "../ExportToExcel";
import { IoThermometer } from "react-icons/io5";
import Swal from "sweetalert2";

const Departments = () => {
	const [appdata, setappdata] = useState(null);
	const [bkpdata, setbkpdata] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentItems, setCurrentItems] = useState(null);
	const [items, setitems] = useState([]);
	const [posdata, setposdata] = useState([]);
	const [pos, setpos] = useState("");
	const [InterviewStatus, setInterviewStatus] = useState(0);

	let [loading, setLoading] = useState(false);
	// let [msg, setmsg] = useState(false);
	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);
	// const [datevalue, onChange] = useState([
	//   new Date(),
	//   new Date(),
	// ]);

	const [currdate] = useState(new Date());
	const [datevalue, onChange] = useState([
		new Date(currdate.getFullYear(), currdate.getMonth(), 1),
		new Date(),
	]);

	console.log("po",pos)
	useEffect(() => {
		const endOffset = itemOffset + 20;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 20));
		getPosData();
		getData(
			format(new Date(datevalue[0]), "yyyy-MM-dd"),
			format(new Date(datevalue[1]), "yyyy-MM-dd")
		);
	}, []);

	useEffect(() => {
		const endOffset = itemOffset + 20;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 20));
	}, [itemOffset]);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * 20) % items.length;

		setItemOffset(newOffset);
	};

	const furtherrounds = async (uuid) => {
		// alert(http://localhost:3000/view-job-application-status/900875de-0b17-4763-8eee-f032bbdb740b)
		await navigator.clipboard.writeText(
			SITEURL + "view-job-application-status/" + uuid
		);
		// alert(uuid)
		// swal({
		//   title: "Are you sure",
		//   text: `You want to send a link for further rounds to ${name}?`,
		//   buttons: ["No", "Yes"],
		//   showCloseButton: true,
		// }).then((willDelete) => {
		//   if (willDelete) {
		//     setLoading(true);
		//     const myHeaders = new Headers();
		//     let userdata = JSON.parse(localStorage.getItem("isAuth"));
		//     if (userdata != null) {
		//       let token = userdata.usertoken;
		//       myHeaders.append("Authorization", token);
		//     }
		//     myHeaders.append("Content-Type", "application/json");
		//     fetch(BASEURL + `delete-user/${uuid}`, {
		//       method: "DELETE",
		//       headers: myHeaders,
		//     })
		//       .then((response) => response.json())
		//       .then((res) => {
		swal.fire({
			title: "URL copy success. Please send url to candidate to further rounds",
			icon: "success",
			timer: 3000,
		});
		//       })
		//       .catch((err) => console.log(err));
		//     setLoading(false);
		//   }
		// });
	};
	const interviesend = async (uuid) => {
		// alert(http://localhost:3000/view-job-application-status/900875de-0b17-4763-8eee-f032bbdb740b)
		await navigator.clipboard.writeText(
			"https://skillscore.rayvat.com/" + uuid
		);
		// alert(uuid)
		// swal({
		//   title: "Are you sure",
		//   text: `You want to send a link for further rounds to ${name}?`,
		//   buttons: ["No", "Yes"],
		//   showCloseButton: true,
		// }).then((willDelete) => {
		//   if (willDelete) {
		//     setLoading(true);
		//     const myHeaders = new Headers();
		//     let userdata = JSON.parse(localStorage.getItem("isAuth"));
		//     if (userdata != null) {
		//       let token = userdata.usertoken;
		//       myHeaders.append("Authorization", token);
		//     }
		//     myHeaders.append("Content-Type", "application/json");
		//     fetch(BASEURL + `delete-user/${uuid}`, {
		//       method: "DELETE",
		//       headers: myHeaders,
		//     })
		//       .then((response) => response.json())
		//       .then((res) => {
		swal.fire({
			title: "URL copy success. Please send url to candidate to further rounds",
			icon: "success",
			timer: 3000,
		});
		//       })
		//       .catch((err) => console.log(err));
		//     setLoading(false);
		//   }
		// });
	};

	console.log(bkpdata, "bkpdata");
	const exportFile = async () => {
		// Assuming 'bkpdata' holds the entire job application data

		if (bkpdata && bkpdata.length > 0) {
			const downloaddata = bkpdata.map((item) => ({
				Date: item.applicationdate,
				Name: item.name,
				Number: item.contactno,
				Email: item.email,
				Location:
					item.city === "0"
						? "Ahmedabad"
						: item.city === "1"
						? "Gandhinagar"
						: item.city,
				Position: item.position,
				Education: item.education,
				"Experience Yes & Not ": item.currentlyemployeed,
				"Experience Years ": item.totalworkexperience,
				"Current Salary": item.currentsalary,
				"Expected CTC": item.expectedsalary,
				Skill: item.yourskills,
				"Date Of Birth": item.dob,
				"Notice Period": item.noticeperiod,
				Address: item.address,
				"Assessment Status":
					item.isassessmentadded === 1 ? "Done" : "Pending",
				// "Interview Status": getInterviewStatusLabel(item.interviewStatus),
				"Test Score": item.testScore || "-", // Assuming 'testScore' exists
				"Status Datetime": item.interviewStatus_datetime
					? format(
							new Date(item.interviewStatus_datetime),
							"yyyy-MM-dd HH:mm:ss"
					  )
					: "-",
				"Ref By": item.knowvavancy || "-",
				"Application Date": item.applicationdate,
				"CV A/V": item.knowvavancy,
			}));

			await exportToExcel(downloaddata, "JobApplications-Summary", 1);
		} else {
			console.log("No data available for export");
		}
	};

	const interviewStatus = async (uuid, status, comment) => {
		// const inputOptions = new Promise((resolve) => {
		//   setTimeout(() => {
		//     resolve({
		//       "0": "Pending",
		//       "1": "Called",
		//       "2": "HR Round",
		//       "3": "Technical Round",
		//       "4": "Practical Round",
		//       "5": "Final Round",
		//       "6": "Hired",
		//       "7": "Rejected"
		//     });
		//   }, 1000);
		// });

		let htmldata = `<input type="radio" name="options" id="option1" value="0" ${
			status == 0 && "checked"
		}>
        <label for="option1">Pending</label>
        <input type="radio" name="options" id="option2" value="1" ${
			status == 1 && "checked"
		}>
        <label for="option2">Called</label>
        <input type="radio" name="options" id="option3" value="2" ${
			status == 2 && "checked"
		}>
        <label for="option3">HR Round</label>
        <input type="radio" name="options" id="option3" value="3" ${
			status == 3 && "checked"
		}>
        <label for="option3">Technical Round</label>
        <input type="radio" name="options" id="option3" value="4" ${
			status == 4 && "checked"
		}>
        <label for="option3">Practical Round</label>
        <input type="radio" name="options" id="option3" value="5" ${
			status == 5 && "checked"
		}>
        <label for="option3">Final Round</label>
        <input type="radio" name="options" id="option3" value="6" ${
			status == 6 && "checked"
		}>
        <label for="option3">Hired</label>
        <input type="radio" name="options" id="option3" value="7" ${
			status == 7 && "checked"
		}>
        <label for="option3">Rejected</label>        
        <br/><br/>
        // <textarea id="inputBox" class="swal2-input" rows="3" style="width: 100%;" placeholder="Enter your comment">${comment}</textarea>`;

		const { value: fianlval } = await Swal.fire({
			title: "Are you sure you want to change interview status?",
			width: 1200,
			fontSize: 10,
			// input: "radio",
			// inputValue:+status,
			// inputOptions,
			confirmButtonText: "Yes, change it!",
			cancelButtonText: "No, cancel!",
			showCloseButton: true,
			title: "Choose an option",
			html: htmldata,
			preConfirm: () => {
				const selectedOption = document.querySelector(
					'input[name="options"]:checked'
				);
				const inputBox = document.getElementById("inputBox").value;
				if (!selectedOption) {
					// Swal.showValidationMessage('Please select an option');
					alert("Please Select option");
					return false;
				}
				if (!inputBox) {
					// Swal.showValidationMessage('Please enter some text');
					alert("Please Enter comment");
					return false;
				}
				return {
					option: selectedOption.value,
					comment: inputBox,
				};
			},
			// inputValidator: (value) => {
			//   if (!value) {
			//     return "You need to choose something!";
			//   }
			// }
		});
		if (fianlval) {
			// console.log(fianlval);return
			// swal.fire({ html: `You selected: ${fianlval}` });
			setLoading(true);
			const myHeaders = new Headers();
			let userdata = JSON.parse(localStorage.getItem("isAuth"));
			if (userdata != null) {
				let token = userdata.usertoken;
				myHeaders.append("Authorization", token);
			}
			myHeaders.append("Content-Type", "application/json");
			fetch(BASEURL + `interview_status/${uuid}/${fianlval.option}`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({ comment: fianlval.comment }),
			})
				.then((response) => response.json())
				.then((res) => {
					swal.fire({
						title: res.msg,
						icon: "success",
						timer: 3000,
					});
					getData();
				})
				.catch((err) => console.log(err));
			setLoading(false);
		}
	};

	const sendEmail = async (uuid, email, name, selectedOption) => {
		let payload = {
			uuid: uuid,
			rounds_completed: 1,
		};

		console.log(selectedOption);
		switch (selectedOption) {
			case "3":
				payload.is_test_invitation = 1;
				break;
			case "4":
				payload.is_tech_invitation = 1;
				break;
			case "5":
				payload.is_prac_invitation = 1;
				break;
			case "6":
				payload.is_final_invitation = 1;
				break;
			default:
				console.error("Invalid selection");
				return;
		}

		const confirmSend = await Swal.fire({
			title: "Are you sure?",
			text: `Do you want to send an email to ${name}?`,
			showCancelButton: true,
			confirmButtonText: "Yes, send it!",
			cancelButtonText: "No, cancel",
		});

		if (confirmSend.isConfirmed) {
			console.log("Sending email to:", email);
			console.log("UUID:", uuid);

			try {
				const response = await fetch(BASEURL + "send-email/", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(payload),
				});

				const result = await response.json();
				if (response.status === 200) {
					Swal.fire("Email sent successfully!", "", "success");
				} else {
					Swal.fire("Failed to send email", result.message, "error");
				}
			} catch (error) {
				console.error("Error sending email:", error);
				Swal.fire("Error", "Error sending email", "error");
			}
		}
	};

	const showInterviewStatusPopup = async (uuid, email, name) => {
		const htmldata = `
		  <div style="display: flex; justify-content: center; gap: 10px; align-items: center; width: 100%; max-width: 800px;">
			<div style="flex: 1; min-width: 120px; text-align: center;">
			  <input type="radio" name="options" id="option3" value="3">
			  <label for="option3">Assessment</label>
			</div>
			<div style="flex: 1; min-width: 120px; text-align: center;">
			  <input type="radio" name="options" id="option4" value="4">
			  <label for="option4">Technical</label>
			</div>
			<div style="flex: 1; min-width: 120px; text-align: center;">
			  <input type="radio" name="options" id="option5" value="5">
			  <label for="option5">Practical</label>
			</div>
			<div style="flex: 1; min-width: 120px; text-align: center;">
			  <input type="radio" name="options" id="option6" value="6">
			  <label for="option6">Final Round</label>
			</div>
		  </div>
		  <br/>
		`;

		const { value: result } = await Swal.fire({
			title: `Send Interview Email to ${name}`,
			html: htmldata,
			confirmButtonText: "Submit",
			showCancelButton: true,
			width: 800,
			preConfirm: () => {
				const selectedOption = document.querySelector(
					'input[name="options"]:checked'
				);
				if (!selectedOption) {
					Swal.showValidationMessage("Please select a status");
					return false;
				}
				return selectedOption.value;
			},
		});

		if (result) {
			console.log("Selected status:", result);
			await sendEmail(uuid, email, name, result); // Passing name to sendEmail
		}
	};

	// const showInterviewStatusPopup = async (uuid) => {
	// 	const htmldata = `
	// 		<div style="display: flex; justify-content: center; gap: 10px; align-items: center;">
	// 			<div>
	// 				<input type="radio" name="options" id="option3" value="3">
	// 				<label for="option3">Assesment</label>
	// 			</div>
	// 			<div>
	// 				<input type="radio" name="options" id="option3" value="3">
	// 				<label for="option3">Technical</label>
	// 			</div>
	// 			<div>
	// 				<input type="radio" name="options" id="option4" value="4">
	// 				<label for="option4">Practical</label>
	// 			</div>
	// 			<div>
	// 				<input type="radio" name="options" id="option5" value="5">
	// 				<label for="option5">Final Round</label>
	// 			</div>
	// 		</div>
	// 		<br/>
	// 			`;

	// 	const { value: result } = await Swal.fire({
	// 		title: "Choose Interview Status",
	// 		html: htmldata,
	// 		confirmButtonText: "Submit",
	// 		showCancelButton: true,
	// 		preConfirm: () => {
	// 			const selectedOption = document.querySelector(
	// 				'input[name="options"]:checked'
	// 			);
	// 			const comment = document.getElementById("commentBox").value;
	// 			if (!selectedOption) {
	// 				Swal.showValidationMessage("Please select a status");
	// 				return false;
	// 			}
	// 			if (!comment) {
	// 				Swal.showValidationMessage("Please enter a comment");
	// 				return false;
	// 			}
	// 			return {
	// 				status: selectedOption.value,
	// 				comment: comment,
	// 			};
	// 		},
	// 	});

	// 	if (result) {
	// 		console.log("Selected status:", result.status);
	// 		console.log("Comment:", result.comment);
	// 	}
	// };

	const getPosData = async (org = "") => {
		// setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getAllPositions/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 1,
				username: JSON.parse(localStorage.getItem("isAuth")).username,
				company_uuid: JSON.parse(localStorage.getItem("isAuth"))
					.companydata[0].uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setposdata(res.data);
					// let data4 = res.alluser ? res.alluser : [];
					// setalluser(data4);
				}
			})
			.catch((err) => console.log(err));
	};

	let data = [{ value: "", label: "Select All", active: "" }];
	for (let i = 0; i < posdata.length; i++) {
		// console.log(posdata[i].uuid);
		data.push({
			value: posdata[i].uuid,
			label: posdata[i].name,
		});
	}
	// nodemailer Funcation
	const nodemailer = async (uuid, email) => {
		console.log("Sending email to:", email);
		console.log("UUID:", uuid);
		try {
			const response = await fetch(BASEURL + "send-email/", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					// email: email,
					uuid: uuid,
					// is_test_invitation: 1,
				}),
			});
			const result = await response.json();
			if (response.status === 200) {
				alert("Email sent successfully!");
			} else {
				alert("Failed to send email: " + result.message);
			}
		} catch (error) {
			console.error("Error sending email:", error);
			alert("Error sending email");
		}
	};
	const getData = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let appdata = JSON.parse(localStorage.getItem("isAuth"));
	
		if (appdata != null) {
			let token = appdata.usertoken;
			myHeaders.append("Authorization", token);
		}
	
		myHeaders.append("Content-Type", "application/json");
	
		await fetch(BASEURL + `getAllJobApp/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				company_uuid: appdata.companydata[0].uuid,
				username: appdata.username,
				type: appdata.type,
				date: datevalue, 
				position: pos ? pos.value : "", 
				InterviewStatus,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					let arr = [];
					let arr1 = [];
					for (const key in res.data) {
						arr.push(key);
						if (key < 20) {
							arr1.push(key);
						}
					}
					setitems(arr);
	
					setCurrentItems(arr1);
	
					setPageCount(Math.ceil(arr.length / 20));
	
					setbkpdata(res.data);
					setappdata(res.data);
				} else {
					setappdata({});
				}
			})
			.catch((err) => console.log(err));
	
		setLoading(false);
	};
	
	// const getData = async () => {
	// 	setLoading(true);
	// 	const myHeaders = new Headers();
	// 	let appdata = JSON.parse(localStorage.getItem("isAuth"));
	// 	if (appdata != null) {
	// 		let token = appdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	// console.log(pos);
	// 	myHeaders.append("Content-Type", "application/json");
	// 	await fetch(BASEURL + `getAllJobApp/`, {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: JSON.stringify({
	// 			company_uuid: appdata.companydata[0].uuid,
	// 			username: appdata.username,
	// 			type: appdata.type,
	// 			date: datevalue,
	// 			position: pos !== "" ? pos.valueOf : "",
	// 			InterviewStatus,
	// 		}),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((res) => {
	// 			if (res.status === 201) {
	// 				let arr = [];
	// 				let arr1 = [];
	// 				for (const key in res.data) {
	// 					arr.push(key);
	// 					if (key < 20) {
	// 						arr1.push(key);
	// 					}
	// 				}
	// 				setitems(arr);

	// 				setCurrentItems(arr1);

	// 				setPageCount(Math.ceil(arr.length / 20));

	// 				setbkpdata(res.data);
	// 				setappdata(res.data);
	// 			} else {
	// 				setappdata({});
	// 			}
	// 		})
	// 		.catch((err) => console.log(err));

	// 	setLoading(false);
	// };

	const filterdata = (event) => {
		if (event.target.value === "") {
			loading ? <HashLoader /> : setappdata(bkpdata);

			let arr = [];
			for (const key in bkpdata) {
				arr.push(key);
			}
			setitems(arr);

			setCurrentItems(arr);
			setPageCount(Math.ceil(arr.length / 20));
		} else {
			var matches = bkpdata.filter(function (s) {
				return (
					s.name.toLowerCase().includes(`${event.target.value}`) ||
					s.contactno
						.toLowerCase()
						.includes(`${event.target.value}`) ||
					s.email.toLowerCase().includes(`${event.target.value}`) ||
					s.position
						.toLowerCase()
						.includes(`${event.target.value}`) ||
					s.noticeperiod
						.toLowerCase()
						.includes(`${event.target.value}`) ||
					s.noticeperiod
						.toLowerCase()
						.includes(`${event.target.value}`) ||
					s.city.toLowerCase().includes(`${event.target.value}`)
				);
			});

			let arr = [];
			for (const key in matches) {
				arr.push(key);
			}
			setitems(arr);

			const newOffset = (matches.length * 20) % matches.length;
			setItemOffset(newOffset);
			setappdata(matches);
			const endOffset = itemOffset + 20;
			setCurrentItems(arr.slice(itemOffset, endOffset));
			setPageCount(Math.ceil(matches.length / 20));
		}
	};

	const fun = (src) => {
		var w = window.open("about:blank");
		setTimeout(function () {
			//FireFox seems to require a setTimeout for this to work.
			w.document.body.appendChild(
				w.document.createElement("iframe")
			).src = src;
			w.document.body.style.margin = 0;
			w.document.getElementsByTagName("iframe")[0].style.width = "100%";
			w.document.getElementsByTagName("iframe")[0].style.height = "100%";
			w.document.getElementsByTagName("iframe")[0].style.border = 0;
		}, 0);
	};

	const handledate = (e) => {
		onChange(e);
	};
	// console.log(posdata);
	return (
		<div className="intro-y box lg:mt-5">
			<div className="flex items-center border-b border-slate-200/60 dark:border-darkmode-400">
				<h2 className="font-medium text-base p-2 mr-auto">
					Job Applications
				</h2>
			</div>
			<div className="p-5">
				{/* <div className="flex flex-wrap flex-col sm:flex-row sm:items-end xl:items-start mb-4">
					<div className="flex flex-wrap  sm:mt-0 w-full">
						<div className="flex items-center  m-0 xl:mt-0 w-[300px]">
							<input
								id="tabulator-html-filter-value"
								type="text"
								onChange={filterdata}
								className="form-control w-full mt-2 sm:mt-0"
								placeholder="Search..."
							/>
						</div>
						<div className="p-1 m-0  w-[300px]">
							<Select
								defaultValue={posdata[0]}
								value={pos}
								className="basic-single text-center w-full"
								onChange={(e) => setpos(e)}
								options={data}
								getOptionLabel={(option) => `${option.label}`}
								styles={{
									option: (base) => ({
										...base,
										height: "100%",
										display: "flex",
									}),
								}}
							/>
						</div>
						<div className="p-1 sm:ml-2 w-[300px]">
							<select
								className="form-control w-full"
								onChange={(e) =>
									setInterviewStatus(e.target.value)
								}
							>
								<option value={8}>All</option>
								<option value={0} selected>
									Pending
								</option>
								<option value={1}>Called</option>
								<option value={2}>HR Round</option>
								<option value={3}>Technical Round</option>
								<option value={4}>Practical Round</option>
								<option value={5}>Final Round</option>
								<option value={6}>Hired</option>
								<option value={7}>Rejected</option>
							</select>
						</div>
						<div className="p-1 sm:ml-2 w-[300px]">
							<DateRangePicker
								clearIcon=""
								calendarIcon={
									<FaCalendar
										style={{ color: "rgb(30 64 175)" }}
									/>
								}
								className="form-control w-full"
								onChange={handledate}
								value={datevalue}
							/>
						</div>
					</div>
					<div className="flex flex-wrap p-1 sm:mt-0 sm:ml-2 w-full">
						<button
							type="button"
							onClick={(e) => getData(e)}
							className="btn btn-primary mx-2 mb-2 sm:mb-0 btn-sm"
						>
							Get Data
						</button>
						<button
							type="button"
							onClick={() => {
								setpos("");
								onChange([
									format(
										new Date(datevalue[0]),
										"yyyy-MM-dd"
									),
									format(
										new Date(datevalue[1]),
										"yyyy-MM-dd"
									),
								]);
							}}
							className="btn btn-danger mx-2 mb-2 sm:mb-0 btn-sm"
						>
							Clear
						</button>
						<button
							className="btn bg-primary text-white mx-2 mb-2 sm:mb-0"
							onClick={exportFile}
						>
							Download
						</button>
					</div>
				</div> */}

				<div className="flex flex-wrap flex-col sm:flex-row sm:items-end xl:items-start mb-4">
					<div className="flex flex-wrap  sm:mt-0 w-full">
						<div className="flex items-center  m-0 xl:mt-0 w-[300px]">
							<input
								id="tabulator-html-filter-value"
								type="text"
								onChange={filterdata}
								className="form-control w-full mt-2 sm:mt-0"
								placeholder="Search..."
							/>
						</div>
						<div className="p-1 m-0  w-[300px]">
							<Select
								defaultValue={posdata[0]}
								value={pos}
								className="basic-single text-center w-full"
								onChange={(e) => setpos(e)}
								options={data}
								getOptionLabel={(option) => `${option.label}`}
								styles={{
									option: (base) => ({
										...base,
										height: "100%",
										display: "flex",
									}),
								}}
							/>
						</div>
						<div className="p-1 sm:ml-2 w-[300px]">
							<select
								className="form-control w-full"
								onChange={(e) =>
									setInterviewStatus(e.target.value)
								}
							>
								<option value={8}>All</option>
								<option value={0} selected>
									Pending
								</option>
								<option value={1}>Called</option>
								<option value={2}>HR Round</option>
								<option value={3}>Technical Round</option>
								<option value={4}>Practical Round</option>
								<option value={5}>Final Round</option>
								<option value={6}>Hired</option>
								<option value={7}>Rejected</option>
							</select>
						</div>
						<div className="p-1 sm:ml-2 w-[300px]">
							<DateRangePicker
								clearIcon=""
								calendarIcon={
									<FaCalendar
										style={{ color: "rgb(30 64 175)" }}
									/>
								}
								className="form-control w-full"
								onChange={handledate}
								value={datevalue}
							/>
						</div>
						<button
							type="button"
							onClick={(e) => getData(e)}
							className="btn btn-primary mx-1 mb-1 sm:mb-0 btn-sm"
						>
							Get Data
						</button>

						<button
							type="button"
							onClick={() => {
								// setpos("");
								onChange([
									format(
										new Date(datevalue[0]),
										"yyyy-MM-dd"
									),
									format(
										new Date(datevalue[1]),
										"yyyy-MM-dd"
									),
								]);
							}}
							className="btn btn-danger mx-1 mb-1 sm:mb-0 btn-sm"
						>
							Clear
						</button>
						<button
							className="btn bg-primary text-white mx-1 mb-1 sm:mb-0"
							onClick={exportFile}
						>
							Download
						</button>
					</div>
				</div>
				<div className="overflow-x-auto">
					{loading ? (
						<HashLoader
							color="#5755d9"
							size={30}
							style={{ position: "absolute", right: "50%" }}
						/>
					) : (
						<table
							className="table table-bordered"
							style={{ width: "100%" }}
						>
							<thead>
								<tr>
									<th
										className="whitespace-nowrap"
										style={{ width: "20px" }}
									>
										Name
									</th>
									<th className="whitespace-nowrap">
										Email ID
									</th>
									<th className="whitespace-nowrap">
										Contact Number
									</th>
									{/* <th className="whitespace-nowrap">City</th> */}
									{/* <th className="whitespace-nowrap">Address</th> */}
									<th className="whitespace-nowrap">
										Position
									</th>
									<th className="whitespace-nowrap">
										Exp Salary
									</th>
									{/* <th className="whitespace-nowrap">Notice Period</th> */}
									<th className="whitespace-nowrap">
										Assessment <br /> Status
									</th>
									<th className="whitespace-nowrap">
										Interview
										<br /> Status
									</th>
									<th className="whitespace-nowrap">
										Test
										<br /> Score
									</th>
									<th className="whitespace-nowrap">
										Interview Email
									</th>
									<th className="whitespace-nowrap">
										Status
										<br /> Datetime
									</th>
									<th className="whitespace-nowrap">
										Ref By
									</th>

									<th className="whitespace-nowrap">
										Date Time
									</th>
									<th className="whitespace-nowrap">
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{currentItems && currentItems.length === 0 && (
									<tr>
										<td colSpan="8">No data available</td>
									</tr>
								)}
								{currentItems &&
									currentItems.length > 0 &&
									currentItems.map((item, i) => {
										return (
											<tr key={i}>
												<td>
													<Link
														title="Application Details"
														to={
															"/appdetails/" +
															appdata[item].uuid
														}
													>
														<u>
															{appdata[item].name}
														</u>
													</Link>
												</td>
												<td style={{ width: "30px" }}>
													{appdata[item].email}
												</td>
												<td>
													{appdata[item].contactno}
												</td>
												{/* <td>{appdata[item].city===0?"Ahmedabad":appdata[item].city===0?"Gandhinagar":"Other"}</td> */}
												{/* <td>{appdata[item].address}</td> */}
												<td>
													{appdata[item].position}
												</td>
												<td>
													{
														appdata[item]
															.expectedsalary
													}
												</td>

												<td>
													{appdata[item]
														.isassessmentadded ==
													1 ? (
														<span
															className="btn btn-success btn-sm text-white"
															style={{
																fontSize:
																	"10px",
															}}
														>
															Done
														</span>
													) : (
														<span
															className="btn btn-warning btn-sm"
															style={{
																fontSize:
																	"10px",
															}}
														>
															Pending
														</span>
													)}
												</td>
												<td>
													{appdata[item]
														.interviewStatus ===
													0 ? (
														<>
															<span
																onClick={() =>
																	interviewStatus(
																		appdata[
																			item
																		].uuid,
																		appdata[
																			item
																		]
																			.interviewStatus,
																		appdata[
																			item
																		]
																			.comment
																	)
																}
																className="btn btn-warning btn-sm"
																style={{
																	fontSize:
																		"10px",
																}}
															>
																Pending
															</span>
														</>
													) : appdata[item]
															.interviewStatus ===
													  1 ? (
														<>
															<span
																onClick={() =>
																	interviewStatus(
																		appdata[
																			item
																		].uuid,
																		appdata[
																			item
																		]
																			.interviewStatus,
																		appdata[
																			item
																		]
																			.comment
																	)
																}
																className="btn btn-default btn-sm"
																style={{
																	fontSize:
																		"10px",
																}}
															>
																Called
															</span>{" "}
														</>
													) : appdata[item]
															.interviewStatus ===
													  2 ? (
														<>
															<span
																onClick={() =>
																	interviewStatus(
																		appdata[
																			item
																		].uuid,
																		appdata[
																			item
																		]
																			.interviewStatus,
																		appdata[
																			item
																		]
																			.comment
																	)
																}
																className="btn btn-primary btn-sm"
																style={{
																	fontSize:
																		"10px",
																}}
															>
																HR Round
															</span>{" "}
														</>
													) : appdata[item]
															.interviewStatus ===
													  3 ? (
														<>
															<span
																onClick={() =>
																	interviewStatus(
																		appdata[
																			item
																		].uuid,
																		appdata[
																			item
																		]
																			.interviewStatus,
																		appdata[
																			item
																		]
																			.comment
																	)
																}
																className="btn btn-primary btn-sm"
																style={{
																	fontSize:
																		"10px",
																}}
															>
																Technical Round
															</span>{" "}
														</>
													) : appdata[item]
															.interviewStatus ===
													  4 ? (
														<>
															<span
																onClick={() =>
																	interviewStatus(
																		appdata[
																			item
																		].uuid,
																		appdata[
																			item
																		]
																			.interviewStatus,
																		appdata[
																			item
																		]
																			.comment
																	)
																}
																className="btn btn-primary btn-sm"
																style={{
																	fontSize:
																		"10px",
																}}
															>
																Practical Round
															</span>{" "}
														</>
													) : appdata[item]
															.interviewStatus ===
													  5 ? (
														<>
															<span
																onClick={() =>
																	interviewStatus(
																		appdata[
																			item
																		].uuid,
																		appdata[
																			item
																		]
																			.interviewStatus,
																		appdata[
																			item
																		]
																			.comment
																	)
																}
																className="btn btn-success btn-sm"
																style={{
																	fontSize:
																		"10px",
																}}
															>
																Final Round
															</span>{" "}
														</>
													) : appdata[item]
															.interviewStatus ===
													  6 ? (
														<>
															<span
																onClick={() =>
																	interviewStatus(
																		appdata[
																			item
																		].uuid,
																		appdata[
																			item
																		]
																			.interviewStatus,
																		appdata[
																			item
																		]
																			.comment
																	)
																}
																className="btn btn-success btn-sm"
																style={{
																	fontSize:
																		"10px",
																}}
															>
																Hired
															</span>{" "}
														</>
													) : (
														<>
															<span
																onClick={() =>
																	interviewStatus(
																		appdata[
																			item
																		].uuid,
																		appdata[
																			item
																		]
																			.interviewStatus,
																		appdata[
																			item
																		]
																			.comment
																	)
																}
																className="btn btn-danger btn-sm text-white"
																style={{
																	fontSize:
																		"10px",
																}}
															>
																Rejected
															</span>
														</>
													)}
												</td>
												{/* Test Score */}
												{/* <td
  title={
    appdata[item].test_percentage
      ? `Test Percentage: ${parseFloat(appdata[item].test_percentage).toFixed(2)}%`
      : ''
  }
  style={{
    color: appdata[item].test_percentage
      ? parseFloat(appdata[item].test_percentage) < 50
        ? 'red'
        : 'green' 
      : 'inherit', 
    cursor: appdata[item].test_percentage ? 'pointer' : 'default', 
  }}
>
  {appdata[item].test_result && appdata[item].test_outof
    ? `${appdata[item].test_result}/${appdata[item].test_outof}`
    : ''} 
</td> */}
												{/* <td
													style={{
														color:
															appdata[item]
																.test_percentage &&
															!isNaN(
																parseFloat(
																	appdata[
																		item
																	]
																		.test_percentage
																)
															)
																? parseFloat(
																		appdata[
																			item
																		]
																			.test_percentage
																  ) < 50
																	? "red"
																	: parseFloat(
																			appdata[
																				item
																			]
																				.test_percentage
																	  ) <= 60
																	? "lightgreen"
																	: "green"
																: "inherit",
														cursor: appdata[item]
															.test_percentage
															? "pointer"
															: "default",
														transition:
															"color 0.3s ease", 
													}}
													className={
														appdata[item]
															.test_percentage &&
														!isNaN(
															parseFloat(
																appdata[item]
																	.test_percentage
															)
														)
															? parseFloat(
																	appdata[
																		item
																	]
																		.test_percentage
															  ) < 50
																? "low-percentage"
																: parseFloat(
																		appdata[
																			item
																		]
																			.test_percentage
																  ) <= 60
																? "medium-percentage"
																: "high-percentage"
															: ""
													}
												>
													{appdata[item]
														.test_result &&
													appdata[item].test_outof
														? `${
																appdata[item]
																	.test_result
														  } | ${
																appdata[item]
																	.test_outof
														  }  ${parseFloat(
																appdata[item]
																	.test_percentage
														  ).toFixed(2)}%`
														: ""}
												</td> */}
												{/* <td
													style={{
														color: appdata[item]
															.test_percentage
															? parseFloat(
																	appdata[
																		item
																	]
																		.test_percentage
															  ) < 50
																? "red"
																: parseFloat(
																		appdata[
																			item
																		]
																			.test_percentage
																  ) <= 60
																? "lightgreen"
																: "green"
															: "inherit",
														backgroundColor:
															appdata[item]
																.test_percentage
																? parseFloat(
																		appdata[
																			item
																		]
																			.test_percentage
																  )
																   < 50
																	? "#ffcccc"
																	: parseFloat(
																			appdata[
																				item
																			]
																				.test_percentage
																	  ) <= 60
																	? "#ccffcc"
																	: "#cceeff"
																: "transparent",
														cursor: appdata[item]
															.test_percentage
															? "pointer"
															: "default",
														padding: "8px",
														transition:
															"color 0.3s ease, background-color 0.3s ease",
													}}
												>
													{appdata[item]
														.test_result &&
													appdata[item].test_outof ? (
														<>
															{`${
																appdata[item]
																	.test_result
															} | ${
																appdata[item]
																	.test_outof
															} ${parseFloat(
																appdata[item]
																	.test_percentage
															).toFixed(2)}%`}
														</>
													) : (
														""
													)}
												</td> */}
												{/* <td
													style={{
														color: appdata[item]
															.test_percentage
															? parseFloat(
																	appdata[
																		item
																	]
																				.test_percentage
															  ) < 50
																? "red"
																: parseFloat(
																		appdata[
																			item
																		]
																			.test_percentage
																  ) <= 60
																? "lightgreen"
																: "green"
															: "inherit",
														backgroundColor:
															appdata[item]
																.test_percentage
																? parseFloat(
																		appdata[
																			item
																		]
																			.test_percentage
																  ) < 50
																	? "#ffcccc"
																	: parseFloat(
																			appdata[
																				item
																			]
																				.test_percentage
																	  ) <= 60
																	? "#ccffcc"
																	: "#cceeff"
																: "transparent",
														cursor: appdata[item]
															.test_percentage
															? "pointer"
															: "default",
														padding: "8px",
														transition:
															"color 0.3s ease, background-color 0.3s ease",
													}}
												>
													{appdata[item]
														.test_result &&
													appdata[item].test_outof ? (
														<>
															{`${appdata[item].test_result} | ${appdata[item].test_outof} `}
															<span
																className={`btn btn-sm text-white ${
																	parseFloat(
																		appdata[
																			item
																		]
																			.test_percentage
																	) < 50
																		? "btn-danger"
																		: parseFloat(
																				appdata[
																					item
																				]
																					.test_percentage
																		  ) <=
																		  60
																		? "btn-lightgreen"
																		: "btn-success"
																}`}
															>
																{`${parseFloat(
																	appdata[
																		item
																	]
																		.test_percentage
																).toFixed(2)}%`}
															</span>
														</>
													) : (
														""
													)}
												</td> */}
												<td
													style={{
														color: appdata[item]
															.test_percentage
															? parseFloat(
																	appdata[
																		item
																	]
																		.test_percentage
															  ) < 50
																? "red"
																: parseFloat(
																		appdata[
																			item
																		]
																			.test_percentage
																  ) <= 60
																? "darkgreen" // Dark green for 50-60 range
																: "green" // Green for above 60
															: "inherit",
														backgroundColor:
															appdata[item]
																.test_percentage
																? parseFloat(
																		appdata[
																			item
																		]
																			.test_percentage
																  ) < 50
																	? "#ffcccc" // Light red for below 50
																	: parseFloat(
																			appdata[
																				item
																			]
																				.test_percentage
																	  ) <= 60
																	? "#ffffcc"
																	: "#ccffcc"
																: "transparent",
														cursor: appdata[item]
															.test_percentage
															? "pointer"
															: "default",
														padding: "8px",
														transition:
															"color 0.3s ease, background-color 0.3s ease",
													}}
												>
													{appdata[item]
														.test_result &&
													appdata[item].test_outof ? (
														<>
															{`${appdata[item].test_result} / ${appdata[item].test_outof} `}
															<span
																className={`btn btn-sm text-white ${
																	parseFloat(
																		appdata[
																			item
																		]
																			.test_percentage
																	) < 50
																		? "btn-danger"
																		: parseFloat(
																				appdata[
																					item
																				]
																					.test_percentage
																		  ) <=
																		  60
																		? "btn-warning"
																		: "btn-success"
																}`}
															>
																{`${parseFloat(
																	appdata[
																		item
																	]
																		.test_percentage
																).toFixed(2)}%`}
															</span>
														</>
													) : (
														""
													)}
												</td>

												{/* nodemailer */}
												{/* <td>
													<button
														type="button"
														onClick={(e) =>
															nodemailer(
																appdata[item]
																	.uuid,
																appdata[item]
																	.email
															)
														}
														class="btn btn-primary mx-2 pt-2 btn-sm "
													>
														Send
													</button>
												</td> */}
												<td>
													<button
														type="button"
														onClick={() =>
															showInterviewStatusPopup(
																appdata[item]
																	.uuid,
																appdata[item]
																	.email,
																appdata[item]
																	.name // Passing the user's name
															)
														}
														className="btn btn-primary mx-2 pt-2 btn-sm"
													>
														Send
													</button>
												</td>

												<td>
													{appdata[item]
														.interviewStatus_datetime !=
													"undefined"
														? format(
																new Date(
																	appdata[
																		item
																	].interviewStatus_datetime
																),
																"yyyy-MM-dd HH:mm:ss"
														  )
														: "-"}
												</td>
												<td>
													{appdata[item]
														.knowvavancy !=
													"undefined"
														? appdata[item]
																.knowvavancy
														: "-"}
												</td>

												<td>
													{
														appdata[item]
															.applicationdate
													}
												</td>

												<td>
													{/* <button className="btn btn-primary btn-sm p-1"> */}
													<div
														style={{
															display: "flex",
														}}
													>
														<FaClone
															onClick={() =>
																interviesend(
																	appdata[
																		item
																	].uuid
																)
															}
															className="cursor-pointer ml-3 mr-3"
															style={{
																fontSize:
																	"17px",
															}}
															title="Send a link for further rounds"
														/>
														{/* <FaClone
															onClick={() =>
																furtherrounds(
																	appdata[
																		item
																	].uuid
																)
															}
															className="cursor-pointer ml-3 mr-3"
															style={{
																fontSize:
																	"17px",
															}}
															title="Send a link for further rounds"
														/> */}
														<FaPodcast
															onClick={() =>
																furtherrounds(
																	appdata[
																		item
																	].uuid,
																	appdata[
																		item
																	].name
																)
															}
															className="cursor-pointer ml-3 mr-3"
															style={{
																fontSize:
																	"17px",
															}}
															title="Send a link for further rounds"
														/>

														<span className="mr-2">
															{appdata[item]
																.resume !==
																"" ||
															appdata[item]
																.cvname !==
																"" ? (
																<Link
																	title="Job Assessment"
																	to={
																		"/job-assessment/" +
																		appdata[
																			item
																		].uuid
																	}
																>
																	<FaTasks
																		className="cursor-pointer"
																		style={{
																			fontSize:
																				"17px",
																		}}
																	/>
																</Link>
															) : null}
														</span>

														<span>
															{appdata[item]
																.resume !==
																"" &&
															appdata[item]
																.is_from_othersite ===
																0 ? (
																<a
																	target="_blank"
																	title="Download CV"
																	href={
																		BASEURL +
																		"uploads/cv/" +
																		appdata[
																			item
																		].resume
																	}
																>
																	<FaFileDownload
																		className="cursor-pointer"
																		style={{
																			fontSize:
																				"17px",
																		}}
																	/>
																</a>
															) : null}

															{appdata[item]
																.cvname !==
																"" &&
															appdata[item]
																.is_from_othersite ===
																1 ? (
																<a
																	target="_blank"
																	title="Download CV"
																	href={
																		"https://www.rayvat.com/assets/contactfiles/" +
																		appdata[
																			item
																		].cvname
																	}
																>
																	<FaFileDownload
																		className="cursor-pointer"
																		style={{
																			fontSize:
																				"17px",
																		}}
																	/>
																</a>
															) : null}
														</span>
														<span>
															{appdata[item]
																.profilepic !==
															"" ? (
																<FaUserAlt
																	onClick={() =>
																		fun(
																			appdata[
																				item
																			]
																				.profilepic
																		)
																	}
																	className="cursor-pointer ml-3"
																	style={{
																		fontSize:
																			"17px",
																	}}
																/>
															) : null}
														</span>
													</div>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					)}
					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
						<br />
						<nav className="w-full sm:w-auto sm:mr-auto">
							{!loading ? (
								<div className="row">
									<div className="col-md-3"></div>
									<div className="col-md-4">
										<ReactPaginate
											pageCount={pageCount}
											pageRangeDisplayed={20}
											marginPagesDisplayed={1}
											onPageChange={handlePageClick}
											containerClassName="pagination"
											activeClassName="active"
											pageLinkClassName="page-link"
											breakLinkClassName="page-link"
											nextLinkClassName="page-link"
											previousLinkClassName="page-link"
											pageClassName="page-item"
											breakClassName="page-item"
											nextClassName="page-item"
											previousClassName="page-item"
											previousLabel={<>&laquo;</>}
											nextLabel={<>&raquo;</>}
										/>
									</div>
									<div className="col-md-4"></div>
								</div>
							) : null}
						</nav>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Departments;
