import React, { useState, useEffect } from "react";
import { SITEURL, BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Masteradmin from "../Masteradmin";
import { format } from "date-fns";

const Departments = (props) => {
  const [userdata, setuserdata] = useState([]);
  const [bkpdata, setbkpdata] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [items, setitems] = useState([]);
  const [add, setadd] = useState(2);
  let [tabtype, settabtype] = useState(false);
  
  const [data, setdata] = useState([]);

  const [isadd, setisadd] = useState(false);
  let [loading, setLoading] = useState(false);
  
  let [master, ] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

  useEffect(() => {
    setadd(JSON.parse(localStorage.getItem("isAuth")).type);
    const endOffset = itemOffset + 10;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 10));
    getData(props.id);
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 10));
  }, [itemOffset]);


  const getData = async (id="") => {
    settabtype(false);
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    let data = await fetch(BASEURL + `get-smses`, {
      method: "post",
      body:JSON.stringify({id}),
      headers: myHeaders,
    });
    let data2 = await data.json();
    if (data2.status === 201) {
      let arr = [];
      let arr1 = [];
      for (const key in data2.data) {
        arr.push(key);
        if (key < 9) {
          arr1.push(key);
        }
      }
      setitems(arr);
      setCurrentItems(arr1);

      setPageCount(Math.ceil(arr.length / 10));
      // console.log(data2.data);
      setdata(data2.data);
      setbkpdata(data2.data);
    }
    setLoading(false);
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % items.length;

    setItemOffset(newOffset);
  };



  const filterdata = (event) => {
    if (event.target.value === "") {
      loading ? <HashLoader /> : setuserdata(bkpdata);

      let arr = [];
      for (const key in bkpdata) {
        arr.push(key);
      }
      setitems(arr);

      setCurrentItems(arr);
      setPageCount(Math.ceil(arr.length / 10));
    } else {
      var matches = bkpdata.filter(function (s) {
        return s.name.toLowerCase().includes(`${event.target.value}`);
      });

      let arr = [];
      for (const key in matches) {
        arr.push(key);
      }
      setitems(arr);

      const newOffset = (matches.length * 10) % matches.length;
      setItemOffset(newOffset);
      setuserdata(matches);
      const endOffset = itemOffset + 10;
      setCurrentItems(arr.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(matches.length / 10));
    }
  };


  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">SMS History</h2>
  
      </div>
      {tabtype ? <Masteradmin getData={getData}  /> : null}

      <div className="p-5">
     
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
          <div className="flex mt-5 sm:mt-0">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                onChange={filterdata}
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
          </div>
          {/* {master == true ? (
            <div className="flex mt-5 sm:mt-0">
              <select
              name="uuid"
                onChange={handlechange}
                className="form-control"
                id=""
              >
                <option value="1">Active</option>
                <option value="2">In-Active</option>
              </select>
            </div>
          ) : null} */}
          <div className="hidden md:block ml-auto text-slate-500">
            Showing {itemOffset + 1} to  {userdata.length > itemOffset + 10 ? itemOffset + 10 : userdata.length} of {userdata.length} entries
          </div>
        </div>
        <div className="overflow-x-auto">
          {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="whitespace-nowrap">Date</th>
                  <th className="whitespace-nowrap">Time</th>
                  {/* <th className="whitespace-nowrap">User</th> */}
                  <th className="whitespace-nowrap">Sms From	</th>
                  <th className="whitespace-nowrap">Sms To	</th>
                  <th className="whitespace-nowrap">Sms Type	</th>
                  <th className="whitespace-nowrap">Message</th>                  
                </tr>
              </thead>
              <tbody>
              {currentItems &&
                  currentItems.length === 0 && 
                    <tr><td colSpan="8">No data available</td></tr>
                }
                {currentItems &&
                  currentItems.length > 0 &&
                  currentItems.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{format(new Date(data[item].created_at), "yyyy-MM-dd")}</td>
                        <td>{format(new Date(data[item].created_at), "HH:mm:ss")}</td>
                        {/* <td>{data[item].user_id}</td> */}
                        <td>{data[item].sms_from}</td>
                        <td>{data[item].sms_to}</td>
                        <td>{data[item].type===1?"Incoming":"Outgoing"}</td>
                        <td>{data[item].message}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}

          <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
            <br />
            <nav className="w-full sm:w-auto sm:mr-auto">
              {!loading ? (
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-4">
                    <ReactPaginate
                      pageCount={pageCount}
                      pageRangeDisplayed={10}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      pageClassName="page-item"
                      breakClassName="page-item"
                      nextClassName="page-item"
                      previousClassName="page-item"
                      previousLabel={<>&laquo;</>}
                      nextLabel={<>&raquo;</>}
                    />
                  </div>
                  <div className="col-md-4"></div>
                </div>
              ) : (
                <HashLoader
                  color="#5755d9"
                  size={30}
                  style={{ position: "absolute", right: "50%" }}
                />
              )}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
