import { createSlice } from '@reduxjs/toolkit'
const themecolor = localStorage.getItem('themeColor') ? localStorage.getItem('themeColor') : 'default';
export const TabSection = Object.freeze({
    contact: "contact",
    callHistory: "callHistory",
    settings: "settings",
    message: "message",
    chat: "chat"
});
let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
const initialState = {
    isTimer: 0,
    openDialpade: true,
    showActiveConatctTab: true,
    showActiveSettingTab: false,
    showActiveCallHistrotyTab: false,
    contactSearch: true,
    isLoading: false,
    themeColor: themecolor,
    modelOpen: false,
    sidebar: {
        tab: TabSection.contact, // conatct,  settings,callhistory, message ,chats,
        openId: null /// diaynemic id 
    },
    laguage: lang,
    loader: false
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleLoaddingScreen: (state, action) => {
            state.loader = action.payload
        },
        toggleModel: (state, action) => {
            state.modelOpen = action.payload
        },
        openTabSection: (state, action) => {
            // console.debug(action)
            state.sidebar.tab = action.payload.tab
            state.sidebar.openId = action.payload.openId
        },
        showDialpadeSection: (state) => {
            state.openDialpade = true;
        },
        setTimer: (state,action) => {
            // console.log("state========================================");
            // console.log(state);
            // console.log(action.payload);
            // console.log("state========================================");
            state.isTimer = action.payload;
        },
        hideDialpadeSection: (state) => {
            state.openDialpade = false;
        },
        showActiveConatctTab: (state) => {
            state.showActiveConatctTab = true;
            state.showActiveSettingTab = false;
            state.showActiveCallHistrotyTab = false;
        },
        showActiveSettingTab: (state) => {
            state.showActiveConatctTab = false;
            state.showActiveSettingTab = true;
            state.showActiveCallHistrotyTab = false;
        },
        showActiveCallHistrotyTab: (state) => {
            state.showActiveConatctTab = false;
            state.showActiveSettingTab = false;
            state.showActiveCallHistrotyTab = true;
        },
        disabledConatctSearch: (state) => {
            state.contactSearch = false;
        },
        enableConatctSearch: (state) => {
            state.contactSearch = true;
        },
        setThemeColor: (state, action) => {
            state.themeColor = action.payload
        },

    },


})

// Action creators are generated for each case reducer function

export default themeSlice.reducer
export const { setTimer, showDialpadeSection, hideDialpadeSection, showActiveSettingTab, showActiveConatctTab, showActiveCallHistrotyTab, enableConatctSearch, disabledConatctSearch, setThemeColor, openTabSection, toggleModel, toggleLoaddingScreen } = themeSlice.actions