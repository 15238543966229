import React, { useState, useEffect } from "react";
import { BASEURL } from "../BASEURL";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
const Departments = () => {
  const [dept, setdept] = useState(null);
  const [data, setdata] = useState({
    id: "",
    name: "",
    description: "",
    no: "",
    status: false,
  });

  const [isadd, setisadd] = useState(false);
  let [loading, setLoading] = useState(false);
  let [msg, setmsg] = useState(false);

  useEffect(() => {
    getdepartments();
  }, []);

  const getdepartments = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== "00:00:00") {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAllLeavetypes/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        company_uuid: userdata.companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setdept(res.data);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const handlechange = (e) => {
    let value = e.target.value;
    if (e.target.name === "status") {
      value = e.target.checked;
    }
    setdata({ ...data, [e.target.name]: value });
  };

  const savedata = async () => {
    // console.log(data); return
    if (data.name === "" || data.description === "" || data.no === "") {
      swal({
        title: "Error",
        text: "Please Enter value",
        icon: "error",
        timer: 2000,
      });
      return;
    }
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `save-leavetype/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        company_uuid: userdata.companydata[0].uuid,
        data,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          userdata.companydata[0].name = data.name;
          userdata.companydata[0].support_access = data.supportaccess;
          localStorage.setItem("isAuth", JSON.stringify(userdata));
          setmsg(res.message);
          setisadd(!isadd);
          getdepartments();
          setdata({
            id: "",
            name: "",
            description: "",
            no: "",
            status: false,
          });
          swal({
            title: "Success",
            text: res.message,
            icon: "success",
            timer: 2000,
          });
          setTimeout(() => {
            setisadd(!isadd);
            setmsg("");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const editdata = (data) => {
    setisadd(true);
    let status;
    if (data.status === 1) {
      status = true;
    } else {
      status = false;
    }
    setdata({
      id: data.type_id,
      name: data.name,
      description: data.description,
      no: data.no,
      status: status,
    });
  };

  const adddata = (data) => {
    setisadd(!isadd);
    setdata({
      name: "",
      description: "",
      no: "",
      status: false,
    });
  };

  const deletedata = (id) => {
    swal({
      title: "Delete",
      text: `Please confirm if you want to Delete Leave Type ?`,
      buttons: ["No", "Yes"],
      showCloseButton: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        if (userdata != null) {
          let token = userdata.usertoken;
          myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");
        fetch(BASEURL + `delete-leavetype/`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            id,
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            swal({
              title: "Success",
              text: res.msg,
              icon: "success",
              timer: 2000,
            });
            getdepartments();
          })
          .catch((err) => console.log(err));
        setLoading(false);
      }
    });
  };

  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">Leave Types</h2>
        <button
          type="button"
          onClick={() => adddata()}
          className="btn btn-primary w-50"
        >
          Add Leave Type
        </button>
      </div>
      <div className="p-5">
        <div className="flex flex-col-reverse xl:flex-row flex-col text-left">
          {isadd === true ? (
            <div className="flex-1 mt-6 xl:mt-0">
              <div className="grid grid-cols-12 gap-x-5">
                <div className="col-span-12 2xl:col-span-6">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Name*
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.name}
                      type="text"
                      name="name"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>

                  <div className="mt-3">
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      No. of Days*
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.no}
                      type="number"
                      name="no"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                </div>

                <div className="col-span-12 2xl:col-span-6">
                  <div>
                    <label
                      htmlFor="update-profile-form-2"
                      className="form-label"
                    >
                      Description*
                    </label>
                    <br />
                    <textarea
                      name="description"
                      className="form-control"
                      cols="80"
                      rows="1"
                      onChange={handlechange}
                      value={data && data.description}
                    ></textarea>
                  </div>

                  <div className="mt-3">
                    <label>Status</label>
                    <div className="form-switch mt-2">
                      {data && data.status === false ? (
                        <input
                          type="checkbox"
                          name="status"
                          onChange={handlechange}
                          className="form-check-input"
                        />
                      ) : (
                        <input
                          type="checkbox"
                          name="status"
                          onChange={handlechange}
                          value={data.status}
                          checked
                          className="form-check-input"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={() => savedata()}
                className="btn btn-primary w-20 mt-3"
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => adddata()}
                className="btn btn-danger w-20 mt-3 ml-2"
              >
                Cancel
              </button>
              <br />
              <br />
              <span className="text-success">{msg ? msg : null}</span>
            </div>
          ) : null}
        </div>
        <div className="overflow-x-auto">
          {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="whitespace-nowrap">#</th>
                  <th className="whitespace-nowrap">Name</th>
                  <th className="whitespace-nowrap">Description</th>
                  <th className="whitespace-nowrap">No of Leaves</th>
                  <th className="whitespace-nowrap">Status</th>
                  <th className="whitespace-nowrap">Actions</th>
                </tr>
              </thead>
              <tbody>
                {dept && dept.length > 0 ? (
                  dept.map((item, i) => {
                    return (
                      <tr>
                        <td>{item.type_id}</td>
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                        <td>{item.no}</td>
                        <td>{item.status === 1 ? "Enable" : "Disble"}</td>
                        <td>
                          <div className="flex lg:justify-center items-center">
                            <div
                              className="edit flex items-center mr-3"
                              onClick={() => editdata(item)}
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                icon-name="check-square"
                                data-lucide="check-square"
                                className="lucide lucide-check-square w-4 h-4 mr-1"
                              >
                                <polyline points="9 11 12 14 22 4"></polyline>
                                <path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"></path>
                              </svg>{" "}
                              Edit
                            </div>
                            <duv
                              className="delete flex items-center text-danger"
                              onClick={() => deletedata(item.type_id)}
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                icon-name="trash-2"
                                data-lucide="trash-2"
                                className="lucide lucide-trash-2 w-4 h-4 mr-1"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>{" "}
                              Delete
                            </duv>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Departments;
