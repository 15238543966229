import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import Select from "react-select";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import "react-month-picker/css/month-picker.css";
import DatePicker from "react-date-picker";
import { format } from "date-fns";
import { FaCalendar, FaCamera, FaTimes } from "react-icons/fa";
import Modal from "react-modal";
import HashLoader from "react-spinners/HashLoader";
import { GrNext, GrPrevious } from "react-icons/gr";
import { useLoadingContext } from "react-router-loading";
Modal.setAppElement("#root");

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "10px",
		border: "1px solid rgb(187, 181, 181)",
		borderRadius: "14px",
		overflowX: "hidden",
		overflowY: "visible",
		width: "1000px",
	},
};

const Activity = () => {
	const loadingContext = useLoadingContext();

	let [loading, setLoading] = useState(false);
	const [attdata, setattdata] = useState(null);
	const [imgsrc, setimgsrc] = useState("");
	const [isopen, setIsOpen] = useState(false);
	const [date, setdate] = useState(new Date());
	const [orgs, setorgs] = React.useState([]);
	const [master] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).master
	);
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);

	useEffect(() => {
		getOrganizations();
		getData("");

		const loading = async () => {
			loadingContext.done();
		};

		loading();
	}, []);

	const handledate = (e) => {
		let date;
		if (e !== null) {
			setdate(e);
			date = format(new Date(e), "yyyy-MM-dd");
		} else {
			date = format(new Date(), "yyyy-MM-dd");
		}

		getData(date);
	};

	const nexTDay = () => {
		let tomorrow = date;
		tomorrow.setDate(date.getDate() + 1);

		setdate(tomorrow);
		let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
		getData(date2);
	};

	const prevDay = () => {
		let tomorrow = date;
		tomorrow.setDate(date.getDate() - 1);

		setdate(tomorrow);
		let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
		getData(date2);
	};

	const columns = [
		{ title: "Employee", field: "employee" },
		{ title: "Mouse Click", field: "mouseclicked" },
		{ title: "Mouse Scroll", field: "mousescroll", width: 200 },
		{ title: "Keyboard Clicks", field: "keyboardclicked" },
		{ title: "Duration", field: "duration" },
		{
			title: "Screenshot",
			field: "screenshot",
			formatter: reactFormatter(<StatusVal />),
		},
		{ title: "Date Time", field: "datetime" },
	];

	function StatusVal(props) {
		const rowData = props.cell._cell.row.data;
		return (
			<>
				{rowData.screenshot !== "" ? (
					<FaCamera style={{ fontSize: "15px" }} />
				) : null}
			</>
		);
	}

	const options = {
		pagination: "local",
		paginationSize: 50,
	};

	const getData = async (date, org = "") => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-activity/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				uuid: "",
				company_uuid: org !== "" ? org : selectedorg,
				date,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				setLoading(true);
				if (res.status === 201) {
					setattdata(res.data);
					setLoading(false);
				} else {
					setattdata([
						{
							employee: "No data Available",
							mouseclicked: "",
							mousescroll: "",
							keyboardclicked: "",
							duration: "",
							screenshot: "",
							datetime: "",
						},
					]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const rowClick = (e, row) => {
		setIsOpen(true);
		setLoading(true);
		const rowData = row._row.data;

		setimgsrc(
			// `https://d1azebur1db3zi.cloudfront.net/screenshot/${rowData.username}/${rowData.date}/${rowData.screenshot}`
			`https://d1azebur1db3zi.cloudfront.net/screenshot/${rowData.username}/${rowData.date}/${rowData.screenshot}`
		);
		setLoading(false);
	};

	function closeModal() {
		setIsOpen(false);
	}

	const getOrganizations = async () => {
		setLoading(true);

		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-organizations/`, {
			method: "POST",
			headers: myHeaders,
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setorgs(res.data);
				} else {
					setorgs([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const getorg = async (org) => {
		setselectedorg(org);
		getData(date, org);
	};

	return (
		<>
			<div className="intro-y flex flex-col sm:flex-row items-center">
				<h2 className="text-lg font-medium mr-auto">Activity</h2>
			</div>
			<div className="intro-y box p-5 mt-5">
				<div
					className="overflow-x-auto scrollbar-hidden"
					style={{ minHeight: "700px" }}
				>
					<div className="h-50">
						<div className="text-left">
							<label>Select Date</label> <br></br>
							<DatePicker
								clearIcon=""
								calendarIcon={
									<FaCalendar
										style={{ color: "rgb(30 64 175)" }}
									/>
								}
								onChange={handledate}
								value={date}
							/>
							<button className="mx-2" onClick={prevDay}>
								<GrPrevious />
							</button>{" "}
							<button className="mx-2" onClick={nexTDay}>
								<GrNext />
							</button>
						</div>
					</div>

					{loading ? (
						<div className="text-center m-5 p-5">
							<HashLoader
								color="#5755d9"
								size={30}
								style={{ position: "absolute", right: "50%" }}
							/>
						</div>
					) : (
						<ReactTabulator
							columns={columns}
							data={attdata}
							options={options}
							events={{
								rowClick: rowClick,
							}}
							className="mt-5 table-report table-report--tabulator"
						/>
					)}
				</div>
			</div>

			<div
				className="container-fluid"
				style={{ maxHeight: "fit-content" }}
			>
				<Modal isOpen={isopen} style={customStyles} contentLabel="">
					<div className="flex items-center sticky top-0 z-50">
						<h2 className="font-medium text-base mr-auto">
							Preview
						</h2>
						<button
							className="btn btn-outline-secondary  sm:flex"
							onClick={() => closeModal()}
						>
							<FaTimes />
						</button>
					</div>

					<img
						src={imgsrc}
						className="h-100 w-100"
						alt="Preview Img"
					/>
				</Modal>
			</div>
		</>
	);
};

export default Activity;
