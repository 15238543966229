import React from "react";
import Chart from "react-apexcharts";
import equal from "fast-deep-equal";

class ApexChart extends React.Component {
  componentDidUpdate(prevProps) {
    if (!equal(this.props.data, prevProps.data)) {
    }
  }

  constructor(props) {
    super(props);
    let arr = [];

    if (props.rowData === undefined) return;

    let obj = {
      x: "Hourly",
      y: [
        new Date(
          props.rowData[0].date + " " + props.rowData[0].hours + ":00:00"
        ).getTime(),
        new Date(
          props.rowData[0].date + " " + props.rowData[0].endtime
        ).getTime(),
      ],
      fillColor: "#ffffff",
    };
    arr.push(obj);

    props.rowData.forEach((element) => {
      let obj = {
        x: "Hourly",
        y: [
          new Date(element.date + " " + element.starttime).getTime(),
          new Date(element.date + " " + element.endtime).getTime(),
        ],
        fillColor: element.color,
      };
      arr.push(obj);
    });

    let obj1 = {
      x: "Hourly",
      y: [
        new Date(
          props.rowData[0].date +
            " " +
            props.rowData[props.rowData.length - 1].endtime
        ).getTime(),
        new Date(
          props.rowData[0].date +
            " " +
            props.rowData[props.rowData.length - 1].hours +
            ":59:59"
        ).getTime(),
      ],
      fillColor: "#ffffff",
    };
    arr.push(obj1);

    this.state = {
      series: [
        {
          name: "0",
          data: arr,
        },
      ],
      options: {
        chart: {
          type: "rangeBar",
          height: -50,
          parentHeightOffset: -50,
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        selection: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "10%",
            rangeBarGroupRows: true,
          },
        },
        fill: {
          type: "solid",
        },
        grid: {
          show: false,
          padding: {
            top: -70,
            bottom: 0,
          },
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        tooltip: {
          custom: function (opts) {
            return "";
          },
        },
      },
    };
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="rangeBar"
        height={100}
      />
    );
  }
}

export default ApexChart;
