import React, { useEffect } from "react";
import { FaCalendar, FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import swal from "sweetalert";
import { BASEURL } from "../BASEURL";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

const PunchTicket = () => {
	const [addShow, setaddShow] = useState(false);
	const [showTicket, setShowticket] = useState([]);
	const [userdata] = useState(JSON.parse(localStorage.getItem("isAuth")));
	const [updateShow, setUpdateShow] = useState(false);
	const [AccepTicket, setacceptTicket] = useState([]);
	// console.log("userdata id ", userdata)
	const [data, setdata] = useState({
		date: "",
		punch_start_time: "00:00:00",
		punch_end_time: "00:00:00",
		lunch_start_time: "00:00:00",
		lunch_end_time: "00:00:00",
		reason: "",
		status: 0,
	});
	const handleAdd = () => {
		setaddShow(true);
		setUpdateShow(false);
		// setaddShow(false);
	};
	const handleCancel = () => {
		setaddShow(false);
		setUpdateShow(false);
		setdata({
			date: "",
			punch_start_time: "00:00:00",
			punch_end_time: "00:00:00",
			lunch_start_time: "00:00:00",
			lunch_end_time: "00:00:00",
			reason: "",
			status: 0,
		});
	};
	const handlechange = (e) => {
		let value = e.target.value;
		setdata({
			...data,
			[e.target.name]: value,
		});
	};
	const handleDatechage = (date) => {
		const adjustedDate = new Date(
			date.getTime() + date.getTimezoneOffset() * 60000
		);
		setdata({
			...data,
			date: adjustedDate,
		});
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(BASEURL + "add-emp-ticket", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ data, username: userdata.username }),
			});
			if (response.ok) {
				setdata({
					date: "",
					punch_start_time: "00:00:00",
					punch_end_time: "00:00:00",
					lunch_start_time: "00:00:00",
					lunch_end_time: "00:00:00",
					reason: "",
					status: 0,
				});
				GetempTicket();
			} else if (!response.ok) {
				throw new Error("Failed to submit form data");
			}
			swal({
				title: "Success",
				text: "Ticket points added successfully",
				icon: "success",
				timer: 2000,
			});
		} catch (error) {
			console.error("Error submitting form data:", error);
		}
	};
	const GetempTicket = async () => {
		try {
			const response = await fetch(BASEURL + "show-emp-ticket", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: userdata.username,
					role: userdata.role,
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setShowticket(data.data);
				// console.log('Response data:', data);
			} else {
				throw new Error("Failed to fetch data");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const UpdateTicket = async (id) => {
		console.log("updating ticket id : " + id);
		try {
			const response = await fetch(BASEURL + `update-emp-ticket/${id}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
			const data = await response.json();
			if (response) {
				Swal.fire({
					icon: "success",
					title: "Ticket updated successfully",
					showConfirmButton: false,
					timer: 1000,
				});
				GetempTicket();
			} else {
				console.log("Error your leave request is not updated");
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};
	useEffect(() => {
		GetempTicket();
	}, []);
	const [currentPage, setCurrentPage] = useState(0);
	const [dataPerPage] = useState(10);
	const indexOfLastData = (currentPage + 1) * dataPerPage;
	const indexOfFirstData = indexOfLastData - dataPerPage;
	const currentData = showTicket.slice(indexOfFirstData, indexOfLastData);
	const handlePageChange = ({ selected }) => {
		setCurrentPage(selected);
	};
	const handleAction = async (id, action) => {
		try {
			const response = await fetch(
				BASEURL + `admin-update-ticket/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ action }),
				}
			);
			if (response.ok) {
				showTicket.filter((ticket) => {
					if (ticket.id === id) {
						ticket.status = action === "accept" ? 1 : 2;
						setacceptTicket(ticket);
					}
					return ticket;
				});
				swal({
					title: "Success",
					text: `Ticket ${
						action === "accept" ? "accepted" : "rejected"
					} successfully`,
					icon: "success",
					timer: 2000,
				});
			} else {
				throw new Error(`Failed to ${action} ticket`);
			}
		} catch (error) {
			console.error(`Error ${action}ing ticket:`, error);
		}
	};
	const handleAccept = async (id) => {
		handleAction(id, "accept");
		handleAttendance(id);
		try {
			// const response = await fetch(BASEURL + "add-attendance", {
			const response = await fetch(BASEURL + "add-emp-ticket", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ AccepTicket }),
			});
			if (response.ok) {
				setdata({
					date: "",
					punch_start_time: "00:00:00",
					punch_end_time: "00:00:00",
					lunch_start_time: "00:00:00",
					lunch_end_time: "00:00:00",
					reason: "",
					status: 0,
				});
				GetempTicket();
			} else if (!response.ok) {
				throw new Error("Failed to submit form data");
			}
			swal({
				title: "Success",
				text: "Ticket points added successfully",
				icon: "success",
				timer: 2000,
			});
		} catch (error) {
			console.error("Error submitting form data:", error);
		}
	};
	const handleReject = (id) => {
		handleAction(id, "reject");
		handleAttendance(id);
	};
	console.log(" filter accepted and rejected data id", AccepTicket);
	const handleAttendance = async () => {
		try {
			const response = await fetch(BASEURL + "add-attendance", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ AccepTicket }),
			});
			if (response.ok) {
				setdata({
					date: "",
					punch_start_time: "00:00:00",
					punch_end_time: "00:00:00",
					lunch_start_time: "00:00:00",
					lunch_end_time: "00:00:00",
					reason: "",
					status: 0,
				});
				GetempTicket();
			} else if (!response.ok) {
				throw new Error("Failed to submit form data");
			}
			swal({
				title: "Success",
				text: "Ticket points added successfully",
				icon: "success",
				timer: 2000,
			});
		} catch (error) {
			console.error("Error submitting form data:", error);
		}
	};
	return (
		<div className="intro-y box lg:mt-5">
			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
				<h2 className="font-medium text-base mr-auto">
					Attendance Ticket
				</h2>
			</div>
			<div className="p-5">
				<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
					{addShow === true ? (
						<div
							className="flex-1 mt-6 xl:mt-0 p-3 mb-2"
							style={{
								border: "1px solid lightgray",
								backgroundColor:
									"rgb(var(--color-slate-100) / var(--tw-bg-opacity))",
								borderRadius: "5px",
							}}
						>
							<form method="POST" onSubmit={handleSubmit}>
								<div className="grid grid-cols-12 gap-x-5">
									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-2"
												className="form-label"
											>
												Date
											</label>
											<DatePicker
												selected={data.date}
												clearIcon=""
												onChange={handleDatechage}
												name="date"
												calendarIcon={
													<FaCalendar
														style={{
															color: "rgb(30 64 175)",
														}}
													/>
												}
												className="form-control"
											/>
										</div>
									</div>
									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-1"
												className="form-label"
											>
												Punch Start Time{" "}
												<span className="text-danger">
													(*)
												</span>
											</label>
											<input
												id="update-profile-form-1"
												type="text"
												required
												name="punch_start_time"
												className="form-control"
												onChange={handlechange}
												value={data.punch_start_time}
											/>
										</div>
									</div>
									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-1"
												className="form-label"
											>
												Punch End Time{" "}
												<span className="text-danger">
													(*)
												</span>
											</label>
											<input
												id="update-profile-form-1"
												type="text"
												required
												name="punch_end_time"
												className="form-control"
												onChange={handlechange}
												value={data.punch_end_time}
											/>
										</div>
									</div>
									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-1"
												className="form-label"
											>
												Lunch Start Time{" "}
												<span className="text-danger">
													(*)
												</span>
											</label>
											<input
												id="update-profile-form-1"
												type="text"
												required
												name="lunch_start_time"
												className="form-control"
												onChange={handlechange}
												value={data.lunch_start_time}
											/>
										</div>
									</div>
									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-1"
												className="form-label"
											>
												Lunch End Time{" "}
												<span className="text-danger">
													(*)
												</span>
											</label>
											<input
												id="update-profile-form-1"
												type="text"
												required
												name="lunch_end_time"
												className="form-control"
												onChange={handlechange}
												value={data.lunch_end_time}
											/>
										</div>
									</div>
									<div className="col-span-12 2xl:col-span-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Reason{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<br />
										<textarea
											name="reason"
											className="form-control"
											required
											cols="80"
											rows="2"
											onChange={handlechange}
											value={data.reason}
										></textarea>
									</div>
								</div>
								{updateShow && (
									<button
										type="submit"
										className="btn btn-success text-white mr-2 w-20 mt-3"
										onClick={UpdateTicket}
									>
										Update
									</button>
								)}
								{!updateShow && (
									<button
										type="submit"
										className="btn btn-primary w-20 mt-3"
									>
										Save
									</button>
								)}
								<button
									type="button"
									onClick={() => handleCancel()}
									className="btn btn-danger w-20 mt-3 ml-2"
								>
									Cancel
								</button>
								<br />
								<br />
								<span className={"text-success"}></span>
							</form>
						</div>
					) : null}
				</div>
				<div className="overflow-x-auto">
					<table
						className="table table-bordered"
						style={{ width: "100%" }}
					>
						<thead>
							<tr>
								<th className="whitespace-nowrap">id</th>
								<th className="whitespace-nowrap">Action</th>
								<th className="whitespace-nowrap">Username</th>
								<th className="whitespace-nowrap">Date</th>
								<th className="whitespace-nowrap">
									Punch Start{" "}
								</th>
								<th className="whitespace-nowrap">
									Punch End{" "}
								</th>
								<th className="whitespace-nowrap">
									Lunch Start{" "}
								</th>
								<th className="whitespace-nowrap">
									Lunch End{" "}
								</th>
								<th className="whitespace-nowrap">Reason</th>
								<th className="whitespace-nowrap">status</th>
							</tr>
						</thead>
						<tbody>
							{currentData.map((ticket, index) => (
								<tr key={index}>
									<td>{ticket.id}</td>
									<td>
										<div
											className="d-flex justify-content-between"
											style={{
												display: "flex",
												justifyContent: "space-evenly",
											}}
										>
											<button
												className="btn btn-success text-white mx-2 btn-sm"
												onClick={() =>
													handleAccept(ticket.id)
												}
											>
												ACCEPT
											</button>
											<button
												className="btn btn-danger  btn-sm"
												onClick={() =>
													handleReject(ticket.id)
												}
											>
												REJECT
											</button>
										</div>
									</td>
									<td>{ticket.username}</td>
									<td>
										{format(
											new Date(ticket.date),
											"yyyy-MM-dd"
										)}
									</td>
									<td>{ticket.punch_start_time}</td>
									<td>{ticket.punch_end_time}</td>
									<td>{ticket.lunch_start_time}</td>
									<td>{ticket.lunch_end_time}</td>
									<td>{ticket.reason}</td>
									<td>
										{ticket.status === 0 && (
											<button className="btn btn-warning text-white btn-sm">
												Pending
											</button>
										)}
										{ticket.status === 1 && (
											<button className="btn btn-success text-white btn-sm">
												Approved
											</button>
										)}
										{ticket.status === 2 && (
											<button className="btn btn-danger text-white btn-sm">
												Rejected
											</button>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
						<br />
						<nav className="w-full sm:w-auto sm:mr-auto">
							<div className="row">
								<div className="col-md-3"></div>
								<div className="col-md-4">
									<ReactPaginate
										breakLabel={"..."}
										pageCount={Math.ceil(
											showTicket.length / dataPerPage
										)}
										marginPagesDisplayed={2}
										pageRangeDisplayed={5}
										onPageChange={handlePageChange}
										containerClassName={"pagination"}
										activeClassName={"active"}
										pageClassName={"page-item"}
										previousClassName={"page-item"}
										nextClassName={"page-item"}
										pageLinkClassName={"page-link"}
										previousLinkClassName={"page-link"}
										nextLinkClassName={"page-link"}
										breakClassName={"page-item"}
										breakLinkClassName={"page-link"}
										previousLabel={<>&laquo;</>}
										nextLabel={<>&raquo;</>}
									/>
								</div>
								<div className="col-md-4"></div>
							</div>
						</nav>
					</div>
				</div>
			</div>
		</div>
	);
};
export default PunchTicket;
