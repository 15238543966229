import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { authLogin, SendOtp, VerifyOtp, ResendOtp, autlogout, getGlobalConfigData, getUserProfileDetails } from './authService'
import { userSettings } from '../../../data/userSettings';
const initialProfileSettings = {
    "sms_service_status": false,
    "balance_status": false,
    "recharge_status": false,
    "toll_free_flag": false,
    "toll_free_number": "",
    "im_service_status": false
};
const getUserData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
const customSettings = localStorage.getItem('_customSettings') ? JSON.parse(localStorage.getItem('_customSettings')) : {
    _account: true,
    _autoAnswer: false,
    _dnd: false,
    _i18n: "en",
    _pcsRegStatus: "NOT_REGISTERED_STATE",
    _sipRegStatus: "NOT_REGISTERED_STATE",
};
const accountCreds = localStorage.getItem('_accountCreds') ? JSON.parse(localStorage.getItem('_accountCreds')) : null;
const profileConfigSettings = localStorage.getItem('_profileConfigSettings') ? JSON.parse(localStorage.getItem('_profileConfigSettings')) : initialProfileSettings;
const access_tocken = localStorage.getItem('access_tocken') ? localStorage.getItem('access_tocken') : null;
const otp_auth_token = localStorage.getItem('otp_auth_tocken') ? localStorage.getItem('otp_auth_tocken') : null;


const initialState = {
    user: getUserData,
    isLoading: false,
    isSucess: true,
    isError: false,
    message: null,
    tocken: access_tocken,
    otp_auth_tocken: otp_auth_token,
    customSettings: customSettings,
    accountCreds: accountCreds,
    profileConfigSettings: profileConfigSettings,
    status: "ERROR",
    userSettings: userSettings,
    globalConfig: {},
    UserProfileDetails: {}
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        UpdatecustomSettings: (state, action) => {
            const { key, value } = action.payload
            let customSettings = state.customSettings;
            customSettings[key] = value
            state.customSettings = customSettings;
            localStorage.setItem('_customSettings', JSON.stringify(state.customSettings));
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed

        //login reducer
        builder.addCase(getGlobalConfigData.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getGlobalConfigData.fulfilled, (state, action) => {
                const { status, message, data } = action.payload
                state.globalConfig = data;
                state.isLoading = false;
            })
            .addCase(getGlobalConfigData.rejected, (state, action) => {
                state.isError = true;
                state.message = action.payload
            })
            .addCase(authLogin.pending, (state) => {
                state.isLoading = true;
            }).addCase(authLogin.fulfilled, (state, action) => {
                const { status, message, data, access_token } = action.payload;
                if (status === 'ERROR') {
                    state.isError = true;
                    state.isSucess = false;
                    state.user = null;
                    state.isLoading = false;
                    toast.error(message);
                } else {
                    state.user = data;
                    state.isError = false;
                    state.isLoading = false;
                    state.isSucess = true;
                    state.tocken = access_token;
                    toast.success(message);
                }
                state.message = action.payload.message;
                // Add user to the state array

            }).addCase(authLogin.rejected, (state, action) => {
                state.user = null;
                state.isError = true;
                state.message = action.payload;
                toast.error(action.message);
            })


            //// send otp
            .addCase(SendOtp.pending, (state) => { state.isLoading = true })
            .addCase(SendOtp.fulfilled, (state, action) => {
                state.otp_auth_tocken = action.payload.data.otp_auth_token;
                const { status, message } = action.payload;
                state.status = status;
                if (status === 'ERROR') {
                    state.isError = true;
                    state.isSucess = false;
                    toast.error(message);
                } else {
                    state.isError = false;
                    state.isLoading = false;
                    state.isSucess = true;
                    toast.success(message);
                }
            })

            /// verify otp
            .addCase(VerifyOtp.fulfilled, (state, action) => {
                const { status, message, data, access_token } = action.payload;
                if (status === 'ERROR') {
                    state.isError = true;
                    state.isSucess = false;
                    state.user = null;
                    state.isLoading = false;
                    toast.error(message);
                } else {
                    state.user = data;
                    state.isError = false;
                    state.isLoading = false;
                    state.isSucess = true;
                    state.tocken = access_token
                    toast.success(message);
                }
                state.message = message;
                // Add user to the state array
            }).addCase(VerifyOtp.rejected, (state, action) => {
                state.user = null;
                state.isError = true;
                state.message = action.payload;
                toast.error(action.message);
            })

            ////// Resend Otp
            .addCase(ResendOtp.fulfilled, (state, action) => {
                state.otp_auth_tocken = action.payload.data.otp_auth_token;
                const { status, message, data, } = action.payload;
                state.status = status;
                if (status === 'ERROR') {
                    state.isError = true;
                    state.isSucess = false;
                    toast.error(message);
                } else {
                    state.isError = false;
                    state.isLoading = false;
                    state.isSucess = true;
                    toast.success(message);
                }
            })

            .addCase(autlogout.fulfilled, (state, action) => {
                const { status, message, data } = action.payload;

                if (status === 'ERROR') {
                    state.isError = true;
                    state.isSucess = false;
                    toast.error(message);
                } else {
                    state.user = null;
                    state.isError = false;
                    state.isLoading = false;
                    state.isSucess = true;
                    toast.success(message);
                }
            })
            .addCase(getUserProfileDetails.fulfilled, (state, action) => {
                // console.debug('set new Settings ')
                const account_properties = action?.payload?.account_properties
                state.UserProfileDetails = account_properties;
                state.userSettings = account_properties
                state.accountCreds = action.payload?.sipData || {}
            })
            .addCase(getUserProfileDetails.rejected, (state, action) => {
                console.debug('set user settings fail', action.payload)
            })
    },
})

// Action creators are generated for each case reducer function

export default authSlice.reducer
export const { UpdatecustomSettings } = authSlice.actions;