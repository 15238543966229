import {
  GETSCREENSHOT,
  SET_SCREENSHOT,
  GETATTANDANCE,
  GETLEAVES,
  SETLEAVES,
  GETPRODUCTIVITY,
  SET_PRODUCTIVITY,
  SET_ATTANDANCE,
  SET_STOPWATCH,
  GET_STOPWATCH,
  TABTYPE,
  SHOW_LOADING,
  HIDE_LOADING,
  SWITCH_THEME,
  GET_CURRENT_EMP
} from "../constants/Constant";

const initState = {
  tabtypeval: 0,
  loading: false,
  data: [],
  productivitydata: [],
  attendancedata: [],
  leavedata: [],
  timerdata: "",
  currentTheme: 0,
  currentEmp: "",
};

const reducer = (state = initState, action) => {
  
  switch (action.type) {
    case SWITCH_THEME:
      // console.log(action.currentTheme);
      return {
        ...state,
        currentTheme: action.currentTheme
    };
    case GET_CURRENT_EMP:
      return {
        ...state,
        currentEmp: action.currentEmp
    };
    case GETSCREENSHOT:
      return {
        ...state,
        loading: true,
      };
    case SET_SCREENSHOT:
      return {
        ...state,
        data: action.message,

        loading: false,
      };

    case GETPRODUCTIVITY:
      return {
        ...state,
        loading: true,
      };
    case GETATTANDANCE:
      return {
        ...state,
        loading: true,
      };
    case GETLEAVES:
      return {
        ...state,
        loading: true,
      };
    case SET_PRODUCTIVITY:
      return {
        ...state,
        productivitydata: action.message,
        loading: false,
      };
    case SET_ATTANDANCE:
      return {
        ...state,
        attendancedata: action.message,
        loading: false,
      };
    case SETLEAVES:
      return {
        ...state,
        leavedata: action.message,
        loading: false,
      };

    case SET_STOPWATCH:
      return {
        ...state,
        timerdata: action.message,
        loading: false,
      };

    case GET_STOPWATCH:
      return {
        ...state,
        loading: true,
      };

    case TABTYPE:
      return {
        ...state,
        tabtypeval: action.payload,
      };

    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
