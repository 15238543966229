import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { useParams } from "react-router-dom";
import {
	FaPhone,
	FaCalendar,
	FaEnvelope,
	FaMapMarker,
	FaUser,
	FaTimes,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { ReactTabulator } from "react-tabulator";
import { format } from "date-fns";
import Modal from "react-modal";
import MonthlyAttandance from "./empdetails/MonthlyAttandance";
import Leaves from "./empdetails/Leaves";
import LeaveSummary from "./empdetails/LeaveSummary";
import Activity from "./empdetails/Activity";
import Feeds from "./empdetails/Feeds";
import ManagerDetails from "./empdetails/ManagerDetails";
import Documents from "./empdetails/Documents";
import Profile from "./empdetails/Profile";
import { FaUserCircle } from "react-icons/fa";
import { onCurrentEmp } from "../redux/actions/Action";
import { useSelector, useDispatch } from "react-redux";

Modal.setAppElement("#root");

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "10px",
		border: "1px solid rgb(187, 181, 181)",
		borderRadius: "14px",
		overflowX: "hidden",
		overflowY: "visible",
		width: "600px",
	},
};

const Employees_details = () => {
	const [userdata, setuserdata] = useState(null);
	const [attdata, setattdata] = useState(null);
	let [IsOpen, setIsOpen] = useState(false);

	let [isAuth, setisAuth] = useState(
		JSON.parse(localStorage.getItem("isAuth"))
	);
	const params = useParams();

	let [isTab, setisTab] = useState(0);
	const dispatch = useDispatch();

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		console.log(params.user_id);
		await fetch(BASEURL + `getUserDetails/${params.user_id}`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 0,
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setuserdata(res.userdata);
					dispatch(onCurrentEmp(res.userdata[0].name));
					let attarr = [];
					if (res.attdata.length > 0) {
						res.attdata.forEach((element) => {
							let date1 = new Date(
								format(new Date(element.date), "yyyy-MM-dd") +
									" " +
									element.start_time
							);
							let date2 = new Date(
								format(new Date(element.date), "yyyy-MM-dd") +
									" " +
									element.end_time
							);
							let diff = date2.getTime() - date1.getTime();
							let msec = diff;
							let hh = Math.floor(msec / 1000 / 60 / 60);
							msec -= hh * 1000 * 60 * 60;

							let mm = Math.floor(msec / 1000 / 60);
							msec -= mm * 1000 * 60;
							let ss = Math.floor(msec / 1000);
							msec -= ss * 1000;

							let mmm = mm >= 10 ? mm : "0" + mm;
							let hhh = hh >= 10 ? hh : "0" + hh;
							let sss = ss >= 10 ? ss : "0" + ss;

							let data = {
								date: format(
									new Date(element.date),
									"yyyy-MM-dd"
								),
								in: element.start_time,
								out: element.end_time,
								duration:
									element.end_time !== "00:00:00"
										? hhh + ":" + mmm + ":" + sss
										: "-",
								details: "More Details",
							};

							attarr.push(data);
						});
					}

					setattdata(attarr);
				}
			})
			.catch((err) => console.log(err));
	};

	const columns = [
		{ title: "Date", field: "date", width: 150 },
		{ title: "In", field: "in" },
		{ title: "Out", field: "out" },
		{ title: "Duration", field: "duration" },
	];

	const options = {
		pagination: "local",
		paginationSize: 50,
	};

	function closeModal() {
		setIsOpen(false);
	}

	let Attendance = () => {
		return (
			<>
				<div className="intro-y flex flex-col sm:flex-row items-center mt-8">
					<h2 className="text-lg font-medium mr-auto">Attandance</h2>
				</div>
				<div className="intro-y box">
					<div className="scrollbar-hidden">
						<ReactTabulator
							columns={columns}
							data={attdata}
							options={options}
							className="mt-5 table-report table-report--tabulator"
						/>
					</div>
				</div>
			</>
		);
	};
	return (
		<>
			<div className="intro-y flex items-center mt-8">
				<h2 className="text-lg font-medium mr-auto">Profile Layout</h2>
			</div>

			<div className="intro-y box px-5 pt-5 mt-5">
				<div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5 -mx-5">
					<div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
						<div className="">
							<FaUserCircle
								className="text-5xl"
								style={{ color: "#1d45ab" }}
							/>
						</div>
						<div className="ml-5 text-left">
							<div className="w-30 sm:w-60 truncate sm:whitespace-normal font-medium text-lg d-flex">
								{userdata && userdata[0].name} (
								{userdata && userdata[0].username})
							</div>
							<div className="text-slate-500">
								{userdata && userdata[0].deptname}
							</div>
							{isAuth.role !== 0 && (
								<div className="text-slate-500">
									Edit Profile
								</div>
							)}
						</div>
					</div>
					<div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
						<div className="font-medium text-center lg:text-left lg:mt-3">
							Contact Details
						</div>
						<div className="flex flex-col justify-center items-center lg:items-start mt-2">
							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaPhone />
								</i>
								{userdata && userdata[0].contact_no} |
								{userdata && userdata[0].alternate_no}
							</div>

							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaEnvelope />
								</i>
								{userdata && userdata[0].email}
							</div>
						</div>

						<div className="font-medium text-center lg:text-left lg:mt-3">
							Date of Joining
						</div>
						<div className="flex flex-col justify-center items-center lg:items-start mt-2">
							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaCalendar />
								</i>
								{userdata &&
									format(
										new Date(userdata[0].date_of_joining),
										"yyyy-MM-dd"
									)}
							</div>
						</div>
					</div>

					<div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
						<div className="font-medium text-center lg:text-left lg:mt-3">
							Date of Birth
						</div>
						<div className="flex flex-col justify-center items-center lg:items-start mt-2">
							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaCalendar />
								</i>
								{userdata &&
									format(
										new Date(userdata[0].dob),
										"yyyy-MM-dd"
									)}
							</div>

							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaMapMarker />
								</i>
								{userdata && userdata[0].permanent_address}
							</div>
						</div>

						<div className="font-medium text-center lg:text-left lg:mt-3">
							Height | Weight | Blood Group
						</div>
						<div className="flex flex-col justify-center items-center lg:items-start mt-2">
							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaUser />
								</i>
								{userdata &&
									userdata[0].height +
										" | " +
										userdata[0].weight +
										" | " +
										userdata[0].blood_group}
							</div>
						</div>
					</div>

					<div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
						<div className="font-medium text-center lg:text-left lg:mt-5">
							System Information
						</div>
						<div className="flex items-center justify-center lg:justify-start mt-2">
							<div className="mr-2 w-30 flex">
								Last Seen:{" "}
								<span className="ml-3 font-medium ">+23%</span>
							</div>
						</div>
						<div className="flex items-center justify-center lg:justify-start">
							<div className="mr-2 w-20 flex">
								System:{" "}
								<span className="ml-3 font-medium">win32</span>
							</div>
						</div>
					</div>
				</div>
				<ul
					className="nav nav-link-tabs  flex-wrap flex-col sm:flex-row justify-center sm:justify-start text-center"
					role="tablist"
					style={{ cursor: "pointer" }}
				>
					<li
						id="dashboard-tab"
						className="nav-item"
						role="presentation"
					>
						<div
							className={
								isTab === 0
									? "nav-link py-4 active"
									: "nav-link py-4"
							}
							onClick={() => setisTab(0)}
							data-tw-target="#dashboard"
							aria-controls="dashboard"
							aria-selected="true"
							role="tab"
						>
							Attandance
						</div>
					</li>

					<li
						id="dashboard-tab"
						className="nav-item"
						role="presentation"
					>
						<div
							className={
								isTab === 1
									? "nav-link py-4 active"
									: "nav-link py-4"
							}
							onClick={() => setisTab(1)}
							data-tw-target="#dashboard"
							aria-controls="dashboard"
							aria-selected="true"
							role="tab"
						>
							Monthly Attandance
						</div>
					</li>

					<li
						id="activities-tab"
						className="nav-item"
						role="presentation"
					>
						<div
							className={
								isTab === 2
									? "nav-link py-4 active"
									: "nav-link py-4"
							}
							onClick={() => setisTab(2)}
							data-tw-target="#activities"
							aria-selected="false"
							role="tab"
						>
							Leave
						</div>
					</li>

					<li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 3
									? "nav-link py-4 active"
									: "nav-link py-4"
							}
							onClick={() => setisTab(3)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>
							Leave Summary
						</div>
					</li>

					<li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 6
									? "nav-link py-4 active"
									: "nav-link py-4"
							}
							onClick={() => setisTab(6)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>
							Activity
						</div>
					</li>
					<li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 7
									? "nav-link py-4 active"
									: "nav-link py-4"
							}
							onClick={() => setisTab(7)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>
							Details
						</div>
					</li>
					<li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 8
									? "nav-link py-4 active"
									: "nav-link py-4"
							}
							onClick={() => setisTab(8)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>
							Managers
						</div>
					</li>
					<li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 9
									? "nav-link py-4 active"
									: "nav-link py-4"
							}
							onClick={() => setisTab(9)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>
							Documents
						</div>
					</li>
					<li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 10
									? "nav-link py-4 active"
									: "nav-link py-4"
							}
							onClick={() => setisTab(10)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>
							Feeds
						</div>
					</li>
				</ul>
			</div>

			<div className="intro-y tab-content mt-5">
				<div
					id="dashboard"
					className="tab-pane active"
					role="tabpanel"
					aria-labelledby="dashboard-tab"
				>
					{isTab === 0 ? (
						<Attendance />
					) : isTab === 1 ? (
						<MonthlyAttandance />
					) : isTab === 2 ? (
						<Leaves />
					) : isTab === 3 ? (
						<LeaveSummary />
					) : isTab === 6 ? (
						<Activity />
					) : isTab === 7 ? (
						<Profile />
					) : isTab === 8 ? (
						<ManagerDetails />
					) : isTab === 9 ? (
						<Documents />
					) : isTab === 10 ? (
						<Feeds />
					) : null}
				</div>
			</div>
			{isAuth.role === 0 && (
				<div className="text-left mt-3">
					<Link
						to="/employees"
						className="btn btn-primary btn-sm text-white"
					>
						Back to All Employees
					</Link>
				</div>
			)}
			<Modal isOpen={IsOpen} style={customStyles} contentLabel="">
				<div
					className="row"
					style={{
						marginRight: "10px",
						marginTop: "0px",
						paddingBottom: "5px",
						marginLeft: "3px",
						borderBottom: "1px solid #f5ebeb",
					}}
				>
					<div className="flex items-center">
						<h2 className="font-medium text-base mr-auto">
							More details
						</h2>
						<button
							className="btn btn-outline-secondary hidden sm:flex"
							onClick={() => closeModal()}
						>
							<FaTimes />
						</button>
					</div>
				</div>

				<div
					className="wrapper-grey pt-1 overflow-auto"
					style={{ marginRight: "10px" }}
				>
					<div className="dialoag-container_2TwSP">
						<div className="title_1ce7i">Punch In Details</div>
						<div className="content_3dQ6a">
							<span className="key_2Wvze">
								IP&nbsp;&nbsp;:&nbsp;&nbsp;
							</span>
							<span className="value_1cs5S">202.131.97.66</span>
						</div>
						<div className="content_3dQ6a">
							<span className="key_2Wvze">
								Device&nbsp;&nbsp;:&nbsp;&nbsp;
							</span>
							<span className="value_1cs5S">
								Mozilla/5.0 (Windows NT 6.3; WOW64)
								AppleWebKit/537.36 (KHTML, like Gecko)
								TrackWick/4.6.0 Chrome/66.0.3359.181
								Electron/3.1.9 Safari/537.36
							</span>
						</div>
						<div className="content_3dQ6a">
							<span className="key_2Wvze">
								Platform&nbsp;&nbsp;:&nbsp;&nbsp;
							</span>
							<span className="value_1cs5S">DESKTOP</span>
						</div>
						<div className="content_3dQ6a">
							<span className="key_2Wvze">
								Address&nbsp;&nbsp;:&nbsp;&nbsp;
							</span>
							<span className="value_1cs5S">
								Ahmedabad, GJ, India, 380009
							</span>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Employees_details;
