import React, { useEffect, useState,useMemo } from "react";
import { BASEURL } from "../BASEURL";
import { format } from "date-fns";
import { FaTimes } from "react-icons/fa";
import Modal from "react-modal";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import HashLoader from "react-spinners/HashLoader";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  AiOutlineZoomIn,
  AiOutlineZoomOut,
  AiOutlineCloseCircle,
} from "react-icons/ai";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    padding: "0px",
    border: "1px solid rgb(187, 181, 181)",
    borderRadius: "14px",
  },
};

// const SummaryScreenshots = React.memo(props) => {
  const SummaryScreenshots = React.memo(props => {
  let [loading, setLoading] = useState(false);
  const [ssdata, setssdata] = useState([]);
  const [popupdata, setpopupdata] = useState([]);
  const [icons, seticons] = useState([]);
  const [date, setdate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [username, setusername] = useState();
  const [isopen, setIsOpen] = useState(null);
  const [disable, setdisable] = useState(false);
  const [disablenext, setdisablenext] = useState(false);

  useEffect(() => {
    setdate(format(new Date(props.date), "yyyy-MM-dd"));
    if(props.username!=undefined){
      getScreenshotData(
        format(new Date(props.date), "yyyy-MM-dd"),
        props.username
      );
    }
  }, [props.username, props.date]);

  const getScreenshotData = async (date, username) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-screenshotData/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date,
        username,
        company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        master:JSON.parse(localStorage.getItem("isAuth")).master
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setusername(username);
          setssdata(res.data);
          seticons(res.icons);
        } else {
          setssdata([]);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const handlepopup = async (data) => {
    setpopupdata(data);
    appdetails(data);
    setIsOpen(true);
  };

  function formatTime(seconds) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);
    return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
      .filter(Boolean)
      .join(":");
  }

  const appdetails = (data) => {
    if (data) {
      let windetails = JSON.parse(data.active_window_details);
      let totaldec = windetails.reduce(function (sum, current) {
        
        return sum + current.time;
      }, 0);
      let arr = [];

      windetails.forEach((element) => {
        let icon = icons.filter((item, i) => {
          return item.app_name === element.app;
        });
        let per = Math.round((element.time * 100) / totaldec);
        arr.push(
          <div className="box mt-2 ml-2" style={{ minWidth: "230px" }}>
            <div className="col-span-2 sm:col-span-2 xl:col-span-2 intro-y">
              <div className="intro-y box pl-3 p-0">
                <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                  <b>
                    {element.app} <br />
                    <small className="cursor-pointer" title={element.title}>
                      {element.title.slice(0, 20)}...
                    </small>
                  </b>
                </div>
                <div className="topright">
                  <img width={20} src={icon[0].icon} alt="" srcSet="" />
                </div>
                <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                  <p>{formatTime(element.time)} mm:ss</p>
                </div>
                <div className="bottomright">
                  <CircularProgressbar
                    value={per}
                    text={`${per}%`}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      textSize: 35,
                      textColor: "black",
                      pathColor: "green",
                    })}
                    strokeWidth={10}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });

      return arr;
    } else {
      return "";
    }
  };

  const progressbardata = (item) => {
    if (
      item.clicks === 0 &&
      item.keyboardevent === 0 &&
      item.mouseevent === 0
    ) {
      return (
        <div className="progress h-2 mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{
              background: "rgb(255 0 0)",
              fontSize: "5px",
              width: "100%",
            }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      );
    }

    if (
      item.clicks !== 0 &&
      item.keyboardevent !== 0 &&
      item.mouseevent !== 0
    ) {
      return (
        <div className="progress h-2 mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "100%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      );
    }
    if (
      item.clicks === 0 &&
      item.keyboardevent === 0 &&
      item.mouseevent !== 0
    ) {
      return (
        <div className="progress h-2 mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "40%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="40"
          ></div>
        </div>
      );
    }
    if (
      item.clicks === 0 &&
      item.keyboardevent !== 0 &&
      item.mouseevent === 0
    ) {
      return (
        <div className="progress h-2 mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "40%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="40"
          ></div>
        </div>
      );
    }
    if (
      item.clicks !== 0 &&
      item.keyboardevent === 0 &&
      item.mouseevent !== 0
    ) {
      return (
        <div className="progress h-2 mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "60%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="60"
          ></div>
        </div>
      );
    }
    if (
      item.clicks !== 0 &&
      item.keyboardevent !== 0 &&
      item.mouseevent === 0
    ) {
      return (
        <div className="progress h-2 mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "70%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="70"
          ></div>
        </div>
      );
    }
    if (
      item.clicks !== 0 &&
      item.keyboardevent === 0 &&
      item.mouseevent === 0
    ) {
      return (
        <div className="progress h-2 mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "70%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="70"
          ></div>
        </div>
      );
    }
    if (
      item.clicks === 0 &&
      item.keyboardevent !== 0 &&
      item.mouseevent !== 0
    ) {
      return (
        <div className="progress h-2 mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "70%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="70"
          ></div>
        </div>
      );
    }
  };
  const screenshotdata = () => {
    let content = [];
    for (let i = 23; i >= 0; i--) {
      const arr = ssdata[i];
      if (arr) {
        let amapm = "";
        let hrs = i;
        if (i < 10) {
          hrs = "0" + i;
        }
        if (i < 12) {
          amapm = "AM";
        } else {
          amapm = "PM";
        }
        content.push(
          <div className="overflow-y-auto scrollbar-hidden px-5 pt-5 flex-1">
            <div className="intro-y block sm:flex items-center h-5">
              <strong>
                {format(new Date(date), "yyyy-MM-dd") + " " + hrs + " " + amapm}
              </strong>
            </div>

            <div className="grid grid-cols-12 gap-6 mt-5 pb-3">
              {arr &&
                arr.map((item, index) => {
                  // let url = `https://d1azebur1db3zi.cloudfront.net/screenshot/${username}/${date}/thumb/${item.image_name}`;
                  let url = `https://d1azebur1db3zi.cloudfront.net/screenshot/${username}/${date}/${item.image_name}`;
                  return (
                    <>
                      <div
                        className="col-span-12 sm:col-span-6 xl:col-span-3 2xl:col-span-2 intro-y cursor-pointer "
                        style={{ width: "100%" }}
                        onClick={() => handlepopup(item)}
                        key={index}
                      >
                        <img
                          src={url}
                          style={{ borderRadius: "6px 6px 0 0", width: "100%" }}
                          alt=""
                          className="img_2Vjah"
                        />
                        <div
                          id="progressbar-height"
                          className=""
                          style={{
                            background: "#1e40af",
                            color: "white",
                            borderRadius: "0 0 6px 6px",
                          }}
                        >
                          <div
                            className="preview"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="">
                              {item.time.substring(0, 5)} {amapm}
                              {}
                            </div>
                            <div style={{ width: "90%" }}>
                              {progressbardata(item)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        );
      }
    }

    return content;
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      {loading === true ? (
        <div className="mt-5 " style={{ display: "-webkit-inline-box" }}>
          <HashLoader color="#5755d9" size={30} />
        </div>
      ) : (
        <div
          id="style-4"
          className="intro-y chat grid grid-cols-12 gap-5 mt-2"
          style={{ overflow: "auto", height: "800px" }}
        >
          <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
            <div className="">
              <div className="h-full flex flex-col">
                {/* {!isopen ? screenshotdata() : null} */}
                {screenshotdata()}
                {ssdata?.length === 0 ? (
                  <div className="p-5 text-danger">No Screenshots Found</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      {isopen ? (
        <Modal isOpen={isopen} onRequestClose={()=>closeModal()} style={customStyles} contentLabel="">
          <div
            className="row header sticky top-0 z-50"
            style={{ padding: "9px", background: "#1e40af", color: "white" }}
          >
            <div className="flex items-center">
              <h2 className="font-medium text-base mr-auto">
                {format(new Date(popupdata.date), "yyyy-MM-dd")} {popupdata.time.slice(0, 5)}
              </h2>

              <button
                disabled={disable}
                className="btn btn-outline mr-3"
                onClick={async () => {
                  setLoading(true);
                  let time = popupdata.time.slice(0, 2);
                  if(time<10){
                    time = time.replace(/^0+/, '')
                  }
                  let currtimedata = await ssdata[`${time}`];

                  let index = await currtimedata.findIndex(
                    (p) => p.id == popupdata.id
                  );
                  if (index <= 0) {
                    setdisable(true);
                    setLoading(false);
                  } else {
                    setdisablenext(false);

                    setpopupdata(currtimedata[index - 1]);
                    appdetails();
                    setLoading(false);
                  }
                }}
              >
                Provious
              </button>

              <button
                disabled={disablenext}
                className="btn btn-outline mr-3"
                onClick={async () => {
                  setLoading(true);

                  let time = popupdata.time.slice(0, 2);
                  if(time<10){
                    time = time.replace(/^0+/, '')
                  }
                  let currtimedata = await ssdata[`${time}`];
                    
                  let index = await currtimedata.findIndex((p) => p.id == `${popupdata.id}`);
                  // console.log(index); return

                  if (index > currtimedata.length - 2) {
                    setdisablenext(true);
                    setLoading(false);
                  } else {
                    setdisable(false);
                    // console.log(index);
                    setpopupdata(currtimedata[index + 1]);
                    appdetails();
                    setLoading(false);
                  }
                }}
              >
                Next
              </button>

              <div
                className="mr-3 p-3"
                style={{ border:"1px solid white", borderRadius:"5px", cursor: "pointer" }}
                onClick={() => closeModal()}
              >
                <FaTimes />
              </div>
            </div>
          </div>

          {loading ? (
            <div className="text-center m-5 p-5">
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            </div>
          ) : (
            <div
              className="wrapper-grey pt-1"
              style={{ marginRight: "10px", padding: "10px" }}
            >
              <div className="intro-x chat grid grid-cols-12 gap-5 mt-2">
                <div
                  className="col-span-12 lg:col-span-9 2xl:col-span-9 "
                  style={{ position: "relative" }}
                >
                  <TransformWrapper initialScale={1}>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <div
                          className="tools flex-auto "
                          style={{
                            position: "absolute",
                            zIndex: "1",
                            top: "85%",
                            right: "5%",
                          }}
                        >
                          <button onClick={() => zoomIn()}>
                            <div
                              className="p-2 px-5 mx-1  "
                              style={{
                                backgroundColor: "#CBD5E0",
                                borderRadius: "8px",
                              }}
                            >
                              <AiOutlineZoomIn />
                            </div>
                          </button>
                          <button onClick={() => zoomOut()}>
                            <div
                              className="p-2 px-5 mx-1 "
                              style={{
                                backgroundColor: "#CBD5E0",
                                borderRadius: "8px",
                              }}
                            >
                              <AiOutlineZoomOut />
                            </div>
                          </button>
                          <button onClick={() => resetTransform()}>
                            <div
                              className="p-2 px-5 mx-1 "
                              style={{
                                backgroundColor: "#CBD5E0",
                                borderRadius: "8px",
                              }}
                            >
                              <AiOutlineCloseCircle />
                            </div>
                          </button>
                        </div>

                        <TransformComponent>
                          <img
                            alt=""
                            className="img-fluid"
                            src={
                              loading ? (
                                <HashLoader />
                              ) : (
                                `https://d1azebur1db3zi.cloudfront.net/screenshot/${
                                  popupdata.username
                                }/${format(
                                  new Date(popupdata.date),
                                  "yyyy-MM-dd"
                                )}/${popupdata.image_name}`
                              )
                            }
                          />
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </div>

                <div className="col-span-12 lg:col-span-3">
                  <div className="2xl:border-l -mb-10 pb-10">
                    <div className="2xl:pl-6 grid grid-cols-12 gap-x-6 2xl:gap-x-0 gap-y-6">
                      <div className="col-span-12 2xl:mt-8">
                        <div className="intro-x flex items-center h-5">
                          <h2 className="text-lg font-medium truncate mr-5">
                            Activity Level
                          </h2>
                        </div>
                        <div className="mt-5">
                          <div className="intro-x">
                            <div className="box">
                              <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                                <div className="intro-y box">
                                  <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                    <small>Activity Level in %</small>
                                  </div>
                                  <div
                                    id="progressbar-height"
                                    style={{ display: "flex" }}
                                    className="p-3 pl-0"
                                  >
                                    {progressbardata(popupdata)}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="box mt-2">
                              <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                                <div className="intro-y box pl-3 p-2">
                                  <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                    <b>Duration</b>
                                  </div>
                                  <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                    <p>02:00 mm:ss</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                            <hr
                              style={{ border: "1px solid rgb(229 231 235)" }}
                            />
                            <br />
                          </div>
                        </div>

                        <div className="intro-x flex items-center h-5">
                          <h2 className="text-lg font-medium truncate mr-5">
                            Activity Details
                          </h2>
                        </div>

                        <div className="box mt-2">
                          <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                            <div className="intro-y box pl-3 p-2">
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <b>Mouse Clicked</b>
                              </div>
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <p>{popupdata.clicks} Count</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="box mt-2">
                          <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                            <div className="intro-y box pl-3 p-2">
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <b>Mouse Moved</b>
                              </div>
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <p>{popupdata.mouseevent} Count</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="box mt-2">
                          <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                            <div className="intro-y box pl-3 p-2">
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <b>Key Down</b>
                              </div>
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <p>{popupdata.keyboardevent} Count</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 2xl:col-span-12">
                  <div className="2xl:border-l">
                    <div className="2xl:pl-6 grid grid-cols-6 gap-x-6 2xl:gap-x-0 gap-y-6">
                      <div className="col-span-12 mt-3 2xl:mt-8">
                        <div className="intro-x flex items-center h-5">
                          <h2 className="text-lg font-medium truncate mr-5">
                            Application Usage
                          </h2>
                        </div>

                        <div
                          className="appdetailsdiv"
                          style={{ display: "flex", flexFlow: "wrap" }}
                        >
                          {appdetails(popupdata)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      ) : null}
    </>
)});
export default SummaryScreenshots;
