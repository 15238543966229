import { combineReducers } from 'redux';
import Auth from './Reducer';
import authReducer from "./Auth/authSlice";
import contactReducer from "./Contacts/contactSlice";
import sipjsReducer from "./sipjs/sipjsSlice";
import themeReducer from './themeSlice'

const reducers = combineReducers({
    auth: Auth,
    // auth: authReducer,
    contacts: contactReducer,
    theme: themeReducer,
    sipjs: sipjsReducer
});

export default reducers;