import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { useParams } from "react-router-dom";
import { FaCheckCircle, FaCalendar } from "react-icons/fa";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useLoadingContext } from "react-router-loading";
import { format } from "date-fns";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import { HashLoader } from "react-spinners";
// import Select from "react-select/base";
import Select from "react-select";

const AfterOfficeHours = () => {
	let today = new Date();
	let month = [];
	month[0] = "1";
	month[1] = "2";
	month[2] = "3";
	month[3] = "4";
	month[4] = "5";
	month[5] = "6";
	month[6] = "7";
	month[7] = "8";
	month[8] = "9";
	month[9] = "10";
	month[10] = "11";
	month[11] = "12";
	let monthNumber = month[today.getMonth()];
	let [present, setpresent] = useState(0);
	let [absent, setabsent] = useState(0);
	let [onleave, setonleave] = useState(0);
	let [holiday, setholiday] = useState(0);
	const loadingContext = useLoadingContext();
	let [loading, setLoading] = useState(false);
	const [date, setdate] = useState(new Date());
	const [attdata, setattdata] = useState(null);
	const [isVisible, setisVisible] = useState(false);
	const [weeksummary, setWeekSummary] = useState([]);
	const [weekdata, setWeekData] = useState([]);
	const [monthYear, setmonthYear] = useState({});
	// const [data, setData] = useState([]);
	const currmonth = monthNumber;
	const [summary, setSummary] = useState([]);
	const [alluser, setalluser] = useState(null);
	const [username, setusername] = useState();
	const [userdatalist, setuserdata] = useState([]);
	const [selectedval, setselectedval] = useState("");
	const [orgval, setorgval] = React.useState([]);
	const curryear = today.getFullYear();
	const [tableData, setTableData] = useState();
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);
	const [isAuth, setIsAuth] = useState(
		JSON.parse(localStorage.getItem("isAuth"))
	);
	const [selectedmonth, setselectedmonth] = useState({
		year: curryear,
		month: Number(currmonth),
	});
	const params = useParams();

	console.log(isAuth?.uuid, "auth");

	useEffect(() => {
		if (selectedval?.uuid) {
			let value = JSON.parse(localStorage.getItem("monthyear"));
			const month = value ? value.month : format(new Date(), "MM");
			const year = value ? value.year : format(new Date(), "yyyy");
			getData({ year: year, month: month, uuid: selectedval.uuid });
		}
		if (isAuth?.uuid) {
			let value = JSON.parse(localStorage.getItem("monthyear"));
			const month = value ? value.month : format(new Date(), "MM");
			const year = value ? value.year : format(new Date(), "yyyy");
			getData({ year: year, month: month, uuid: isAuth?.uuid });
		}
	}, [selectedval, isAuth]);

	const columns = [
		{ title: "Date", field: "date" },
		{
			title: "Start Time",
			field: "start_time",
		},
		{
			title: "End Time",
			field: "end_time",
		},
		{
			title: "Duration",
			field: "duration",
		},
		// {
		//   title: "Punching Times",
		//   field: "punching_times",
		// },
	];

	function StatusVal(props) {
		const rowData = props.cell._cell.row.data;
		return (
			<>
				{rowData.status === 1 ? (
					<FaCheckCircle style={{ fontSize: "15px" }} />
				) : null}
			</>
		);
	}

	function ActionVal(props) {
		const rowData = props.cell._cell.row.data;
		return (
			<>
				{rowData.status === 1 && rowData.first_in !== "weekoff" ? (
					<span className="present">P</span>
				) : rowData.status === 0 || rowData.first_in === "weekoff" ? (
					<span className="absent">AB</span>
				) : null}
			</>
		);
	}
	function SimpleButton(props) {
		const rowData = props.cell._cell.row.data;
		return (
			<>
				<span
					className={
						rowData.in === "Absent" ? "text-danger" : "text-primary"
					}
				>
					{rowData.in}
				</span>
			</>
		);
	}

	const options = {
		movableRows: true,
		movableColumns: true,
		pagination: "local",
		paginationSize: 10,
	};

	console.log(selectedval?.uuid, "selectedval");

	const getData = async ({ year, month, uuid }) => {
		setLoading(true);

		let pmonth = month || format(new Date(), "MM");
		let pyear = year || format(new Date(), "yyyy");

		const obj = JSON.stringify({ month: pmonth, year: pyear });
		localStorage.setItem("checkdate", obj);

		const myHeaders = new Headers();
		const isAuth = JSON.parse(localStorage.getItem("isAuth"));
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		const roleData = {
			month: pmonth,
			year: pyear,
			company_uuid: userdata.companydata[0].uuid,
			uuid: uuid,
		};

		const employeeData = {
			month: pmonth,
			year: pyear,
			company_uuid: userdata.companydata[0].uuid,
			uuid: isAuth?.uuid,
		};

		await fetch(BASEURL + `get-after-office-hrs/${isAuth?.uuid}`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(isAuth.role === 0 ? roleData : employeeData),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 200) {
					setTableData(res?.attendance);

					let attarr = [];
					if (res.attdata && res.attdata.length > 0) {
						setonleave(res.onleave);
						setholiday(res.holiday);
						setpresent(res.present);
						setabsent(res.attdata.length - res.present);

						res.attdata.forEach((element) => {
							let date = format(
								new Date(element.date),
								"yyyy-MM-dd"
							);
							let data = {
								date: date,
								in:
									element.first_in === "00:00:00"
										? "Absent"
										: element.first_in,
								out:
									element.last_out === "00:00:00"
										? ""
										: element.last_out,
								duration:
									element.first_in === "00:00:00"
										? ""
										: element.duration,
								type:
									element.first_in === "00:00:00" ||
									element.first_in === "Weekof" ||
									element.first_in === "Holiday"
										? ""
										: "Full Day",
								status:
									element.first_in === "00:00:00" ||
									element.first_in === "Weekof" ||
									element.first_in === "Holiday"
										? 0
										: 1,
								action: "More Details",
								start_time:
									element.first_in === "00:00:00"
										? ""
										: element.first_in, // Adding start_time field
								end_time:
									element.last_out === "00:00:00"
										? ""
										: element.last_out, // Adding end_time field
								punching_times: element.punching_times || "", // Assuming punching_times is in the API response
							};
							attarr.push(data);
						});
					} else {
						setattdata([
							{
								date: "No data Available",
								in: "",
								out: "",
								duration: "",
								type: "",
								status: "",
								action: "",
								start_time: "",
								end_time: "",
								punching_times: "",
							},
						]);
					}
					setattdata(attarr);
				} else {
					setattdata([
						{
							date: "No data Available",
							in: "",
							out: "",
							duration: "",
							type: "",
							status: "",
							action: "",
							start_time: "",
							end_time: "",
							punching_times: "",
						},
					]);
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	// const getData = async ({ year, month }) => {
	// 	setLoading(true);

	// 	let pmonth;
	// 	const obj = {
	// 		month: month,
	// 		year: year,
	// 	};
	// 	localStorage.setItem("checkdate", obj);
	// 	if (year === "") {
	// 		pmonth = format(new Date(), "MM");
	// 	} else {
	// 		pmonth = month;
	// 	}
	// 	let pyear;
	// 	if (year === "") {
	// 		pyear = format(new Date(), "yyyy");
	// 	} else {
	// 		pyear = year;
	// 	}

	// 	const myHeaders = new Headers();
	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
	// 	if (userdata != null) {
	// 		let token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	myHeaders.append("Content-Type", "application/json");
	// 	await fetch(BASEURL + `get-after-office-hrs/${userdata.uuid}`, {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: JSON.stringify({
	// 			month: pmonth,
	// 			year: pyear,
	// 			company_uuid: JSON.parse(localStorage.getItem("isAuth"))
	// 				.companydata[0].uuid,
	// 		}),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((res) => {
	// 			if (res.status === 201) {
	// 				let attarr = [];
	// 				if (res.attdata.length > 0) {
	// 					setonleave(res.onleave);
	// 					setholiday(res.holiday);
	// 					setpresent(res.present);
	// 					setabsent(res.attdata.length - res.present);
	// 					res.attdata.forEach((element) => {
	// 						let date = format(
	// 							new Date(element.date),
	// 							"yyyy-MM-dd"
	// 						);
	// 						let data = {
	// 							date: date,
	// 							in:
	// 								element.first_in === "00:00:00"
	// 									? "Absent"
	// 									: element.first_in,
	// 							out:
	// 								element.last_out === "00:00:00"
	// 									? ""
	// 									: element.last_out,
	// 							duration:
	// 								element.first_in === "00:00:00"
	// 									? ""
	// 									: element.duration,
	// 							type:
	// 								element.first_in === "00:00:00" ||
	// 								element.first_in === "Weekof" ||
	// 								element.first_in === "Holiday"
	// 									? ""
	// 									: "Full Day",
	// 							status:
	// 								element.first_in === "00:00:00" ||
	// 								element.first_in === "Weekof" ||
	// 								element.first_in === "Holiday"
	// 									? 0
	// 									: 1,
	// 							action: "More Details",
	// 						};

	// 						attarr.push(data);
	// 					});
	// 				}

	// 				setattdata(attarr);
	// 			} else {
	// 				setattdata([
	// 					{
	// 						date: "No data Available",
	// 						in: "",
	// 						out: "",
	// 						duration: "",
	// 						type: "",
	// 						status: "",
	// 						action: "",
	// 					},
	// 				]);
	// 			}
	// 		})
	// 		.catch((err) => console.log(err));

	// 	setLoading(false);
	// };

	// get all user value
	const getSummary = async (username, date, users = []) => {
		if (
			JSON.parse(localStorage.getItem("isAuth")).type == "0" ||
			JSON.parse(localStorage.getItem("isAuth")).type == "1"
		) {
			// console.log(users);
			if (username != "" && users && users.length > 0) {
				let res = users.filter((el) => {
					return Number(username) === Number(el.username);
				});
				// console.log(res,'res');
				setselectedval({
					value: username,
					uuid: res[0].uuid,
					label: res[0].name,
					active: "🟢",
				});
			}
		}
		setorgval({
			value: selectedorg,
			label: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
				.name,
		});
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-Summary/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				date,
				username,
				company_uuid: JSON.parse(localStorage.getItem("isAuth"))
					.companydata[0].uuid,
				master: JSON.parse(localStorage.getItem("isAuth")).master,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setusername(username);
					setSummary(res.summary);
					setWeekSummary(res.weeksummary);

					setWeekData(res.weeklydata);
				} else {
					setSummary([]);
					setWeekSummary([]);
					setWeekData([]);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};
	const filterByReference = (arr1, arr2) => {
		let res = [];
		res = arr1.filter((el) => {
			return !arr2.find((element) => {
				return Number(element.username) === Number(el.username);
			});
		});
		return res;
	};

	const filterByReference2 = (arr1, arr2) => {
		let res = [];
		res = arr1.filter((el) => {
			return arr2.find((element) => {
				return Number(element.username) === Number(el.username);
			});
		});
		return res;
	};
	let filterarr = filterByReference(userdatalist, alluser);
	let filterarr2 = filterByReference2(userdatalist, alluser);

	if (filterarr2 && filterarr2.length > 0) {
		for (let i = 0; i < filterarr2.length; i++) {
			filterarr2[i].active2 = true;
		}
	}

	let finalarr3 = filterarr2.concat(filterarr);
	let data = [{ value: "", label: "Select All", active: "" }];
	for (let i = 0; i < finalarr3.length; i++) {
		data.push({
			value: finalarr3[i].username,
			label: finalarr3[i].name,
			active: finalarr3[i].active2 ? "🟢" : "🔴",
			uuid: finalarr3[i].uuid,
		});
	}

	const handleuser = (e) => {
		setselectedval(e);

		setusername(e.value);
		getData({
			year: selectedmonth?.year,
			month: selectedmonth?.month,
			uuid: e.uuid,
		});
	};
	console.log(tableData);

	const getUserData = async (org = "") => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-getAllUsers/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 1,
				// uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
				uuid: org !== "" ? org : selectedorg,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				department: JSON.parse(localStorage.getItem("isAuth"))
					.department,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setuserdata(res.data);
					// console.log(res.data);
					setalluser(res.alluser);
					if (res.alluser.length > 0 && res.data.length > 0) {
						setusername(res.alluser[0]["username"]);
						getSummary(res.alluser[0]["username"], date, res.data);
					} else {
						let userdata = JSON.parse(
							localStorage.getItem("isAuth")
						);
						getSummary(userdata.username, date, res.data);
					}
				}
				setLoading(false);
			})
			.catch((err) => console.log(err));

		loadingContext.done();
	};

    useEffect(() => {
      getUserData();
    }, []);

	const handleOnDismiss = () => {
		setisVisible(false);
	};

	const handleOnChange = (year, month) => {
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		let monthname = monthNames[month - 1];
		localStorage.setItem(
			"monthyear",
			JSON.stringify({ year, monthname, month: month })
		);
		setisVisible(false);
		setmonthYear({ year, month: monthname });
		setselectedmonth({ year, month: Number(monthname) });
		getData({ year, month, uuid: selectedval.uuid });
	};

	const showMonthPicker = (e) => {
		setisVisible(true);
		e.preventDefault();
	};

	const getMonthValue = () => {
		let value = JSON.parse(localStorage.getItem("monthyear"));
		const month = value
			? value.monthname
			: monthYear && monthYear.month
			? monthYear.month
			: 0;
		const year = value
			? value.year
			: monthYear && monthYear.year
			? monthYear.year
			: 0;

		let date = new Date();
		let curryear = date.getFullYear();

		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		return month && year
			? `${month}-${year}`
			: monthNames[currmonth - 1] + "-" + curryear;
	};
	return (
		<>
			<div class="intro-y box lg:mt-5 ">
				<div className="" style={{ justifyContent: "space-between" }}>
					<div className="flex items-center border-b border-slate-200/60 dark:border-darkmode-400">
						<h2 className="font-medium text-base p-2 mr-auto">
							After Office Hours
						</h2>
					</div>
				</div>
				<div className="intro-y box p-5">
					<div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
						<div className="flex mt-5 sm:mt-0">
							<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
								<input
									type="text"
									style={{ fontSize: "12px" }}
									onFocus={(e) => showMonthPicker(e)}
									value={getMonthValue()}
									className="form-control calenderdate1"
									id="basic-url"
									aria-describedby="basic-addon3"
								/>
								<div className="input-group-append calendaricon">
									<FaCalendar
										style={{ fontSize: "12px" }}
										onClick={(e) => showMonthPicker(e)}
									/>
								</div>
							</div>
							{loading ? (
								<div className="text-center m-5 p-5">
									<HashLoader
										color="#5755d9"
										size={30}
										style={{
											position: "absolute",
											right: "50%",
										}}
									/>
								</div>
							) : (
								<ReactMonthPicker
									show={isVisible}
									lang={[
										"Jan",
										"Feb",
										"Mar",
										"Apr",
										"May",
										"Jun",
										"Jul",
										"Aug",
										"Sep",
										"Oct",
										"Nov",
										"Dec",
									]}
									value={selectedmonth}
									onChange={handleOnChange}
									onDismiss={handleOnDismiss}
								/>
							)}
						</div>
						<div>
							{!loading && isAuth.role === 0 && (
								<Select
									defaultValue={data[0]}
									value={selectedval}
									className="basic-single mr-2"
									onChange={(e) => {
										handleuser(e);
									}}
									options={data}
									getOptionLabel={(option) =>
										` ${option.active}   ${option.label}`
									}
									styles={{
										option: (base) => ({
											...base,

											height: "100%",
											display: "flex",
										}),
									}}
								/>
							)}
						</div>
					</div>
					<div className="overflow-x-auto scrollbar-hidden">
						{tableData && tableData.length === 0 ? (
							<div className="text-center mt-5">
								No data available for this month
							</div>
						) : (
							<ReactTabulator
								columns={columns}
								data={tableData}
								options={options}
								className="mt-5 table-report table-report--tabulator"
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default AfterOfficeHours;
