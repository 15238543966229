import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { FaCalendar, FaFileCsv } from "react-icons/fa";
import { format } from "date-fns";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { CSVLink } from "react-csv";
import HashLoader from "react-spinners/HashLoader";
import { useLoadingContext } from "react-router-loading";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import Select from "react-select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";

const Summary = () => {
  let today = new Date();
  let month = [];
  month[0] = "1";
  month[1] = "2";
  month[2] = "3";
  month[3] = "4";
  month[4] = "5";
  month[5] = "6";
  month[6] = "7";
  month[7] = "8";
  month[8] = "9";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";
  const loadingContext = useLoadingContext();
  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const [totalPendingTime, setTotalPendingTime] = useState(null);
  // const username = "";
  const [summary, setSummary] = useState([]);

  const [csvData, setcsvData] = useState([]);
  const [currdate] = useState(new Date());
  const [userdatalist, setuserdata] = useState([]);
  const [alluser, setalluser] = useState(null);
  const [username, setusername] = useState();
  const [orgval, setorgval] = React.useState([]);
  const [weeksummary, setWeekSummary] = useState([]);
  const [weekdata, setWeekData] = useState([]);
  const [monthYear, setmonthYear] = useState({});
  const [date, setdate] = useState(new Date());
  const [data, setData] = useState([
    { value: "", label: "Select All", active: "", uuid: "" },
  ]);
  const [selectedorg, setselectedorg] = React.useState(
    JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
  );
  const user_data = JSON.parse(localStorage.getItem("isAuth"));
  const userUuid = user_data?.uuid
  const userType = user_data?.type
  const comp_uuid = user_data.companydata[0].uuid;
  const [selectedval, setselectedval] = useState(userType !== 0 ? {uuid : userUuid} : "" );
  const [dept, setdept] = useState(null);
  const [departmentData, setDepartmentData] = useState({
		name: "",
		department: 1,
		hod: 2,
		contactno: "",
		organization: comp_uuid,
		email: "",
		password: "",
		status: true,
		uuid: ``,
	});
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  console.log(selectedval,'selectedval')
  let monthNumber = month[today.getMonth()];
  const [newColumns, setNewColumns] = useState([
    { title: "Total Working Days", field: "workingDays", width: 140 },
    {
      title: "Total Sandwich Leave Days",
      field: "sandwichLeaveDays",
      width: 180,
    },
    { title: "Present Days", field: "presentDays", width: 140 },
    { title: "Month Leave", field: "monthLeave", width: 140 },
    { title: "Non Working Days", field: "nonWorkingDays", width: 180 },
    { title: "Total Month Days", field: "totalMonthDays", width: 160 },
    { title: "Is Eligible for PL", field: "isEligiblePL", width: 180 },
  ]);
  const [newTableData, setNewTableData] = useState([]);

  const currmonth = monthNumber;
  const curryear = today.getFullYear();
  const [value, onChange] = useState([
    new Date(currdate.getFullYear(), currdate.getMonth(), 1),
    new Date(),
  ]);
  const [columns, setcolumn] = useState([]);
  const [selectedmonth, setselectedmonth] = useState({
    year: curryear,
    month: Number(currmonth),
  });

  const getdepartments = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== "00:00:00") {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAllDepartments/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setdept(res.data);
          // getUserData();
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleuser = (e) => {
    setselectedval(e);

    setusername(e.value);
    // getData({
    //   year: selectedmonth?.year,
    //   month: selectedmonth?.month,
    //   uuid: e.uuid,
    // });
  };

  const getUserData = async (org = "") => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== "00:00:00") {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");

    await fetch(BASEURL + `admin-getAllUsers/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        type: 1,
        uuid: org !== "" ? org : selectedorg,
        usertype: JSON.parse(localStorage.getItem("isAuth")).type,
        department: JSON.parse(localStorage.getItem("isAuth")).department,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setuserdata(res.data);
          setalluser(res.alluser);

          // Update `data` with user options for the Select component
          const userOptions = res.data.map((user) => ({
            value: user.username,
            label: user.name,
            active: user.active ? "🟢" : "🔴",
            uuid: user.uuid,
          }));
          userOptions.unshift({ value: "", label: "Select All", active: "" });
          setData(userOptions); // Corrected to setData

          if (res.alluser.length > 0 && res.data.length > 0) {
            setusername(res.alluser[0]["username"]);
          } else {
          }
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));

    loadingContext.done();
  };

  useEffect(() => {
    getdepartments();
    getUserData();
  }, []);

  // useEffect(() => {
  //   getData(
  //     username,
  //     format(new Date(value[0]), "yyyy-MM-dd"),
  //     format(new Date(value[1]), "yyyy-MM-dd")
  //   );
  // }, []);


  const options = {
    pagination: "local",
    paginationSize: 10,
  };

  function DetailVal(props) {
    const rowData = props.cell._cell.row.data;

    if (rowData.employee !== "") {
      return (
        <a
          href={"/employees/" + rowData.uuid}
          rel="noreferrer"
          target="_blank"
          alt=""
        >
          <u>{rowData.name}</u>
        </a>
      );
    } else {
      return "No data Available";
    }
  }

  // const getData = async (username, date, ldate) => {
  //   setLoading(true);
  //   const myHeaders = new Headers();
  //   let userdata = JSON.parse(localStorage.getItem("isAuth"));
  //   if (userdata != null) {
  //     let token = userdata.usertoken;
  //     myHeaders.append("Authorization", token);
  //   }
  //   myHeaders.append("Content-Type", "application/json");
  //   await fetch(BASEURL + `get-monthwise-attreport`, {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: JSON.stringify({
  //       username,
  //       date,
  //       ldate,
  //       uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((res) => {
  //       let columns = [
  //         {
  //           title: "Date",
  //           field: "date",
  //           formatter: reactFormatter(<DetailVal />),
  //           width: 120,
  //         },
  //         { title: "Shift Start time", field: "username", width: 120 },
  //         { title: "Shift In time", field: "username", width: 120 },
  //         { title: "Late Coming", field: "username", width: 120 },
  //         { title: "Lunch Break Hrs", field: "username", width: 120 },
  //         { title: "Other Break Hrs", field: "username", width: 120 },
  //         { title: "Door Break Count", field: "username", width: 120 },
  //         { title: "Door Break Hrs", field: "username", width: 120 },
  //         { title: "Shift End Time", field: "username", width: 120 },
  //         { title: "Shift Out Time", field: "username", width: 120 },
  //         { title: "Shift Pending Hrs", field: "username", width: 120 },
  //         { title: "Total Work Hrs", field: "username", width: 120 },
  //         { title: "1st Half Att", field: "username", width: 120 },
  //         { title: "2nd Half Att", field: "username", width: 120 },
  //       ];
  //       columns.push(
  //         { title: "Present", field: "present", width: 140 },
  //         { title: "Absent", field: "absent", width: 140 },
  //         { title: "Leaves", field: "leaves", width: 140 },
  //         { title: "Halfday", field: "halfdayleaves", width: 140 },
  //         { title: "Holidays", field: "holidays", width: 140 }
  //       );
  //       if (res.status === 201) {
  //         // setattdata(res.data);

  //         res.datearr.forEach((element) => {
  //           columns.push({
  //             title: format(new Date(element), "dd E"),
  //             field: element,
  //             width: 140,
  //           });
  //         });

  //         setcsvData([...res.reparr]);
  //       } else {
  //         // setattdata([
  //         //   {
  //         //     name: "No data Available",
  //         //   },
  //         // ]);
  //       }
  //       setcolumn(columns);
  //     })
  //     .catch((err) => console.log(err));

  //   setLoading(false);
  //   loadingContext.done();
  // };

  // const handledate = (e) => {
  //   onChange(e);
  //   const savedata = getData(
  //     username,
  //     format(new Date(e[0]), "yyyy-MM-dd"),
  //     format(new Date(e[1]), "yyyy-MM-dd")
  //   );
  //   localStorage.setItem("checkdate", savedata);
  // };

  const handlechange = (e) => {
    let value = e.target.value;

    if (e.target.name === "status") {
      value = e.target.checked;
    }
    setDepartmentData(value);
    // console.log(data);
  };

  const getMonthWiseData = async () => {
    if (!selectedDate || !(selectedDate instanceof Date)) {
      console.error("Invalid date selected");
      return;
    }

    const selectedMonth = selectedDate.getMonth() + 1;
    const selectedYear = selectedDate.getFullYear();

    if (!selectedval?.uuid) {
      console.error("UUID is missing");
      return;
    }

    if (!selectedorg) {
      console.error("Company UUID is missing");
      return;
    }

    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch(
        `${BASEURL}get-monthwise-attendance/${selectedval.uuid}`,
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            month: selectedMonth,
            company_uuid: selectedorg,
            year: selectedYear,
          }),
        }
      );

      if (!response.ok) {
        const errorMessage = `Error: ${response.status} - ${response.statusText}`;
        throw new Error(errorMessage);
      }

      const result = await response.json();

      if (result?.remaining_pending_time) {
        setTotalPendingTime(result.remaining_pending_time);
      }

      if (result.attendance) {
        setattdata(result.attendance);
      } else {
        setattdata([]);
      }
    } catch (err) {
      console.error("Error fetching month-wise data:", err.message || err);
    }
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 2xl:col-span-12">
          <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 className="text-lg font-medium mr-auto">
              Monthly Attendance Report
            </h2>
            <div className="flex items-center text-primary">
              {
                userType !== 2 &&
                <>
                    <select
                className="form-select"
                name="department"
                onChange={handlechange}
                value={departmentData}
              >
                <option key={-1} value="0">
                  All department
                </option>
                {dept &&
                  dept.length > 0 &&
                  dept.map((item, i) => (
                    <option key={i} value={item.department_id}>
                      {item.deptname}
                    </option>
                  ))}
              </select>

              <div className="mx-2">
                <Select
                  defaultValue={data[0]}
                  value={selectedval}
                  className="basic-single mr-0"
                  onChange={handleuser}
                  options={data}
                  getOptionLabel={(option) =>
                    ` ${option.active}   ${option.label}`
                  }
                  styles={{
                    option: (base) => ({
                      ...base,
                      height: "100%",
                      display: "flex",
                    }),
                  }}
                />
              </div>
                  </>
              }
            

              <div className="mx-1 w-full max-w-[250px]">
                {" "}
                {/* Adjusted width here */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    // label="Month and Year"
                    views={["month", "year"]}
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <button
                className="bg-primary p-2 text-white "
                onClick={getMonthWiseData}
                style={{ borderRadius: "5px" }}
              >
                Get
              </button>

              <CSVLink
                filename={"All-Employees-Monthly-Attendance.csv"}
                className="ml-3 mr-3"
                data={csvData}
              >
                <FaFileCsv
                  style={{ color: "rgb(30 64 175)", fontSize: "25px" }}
                />
              </CSVLink>
            </div>
          </div>
          {/* {loading ? (
            <div className="mt-5" style={{ display: "inline-flex" }}>
              <HashLoader color="#5755d9" size={30} />
            </div>
          ) : ( */}
          <div className="intro-y overflow-x-auto mt-5">
            {attdata && attdata.length > 0 ? (
              <table className="table-auto w-full text-center border-collapse border border-gray-300">
                <thead>
                  <tr className="monthly-report-header text-white">
                    <th
                      className="border border-gray-300 p-1 py-2"
                      style={{ width: "100px" }}
                    >
                      Date
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Shift Start Time
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Shift In Time
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Late Coming
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Lunch Break Hrs
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Other Break Hrs
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Shift End Time
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Shift Out Time
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Shift Pending Hrs
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Total Work Hrs
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      1st Half Att
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      2nd Half Att
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {attdata?.map((row, index) => (
                    <tr
                      key={index}
                      className={`bg-white ${
                        row?.is_weekoff && "weekOffColor"
                      } ${
                        row?.half_day_type === "First Half" ||
                        row?.half_day_type === "Second Half"
                          ? ""
                          : row?.on_leave
                          ? "bg-warning"
                          : ""
                      }`}
                    >
                      <td className="border border-gray-300 p-1 ">
                        {row?.date
                          ? moment(row.date).format("YYYY-MM-DD")
                          : "-"}
                      </td>
                      <td
                        className="border border-gray-300 p-1 "
                        style={{ backgroundColor: "#d7d5d5" }}
                      >
                        {row?.shift?.shift_start_time
                          ? row?.shift?.shift_start_time
                          : "-"}
                      </td>
                      <td className="border border-gray-300 p-1 ">
                        {row?.start_time ? row?.start_time : "-"}
                      </td>
                      <td
                        className={`border border-gray-300 p-1 ${
                          row?.late_shift_in && "danger"
                        }`}
                      >
                        {row?.late_coming ? row?.late_coming : "-"}
                      </td>
                      <td
                        className={`border border-gray-300 p-1 ${
                          row?.is_break_longer && "danger"
                        }`}
                      >
                        {row?.lunch_break_duration
                          ? row?.lunch_break_duration
                          : "-"}
                      </td>
                      <td className="border border-gray-300 p-1 ">
                        {row?.other_break_duration
                          ? row?.other_break_duration
                          : "-"}
                      </td>
                      <td
                        className="border border-gray-300 p-1 "
                        style={{ backgroundColor: "#d7d5d5" }}
                      >
                        {row?.shift?.shift_end_time
                          ? row?.shift?.shift_end_time
                          : "-"}
                      </td>{" "}
                      <td className="border border-gray-300 p-1 ">
                        {row?.end_time ? row?.end_time : "-"}
                      </td>
                      <td
                        className={`border border-gray-300 p-1 ${
                          row?.early_shift_out && "danger"
                        }`}
                      >
                        {row?.pending_time ? row?.pending_time : "-"}
                      </td>
                      <td className="border border-gray-300 p-1 ">
                        {row?.total_working_hours
                          ? row?.total_working_hours
                          : "-"}
                      </td>
                      <td
                        className={`border border-gray-300 p-1 ${
                          row?.is_weekoff
                            ? "weekOffColor"
                            : row?.half_day_type === "First Half" &&
                              "bg-warning"
                        }`}
                      >
                        {row?.on_leave
                          ? "On Leave"
                          : !row?.end_time
                          ? "-"
                          : row?.half_day_type === "First Half"
                          ? "A"
                          : "P"}
                      </td>
                      <td
                        className={`border border-gray-300 p-1 ${
                          row?.is_weekoff
                            ? "weekOffColor"
                            : row?.half_day_type === "Second Half" &&
                              "bg-warning"
                        }`}
                      >
                        {row?.on_leave
                          ? "On Leave"
                          : !row?.end_time
                          ? "-"
                          : row?.half_day_type === "Second Half"
                          ? "A"
                          : "P"}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td className="border border-gray-300 p-1 ">-</td>
                    <td className="border border-gray-300 p-1 ">-</td>{" "}
                    <td className="border border-gray-300 p-1 ">-</td>{" "}
                    <td className="border border-gray-300 p-1 ">-</td>{" "}
                    <td className="border border-gray-300 p-1 ">-</td>{" "}
                    <td className="border border-gray-300 p-1 ">-</td>{" "}
                    <td className="border border-gray-300 p-1 ">-</td>{" "}
                    <td className="border border-gray-300 p-1 ">-</td>{" "}
                    <td className="border border-gray-300 p-1 ">
                      {totalPendingTime
                        ? `Pending Time: ${totalPendingTime}`
                        : "-"}
                    </td>{" "}
                    <td className="border border-gray-300 p-1 ">-</td>
                    <td className="border border-gray-300 p-1 ">-</td>{" "}
                    <td className="border border-gray-300 p-1 ">-</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className="text-danger text-left mt-5 mb-5">
                No Data Available
              </div>
            )}
          </div>
        </div>

        <div>
          {loading ? (
            <div className="mt-5" style={{ display: "inline-flex" }}>
              <HashLoader color="#5755d9" size={30} />
            </div>
          ) : (
            <div className="mt-5">
              <table className="table-auto w-full text-center border-collapse border border-gray-300">
                <thead>
                  <tr className="monthly-report-header text-white">
                    <th className="border border-gray-300 p-1 py-2">
                      Total Working Days
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Ttoal Sandwich Leave Days
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Present Days
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Month Leaves
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Total Month Days
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Non Working Days
                    </th>
                    <th className="border border-gray-300 p-1 py-2">
                      Is Eligible For Pl
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Summary;
