import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { BASEURL } from "../BASEURL";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { compareAsc, format } from "date-fns";

const JobAssessment = (props) => {
	const params = useParams();
	const [assdata, setassdata] = useState();
	const [jobdata, setjobdata] = useState([]);
	const [quedata, setquedata] = useState([]);
	const [quedata1, setquedata1] = useState([]);
	const [interviewername, setinterviewername] = useState("");
	const [overallassessment, setoverallassessment] = useState("");
	const [IsAssessment, setIsAssessment] = useState(false);
	const [IsAssessment1, setIsAssessment1] = useState(false);
	const [positions, setpositions] = useState([]);

	const handlechange = (evnt, index = "") => {
		const { name, value } = evnt.target;
		const rowsInput = [...quedata];
		if (rowsInput[index]["question_type"] == 1) {
			if (rowsInput[index][name] && rowsInput[index][name] != undefined) {
				rowsInput[index][name].push(value);
			} else {
				rowsInput[index][name] = [value];
			}
		} else {
			rowsInput[index][name] = value;
		}
		// console.log(rowsInput);
		setquedata([...rowsInput]);
	};

	useEffect(() => {
		getPositions();
		getData();
	}, []);

	const getPositions = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getAllPositions/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				company_uuid: "abc3bab0-2b6e-4fb7-a453-4049c8157ac5",
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setpositions(res.data);
				} else {
					setpositions([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const getData = async () => {
		const formData = new FormData();
		formData.append("uuid", params.id);
		// console.log(params);
		const res = await axios.post(BASEURL + "get-jobdata", formData, {
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (res.data.status === 201) {
			if (res.data.quedata.length > 0) {
				setquedata(JSON.parse(res.data.quedata[0]["questions_data"]));
				setquedata1(JSON.parse(res.data.quedata[0]["questions_data"]));
			}
			setjobdata(res.data.data[0]);
			setassdata(res.data.assdata);
		}
	};

	let [loading, setLoading] = useState(false);

	// const setIsAssessment = async (e) => {

	// }

	const savedata = async (e) => {
		e.preventDefault();
		// console.log(params); return
		if (interviewername === "" || overallassessment === "") {
			swal({
				title: "Error",
				text: "Please Enter value Required value",
				icon: "error",
				timer: 2000,
			});
			return;
		}

		const formdata = new FormData();
		formdata.append("interviewername", interviewername);
		formdata.append("overallassessment", overallassessment);
		formdata.append("formdata", JSON.stringify(quedata));
		formdata.append("uuid", params.id);

		const res = await axios.post(BASEURL + "job-assessment-add", formdata, {
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (res.data.status === 201) {
			swal({
				title: "Job assessment added successfully",
				text: res.message,
				icon: "success",
				timer: 2000,
			});
			setIsAssessment(false);
			getData();
		} else {
			swal({
				title: "Error occured! Please try again",
				text: res.message,
				icon: "error",
				timer: 2000,
			});
		}
		setLoading(false);
	};

	// console.log(quedata);

	const getassdata = (item) => {
		setinterviewername(item.interviewername);
		setoverallassessment(item.overallassessment);
		console.log(item.answer_data);
		setquedata(JSON.parse(item.answer_data));
		setIsAssessment(true);
		setIsAssessment1(true);
	};
	return (
		<>
			<div className="p-0">
				<div
					className=""
					style={{
						height: "100vh",
						overflow: "scroll",
					}}
				>
					<div className="">
						<div
							className="xl:h-auto py-lg-5 xl:py-0 my-lg-10 xl:my-0"
							style={{ overflow: "scroll" }}
						>
							<div className="">
								<div className="grid grid-cols-24 gap-12 mt-1">
									<div className="intro-y col-span-12 lg:col-span-12">
										<div className="intro-y box">
											<div className="flex flex-col sm:flex-row items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
												<h2
													className="text-base mr-auto"
													style={{
														fontSize: "15px",
														fontWeight: 500,
													}}
												>
													Job Assessment
												</h2>
												<Link to="/admin/12">
													<button
														className="btn btn-primary btn-sm mr-left"
														style={{
															fontSize: "10px",
														}}
													>
														Back to Job Application
													</button>{" "}
												</Link>
											</div>
											<div
												id="form-validation"
												className="p-5"
											>
												<div className="text-right">
													<div className="grid grid-cols-12">
														<div className="intro-y col-span-8 lg:col-span-8 text-left">
															{assdata &&
																assdata.length >
																	0 &&
																assdata.map(
																	(
																		item,
																		i
																	) => {
																		return (
																			<>
																				{item.interviewername ===
																				"Own" ? (
																					<input
																						type="button"
																						onClick={() =>
																							getassdata(
																								item
																							)
																						}
																						value={
																							"View Application"
																						}
																						className="btn btn-primary p-3 btn-sm mb-2 mr-2"
																					/>
																				) : (
																					<input
																						type="button"
																						onClick={() =>
																							getassdata(
																								item
																							)
																						}
																						value={
																							"Interview " +
																							(i +
																								1) +
																							" Done by: " +
																							item.interviewername
																						}
																						className="btn btn-primary p-3 btn-sm mb-2 mr-2"
																					/>
																				)}
																			</>
																		);
																	}
																)}
														</div>

														<div className="intro-y col-span-4 lg:col-span-4 text-right">
															<button
																className="btn btn-primary btn-sm"
																onClick={() => {
																	setIsAssessment1(
																		false
																	);
																	setIsAssessment(
																		!IsAssessment
																	);
																	setinterviewername(
																		""
																	);
																	setoverallassessment(
																		""
																	);
																	setquedata(
																		quedata1
																	);
																}}
															>
																+ Add New
																Assessment
															</button>
														</div>
													</div>
												</div>
												<br />

												{IsAssessment && (
													<div className="preview">
														<div className="flex-1 mt-6 xl:mt-0 mb-5 pb-5">
															<form
																className="pb-5 mb-5"
																onSubmit={(e) =>
																	savedata(e)
																}
															>
																<div className="grid grid-cols-12 gap-x-5 text-left">
																	<div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
																		<div>
																			<label
																				htmlFor="update-profile-form-1"
																				className="form-label"
																			>
																				Candidate
																				Full
																				Name
																			</label>
																			<input
																				id="update-profile-form-1"
																				value={
																					jobdata &&
																					jobdata.name
																				}
																				required
																				type="text"
																				name="fullname"
																				className="form-control"
																				readOnly
																			/>
																		</div>
																	</div>

																	<div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
																		<div>
																			<label
																				htmlFor="update-profile-form-1"
																				className="form-label"
																			>
																				Date
																				of
																				Interview
																			</label>
																			<input
																				id="update-profile-form-1"
																				// value={jobdata &&  format(new Date(jobdata.datetime), "yyyy-MM-dd")}
																				value={
																					jobdata &&
																					jobdata.datetime?.substring(
																						0,
																						10
																					)
																				}
																				required
																				type="text"
																				readOnly
																				name="email"
																				className="form-control"
																			/>
																		</div>
																	</div>

																	<div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
																		<label
																			htmlFor="update-profile-form-2"
																			className="form-label"
																		>
																			Interviewer
																			Name{" "}
																			<span className="text-danger">
																				(*)
																			</span>
																		</label>
																		<input
																			id="update-profile-form-1"
																			value={
																				interviewername
																			}
																			required
																			type="text"
																			name="interviewername"
																			className="form-control"
																			onChange={(
																				e
																			) =>
																				setinterviewername(
																					e
																						.target
																						.value
																				)
																			}
																		/>
																	</div>

																	<div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
																		<div>
																			<label
																				htmlFor="update-profile-form-1"
																				className="form-label"
																			>
																				Position
																				Applied
																				for
																			</label>
																			<select
																				className="form-select"
																				name="position"
																				readOnly
																				value={
																					jobdata &&
																					jobdata.position
																				}
																			>
																				<option value="">
																					Select
																				</option>
																				{positions.map(
																					(
																						item,
																						i
																					) => {
																						return (
																							<option
																								value={
																									item.uuid
																								}
																							>
																								{
																									item.name
																								}
																							</option>
																						);
																					}
																				)}
																				{/* <option value="0">Python Developer</option>
                                                <option value="1">React JS Developer</option>
                                                <option value="2">PHP Developer</option>
                                                <option value="3">HR Executive</option>
                                                <option value="4">Angular Developer</option>
                                                <option value="5">SEO Executive</option>
                                                <option value="6">Admin</option>
                                                <option value="7">Tele Caller</option>
                                                <option value="8">Accountant</option> */}
																			</select>
																		</div>
																	</div>

																	<br />
																	<div className="col-span-12 2xl:col-span-12">
																		<h5>
																			<b>
																				Skills
																				Evaluation
																				Criteria
																			</b>
																		</h5>
																		<br />

																		{quedata &&
																			quedata.length >
																				0 &&
																			quedata.map(
																				(
																					item,
																					i
																				) => {
																					return (
																						<>
																							<label htmlFor="">
																								{
																									item.question
																								}
																							</label>

																							<div
																								className="p-0 py-5"
																								style={{
																									display:
																										"flex",
																								}}
																							>
																								{item.question_type ==
																									"0" ||
																								item.question_type ==
																									"" ? (
																									item.options.map(
																										(
																											listitem,
																											ind2
																										) => {
																											{
																												/* console.log(item["answer_"+i]) */
																											}
																											return (
																												<>
																													<div className="form-check form-check-inline mr-4">
																														<input
																															checked={
																																item[
																																	"answer_" +
																																		i
																																] ==
																																ind2
																															}
																															className="form-check-input"
																															type="radio"
																															name={
																																"answer_" +
																																i
																															}
																															id="inlineRadio1"
																															value={
																																ind2
																															}
																															onChange={(
																																e
																															) =>
																																handlechange(
																																	e,
																																	i
																																)
																															}
																														/>
																														<label
																															className="form-check-label"
																															for="inlineRadio1"
																														>
																															{
																																listitem
																															}
																														</label>
																													</div>
																												</>
																											);
																										}
																									)
																								) : item.question_type ==
																								  "1" ? (
																									item.options.map(
																										(
																											listitem,
																											ind
																										) => {
																											let iscontain = false;
																											if (
																												item[
																													"answer_" +
																														i
																												] &&
																												item[
																													"answer_" +
																														i
																												]
																													.length >
																													0
																											) {
																												iscontain =
																													item[
																														"answer_" +
																															i
																													].includes(
																														String(
																															ind
																														)
																													);
																											}
																											return (
																												<>
																													<div className="form-check form-check-inline mr-4">
																														<input
																															className="form-check-input"
																															type="checkbox"
																															name={
																																"answer_" +
																																i
																															}
																															id="inlineRadio1"
																															value={
																																ind
																															}
																															checked={
																																iscontain
																															}
																															onChange={(
																																e
																															) =>
																																handlechange(
																																	e,
																																	i
																																)
																															}
																														/>
																														<label
																															className="form-check-label"
																															for="inlineRadio1"
																														>
																															{
																																listitem
																															}
																														</label>
																													</div>
																												</>
																											);
																										}
																									)
																								) : item.question_type ==
																								  "2" ? (
																									<>
																										<select
																											className="form-select"
																											aria-label="Default select example"
																											name={
																												"answer_" +
																												i
																											}
																											onChange={(
																												e
																											) =>
																												handlechange(
																													e,
																													i
																												)
																											}
																										>
																											{item.options.map(
																												(
																													listitem,
																													ind3
																												) => {
																													return (
																														<>
																															<option
																																value={
																																	ind3
																																}
																																selected={
																																	item[
																																		"answer_" +
																																			i
																																	] ==
																																	ind3
																																}
																															>
																																{
																																	listitem
																																}
																															</option>
																														</>
																													);
																												}
																											)}
																										</select>
																									</>
																								) : (
																									<textarea
																										name={
																											"answer_" +
																											i
																										}
																										className="form-control"
																										cols="80"
																										rows="2"
																										onChange={(
																											e
																										) =>
																											handlechange(
																												e,
																												i
																											)
																										}
																										value={
																											item[
																												"answer_" +
																													i
																											]
																										}
																									></textarea>
																								)}
																							</div>
																						</>
																					);
																				}
																			)}
																	</div>

																	<div className="col-span-12 2xl:col-span-12">
																		<label
																			htmlFor="update-profile-form-2"
																			className="form-label"
																		>
																			Overall
																			Assessment
																		</label>
																		<br />
																		<textarea
																			name="overallassessment"
																			className="form-control"
																			cols="80"
																			rows="5"
																			onChange={(
																				e
																			) =>
																				setoverallassessment(
																					e
																						.target
																						.value
																				)
																			}
																			value={
																				overallassessment
																			}
																		></textarea>
																	</div>
																</div>

																{loading ? (
																	<HashLoader
																		color="#5755d9"
																		size={
																			30
																		}
																		style={{
																			position:
																				"absolute",
																			left: "10%",
																			paddingTop:
																				"10%",
																		}}
																	/>
																) : (
																	<div className="text-left">
																		{IsAssessment &&
																			!IsAssessment1 && (
																				<button
																					type="submit"
																					className="btn btn-primary w-50 mt-3 mb-5"
																				>
																					Submit
																					Assessment
																				</button>
																			)}
																	</div>
																)}
															</form>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default JobAssessment;
