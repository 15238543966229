import React, { useState, useEffect } from "react";
import OrgDetails from "./OrgDetails";
import Departments from "./Departments";
import Employee from "./Employee";
import Projects from "./Projects";
import WorkingShifts from "./WorkingShifts";
import DesktopApps from "./DesktopApps";
import Notifications from "./Notifications";
import Holidays from "./Holidays";
import Alert from "./Alert";
import LeaveType from "./LeaveType";
import AccountSettings from "../AccountSettings";
import Masteradmin from "../Masteradmin";
import JobVacancies from "../admin/JobVacancies";
import JobApplication from "../admin/JobApplications";
import JobQuestionaries from "../admin/jobQuestionaries";
import CallDetails from "../admin/CallDetailsList";
import ProjectHrs from "../admin/ProjectHrs";
import ProjectToClient from "../admin/ProjectToClient";
import Clients from "../admin/Clients";
import Sms from "../admin/Sms";
import { Link, useParams, useNavigate } from "react-router-dom";
import SecurityEntries from "../admin/SecurityEntries";
import Activity from "./Activity";
import EvolutionPoints from "./EvolutionPoints";
import PunchTicket from "./PunchTicket";

const Profile = () => {
	let [tabtype, settabtype] = useState(
		JSON.parse(localStorage.getItem("isAuth")).master == 1 ? 3 : 0
	);
	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);
	const [isAuth] = useState(JSON.parse(localStorage.getItem("isAuth")));
	const params = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (isAuth.role === 1 || isAuth.username === "1150") {
			settabtype(12);
		}
		if (isAuth.role === 2 && isAuth.username !== "1150") {
			navigate("/");
		}

		if (params.type) {
			settabtype(+params.type);
		}
	}, []);

	useEffect(() => {
		if (params.type) {
			settabtype(+params.type);
		}
	}, [params.type]);

	return (
		<>
			<div className="grid grid-cols-12 gap-10">
				<div className="scrollmenu tableTabs col-span-12 w-full overflow-y-scroll overflow-x-auto">
					{tabtype === 0 ? <OrgDetails /> : null}
					{tabtype === 1 ? <Departments /> : null}
					{/* {tabtype === 2 ? <Designations /> : null} */}
					{tabtype === 3 ? <Employee /> : null}
					{tabtype === 4 ? <WorkingShifts /> : null}
					{tabtype === 5 ? <DesktopApps /> : null}
					{tabtype === 6 ? <Notifications /> : null}
					{tabtype === 7 ? <Alert /> : null}
					{tabtype === 8 ? <LeaveType /> : null}
					{tabtype === 9 ? <Holidays /> : null}
					{tabtype === 10 ? <AccountSettings /> : null}
					{tabtype === 11 ? <Masteradmin /> : null}
					{tabtype === 12 ? <JobApplication /> : null}
					{tabtype === 13 ? <JobVacancies /> : null}
					{tabtype === 14 ? <JobQuestionaries /> : null}
					{tabtype === 15 ? <CallDetails /> : null}
					{tabtype === 16 ? <Sms /> : null}
					{tabtype === 17 ? <SecurityEntries /> : null}
					{tabtype === 18 ? <ProjectHrs /> : null}
					{tabtype === 19 ? <ProjectToClient /> : null}
					{tabtype === 20 ? <Clients /> : null}
					{tabtype === 21 ? <Projects /> : null}
					{tabtype === 22 ? <Activity /> : null}
					{tabtype === 23 ? <EvolutionPoints /> : null}
					{tabtype === 24 ? <PunchTicket /> : null}
				</div>
			</div>
		</>
	);
};

export default Profile;
