import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { useParams } from "react-router-dom";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import "react-month-picker/css/month-picker.css";
import { HashLoader } from "react-spinners";

const ManagerDetails = () => {
  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);

  const params = useParams();

  useEffect(() => {
    getData("");
  }, []);

  const columns = [
    { title: "Name", field: "name" },
    { title: "Username", field: "username" },
    { title: "Email", field: "email" },
    { title: "Contact No", field: "contact_no" },
    { title: "Address", field: "address" },
    { title: "DOB", field: "dob" },
    { title: "Blood Group", field: "blood_group" },

    { title: "Date Of Joining", field: "date_of_joining" },
  ];

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async (date) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
   
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-managerDetails/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        uuid: params.uuid,
        usertype:JSON.parse(localStorage.getItem("isAuth")).type,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setattdata(res.data);
        } else {
          setattdata([
            {
              name: "No data Available",
              username: "",
              email: "",
              contact_no: "",
              address: "",
              dob: "",
              blood_group: "",
              height: "",
              weight: "",
              date_of_joining: "",
            },
          ]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center">
        <h2 className="text-lg font-medium mr-auto">Manager Details</h2>
      </div>

      <div className="intro-y box mt-5">
        <div className="overflow-x-auto scrollbar-hidden">
          {loading ? (
            <div className="text-center pt-0 p-0">
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            </div>
          ) : (
            <ReactTabulator
              columns={columns}
              data={attdata}
              options={options}
              className="mt-5 table-report table-report--tabulator"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ManagerDetails;
