import React, { useState, useEffect } from "react";
import { SITEURL, BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Masteradmin from "../Masteradmin";
import { FaSignInAlt, FaTrashAlt, FaEdit } from "react-icons/fa";

const Departments = () => {
	const navigate = useNavigate();
	const [userdata, setuserdata] = useState([]);
	const [dept, setdept] = useState(null);
	const [bkpdata, setbkpdata] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentItems, setCurrentItems] = useState(null);
	const [items, setitems] = useState([]);
	const [errors, setErrors] = useState({});

	const [add, setadd] = useState(2);
	let [tabtype, settabtype] = useState(false);

	const [getdata, setgetdata] = useState([]);
	const [data, setdata] = useState({
		name: "",
		department: 1,
		hod: 2,
		contactno: "",
		organization: "",
		email: "",
		password: "",
		status: true,
		uuid: ``,
	});

	const [isadd, setisadd] = useState(false);
	let [loading, setLoading] = useState(false);
	let [msg, setmsg] = useState({
		type: "",
		msg: "",
	});

	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

	useEffect(() => {
		setadd(JSON.parse(localStorage.getItem("isAuth")).type);
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
		getUserData();
		getdepartments();
		getData();
	}, []);

	const handleValidation = () => {
		const newErrors = {};

		if (!data.dob) {
			newErrors.dob = "Please enter a valid date of birth.";
		}
		if (!data.date_of_joining) {
			newErrors.date_of_joining = "Please enter a valid joining date.";
		}

		setErrors(newErrors);
	};

	useEffect(() => {
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
	}, [itemOffset]);

	const getdepartments = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getAllDepartments/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setdept(res.data);
				}
				setLoading(false);
			})
			.catch((err) => console.log(err));
	};

	const getData = async () => {
		settabtype(false);
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		let data = await fetch(BASEURL + `addorganization`, {
			method: "GET",
			headers: myHeaders,
		});
		let data2 = await data.json();
		setgetdata(data2.data);
		setLoading(false);
	};

	const renderOrgName = (uuid) => {
		if (uuid === "94541385-dd89-4343-8a1d-14989a8546be") {
			return "Rayvatorg";
		} else if (uuid === "abc3bab0-2b6e-4fb7-a453-4049c8157ac5") {
			return "Jenya";
		}
		return "Unknown Organization";
	};
	const handlePageClick = (event) => {
		const newOffset = (event.selected * 10) % items.length;

		setItemOffset(newOffset);
	};

	const getUserData = async (e = "") => {
		let company_uuid;

		// Get the selected UUID from the event or default to localStorage
		if (e && e.target && e.target.value) {
			company_uuid = e.target.value;
		} else {
			// Default to the UUID from localStorage if no value is selected
			const storedData = JSON.parse(localStorage.getItem("isAuth"));
			company_uuid = storedData.companydata[0].uuid;
		}

		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}

		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-Employee/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				listtype: 1,
				role: 1,
				isactive: 1,
				uuids: [company_uuid], // Pass the selected or default company UUID
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					let arr = [];
					let arr1 = [];
					for (const key in res.data) {
						arr.push(key);
						if (key < 9) {
							arr1.push(key);
						}
					}
					setitems(arr);
					setCurrentItems(arr1);
					setPageCount(Math.ceil(arr.length / 10));
					setuserdata(res.data);
					console.log("Response", res.data);
					setbkpdata(res.data);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	// const getUserData = async (e = "") => {
	// 	let isactive = 1;
	// 	if (e !== "") {
	// 		isactive = e.target.value;
	// 	}

	// 	setLoading(true);
	// 	const myHeaders = new Headers();
	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));

	// 	if (userdata !== "00:00:00") {
	// 		let token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	// console.log(master);
	// 	myHeaders.append("Content-Type", "application/json");
	// 	await fetch(BASEURL + `admin-Employee/`, {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: JSON.stringify({
	// 			listtype: 1,
	// 			role: 1,
	// 			// master,
	// 			isactive,
	// 			// uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
	// 			// 	.uuid,
	// 			uuids: JSON.parse(
	// 				localStorage.getItem("isAuth")
	// 			).companydata.map((company) => company.uuid),
	// 		}),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((res) => {
	// 			if (res.status === 201) {
	// 				let arr = [];
	// 				let arr1 = [];
	// 				for (const key in res.data) {
	// 					arr.push(key);
	// 					if (key < 9) {
	// 						arr1.push(key);
	// 					}
	// 				}
	// 				setitems(arr);
	// 				setCurrentItems(arr1);
	// 				setPageCount(Math.ceil(arr.length / 10));
	// 				setuserdata(res.data);
	// 				console.log("response coming to the uuid", res.data.uuid);
	// 				console.log("Response", res.data);
	// 				setbkpdata(res.data);
	// 			}
	// 		})
	// 		.catch((err) => console.log(err));

	// 	setLoading(false);
	// };

	const handleChange = (e) => {
		const { name, value, checked, type } = e.target;
		setdata({
			...data,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	const handlechange = (e) => {
		let value = e.target.value;
		if (e.target.name === "status") {
			value = e.target.checked;
		}
		setdata({
			...data,
			[e.target.name]: value,
		});
		// console.log(data);
	};

	const savedata = async (elm) => {
		if (
			data.name === "" ||
			// data.department === "" ||
			// data.hod === "" ||
			data.contactno === "" ||
			data.email === "" ||
			data.organization === ""
		) {
			swal({
				title: "Error",
				text: "Please Enter value Required value",
				icon: "error",
				timer: 2000,
			});
			return;
		}
		// console.log(data); return
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `add-user/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				company_uuid: userdata.companydata[0].uuid,
				company: userdata,
				data,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					userdata.companydata[0].name = data.name;
					userdata.companydata[0].support_access = data.supportaccess;
					localStorage.setItem("isAuth", JSON.stringify(userdata));
					setmsg({
						type: res.type,
						msg: res.message,
					});
					getUserData();
					setdata({
						name: "",
						department: 1,
						hod: 2,
						password: "",
						contactno: "",
						email: "",
						status: true,
					});

					swal({
						title: "Success",
						text: res.message,
						icon: "success",
						timer: 2000,
					});
					setTimeout(() => {
						setmsg({
							type: "",
							msg: "",
						});
						setisadd(!isadd);
					}, 1000);
				} else {
					swal({
						title: "Error",
						text: res.message,
						icon: "error",
						timer: 2000,
					});
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};

	const editdata = (data) => {
		setisadd(true);
		let status;
		if (data.active === 1) {
			status = true;
		} else {
			status = false;
		}
		// console.log(data.companyuuid);
		setdata({
			id: data.user_id,
			name: data.name,
			department: data.department,
			organization: data.companyuuid,
			contactno: data.contact_no,
			email: data.email,
			hod: data.type,
			// password: data.password,
			status: status,
		});
	};

	// const addorg = () => {
	// 	settabtype(!tabtype);
	// 	setisadd(false);
	// };
	const adddata = (data) => {
		// console.log(11);
		settabtype(false);
		setisadd(!isadd);
		setdata({
			name: "",
			department: 1,
			hod: 2,
			password: "1",
			contactno: "",
			email: "",
			status: true,
		});
	};
	const filterdata = (event) => {
		const inputValue = event.target.value.toLowerCase();
		if (inputValue === "") {
			loading ? <HashLoader /> : setuserdata(bkpdata);
			let arr = [];
			for (const key in bkpdata) {
				arr.push(key);
			}
			setitems(arr);
			setCurrentItems(arr);
			setPageCount(Math.ceil(arr.length / 10));
		} else {
			var matches = bkpdata.filter(function (s) {
				return s.name.toLowerCase().includes(`${inputValue}`);
			});

			let arr = [];
			for (const key in matches) {
				arr.push(key);
			}
			setitems(arr);

			const newOffset = (matches.length * 10) % matches.length;
			setItemOffset(newOffset);
			setuserdata(matches);
			const endOffset = itemOffset + 10;
			setCurrentItems(arr.slice(itemOffset, endOffset));
			setPageCount(Math.ceil(matches.length / 10));
		}
	};

	const signindata = async (data) => {
		swal({
			title: "Sign In as Admin",
			text: `Are you sure you want to Sign In as Admin?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				// console.log(data.companyuuid); return
				// window.open(SITEURL, '_blank', 'incognito=yes');
				let authdata = JSON.parse(localStorage.getItem("isAuth"));
				// console.log(authdata); return

				const myHeaders = new Headers();
				let userdata = JSON.parse(localStorage.getItem("isAuth"));
				if (userdata != null) {
					let token = userdata.usertoken;
					myHeaders.append("Authorization", token);
				}

				myHeaders.append("Content-Type", "application/json");
				fetch(BASEURL + `get-companydata/${data.company_uuid}`, {
					method: "GET",
					headers: myHeaders,
				})
					.then((response) => response.json())
					.then((res) => {
						if (res.status === 201) {
							let obj = {
								status: 201,
								message: "Signin successful",
								usertoken: authdata.usertoken,
								id: data.user_id,
								uuid: data.uuid,
								username: data.username,
								name: data.name,
								avatar: data.avatar,
								role: data.type,
								timezone: "",
								dept: data.deptname,
								department: data.department,
								companydata: res.data,
								master: data.master,
								issigninbyadmin: 1,
								type: data.type,
							};
							// return
							localStorage.setItem("isAuth", JSON.stringify(obj));
							window.open(SITEURL, "_self");
							// 'incognito=yes'
						} else {
							swal({
								title: "Error",
								text: res.message,
								icon: "error",
								timer: 2000,
							});
						}
					})
					.catch((err) => console.log(err));
				setLoading(false);
			}
		});
	};

	const deletedata = (id) => {
		swal({
			title: "Delete",
			text: `Please confirm if you want to Delete Employee?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				setLoading(true);
				const myHeaders = new Headers();
				let userdata = JSON.parse(localStorage.getItem("isAuth"));
				if (userdata != null) {
					let token = userdata.usertoken;
					myHeaders.append("Authorization", token);
				}
				myHeaders.append("Content-Type", "application/json");
				fetch(BASEURL + `delete-user/${id}`, {
					method: "DELETE",
					headers: myHeaders,
				})
					.then((response) => response.json())
					.then((res) => {
						getUserData();
						swal({
							title: "Success",
							text: res.msg,
							icon: "success",
							timer: 2000,
						});
					})
					.catch((err) => console.log(err));
				setLoading(false);
			}
		});
	};

	// const selectoption = (e) => {
	// 	// console.log(e.target.value);
	// 	// let data = getdata[e.target.value];
	// 	// setdata({
	// 	//   name: data.organization_name,
	// 	//   email: data.email,
	// 	//   mobile: data.mobile_no,
	// 	//   user: data.user_count,
	// 	//   expiry: data.expiry_date,
	// 	//   id: data.id,
	// 	// });
	// 	// setupdate({ ...update, msg: "Update detail" });
	// };

	return (
		<div className="intro-y box lg:mt-5">
			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
				<h2 className="font-medium text-base mr-auto">All Employee </h2>
				<div className="col-span-12 md:col-span-6 mt-3 mb-3">
					{master === 1 ? (
						<div className="flex mt-15 sm:mt-0">
							<select
								style={{
									fontSize: "14px",
									width: "100%",
									padding: "8px",
									borderRadius: "8px",
									margin: "10px",
								}}
								name="organization"
								required
								className="form-select"
								onChange={getUserData}
							>
								<option value="" key={-1}>
									Select Organization
								</option>
								{getdata.length > 0 &&
									getdata.map((ele, i) => (
										<option value={ele.uuid} key={i}>
											{ele.name}
										</option>
									))}
							</select>
						</div>
					) : null}
				</div>
				{add === 0 ? (
					// <button
					// 	type="button"
					// 	onClick={() => adddata(0)}
					// 	className="btn btn-primary w-50"
					// >
					// 	Add Employee
					// </button>
					<button
						type="button"
						onClick={() => navigate("/employees/Addemployee")}
						className="btn btn-primary w-50"
					>
						Add Employee
					</button>
				) : // <button
				// 	type="button"
				// 	onClick={() =>
				// 		window.open(
				// 			"/employees/Addemployee",
				// 			"_blank",
				// 			"noopener,noreferrer"
				// 		)
				// 	}
				// 	className="btn btn-primary w-50"
				// >
				// 	Add Employee
				// </button>
				null}
			</div>
			{tabtype ? <Masteradmin getData={getData} /> : null}
			<div className="p-5">
				<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
					{isadd === true ? (
						<div className="flex-1 mt-6 xl:mt-0">
							<form>
								<div className="grid grid-cols-12 gap-x-5">
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-1"
												className="form-label"
											>
												Employee Name
												<span className="text-danger">
													(*)
												</span>
											</label>
											<input
												id="update-profile-form-1"
												value={data.name}
												required
												type="text"
												name="name"
												className="form-control"
												onChange={handlechange}
											/>
										</div>
									</div>
									{master !== true ? (
										<>
											{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
											<div className="col-span-12 md:col-span-6 mt-3 mb-3">
												<label
													htmlFor="update-profile-form-2"
													className="form-label"
												>
													Select Department
												</label>
												<select
													className="form-select"
													name="department"
													onChange={handlechange}
													value={data.department}
												>
													<option key={-1} value="0">
														All department
													</option>
													{dept &&
														dept.length > 0 &&
														dept.map((item, i) => {
															return (
																<option
																	key={i}
																	value={
																		item.department_id
																	}
																>
																	{
																		item.deptname
																	}
																</option>
															);
														})}
												</select>
											</div>
										</>
									) : null}

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Role{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<select
											className="form-select"
											name="hod"
											onChange={handlechange}
											value={data.hod}
										>
											{/* {master === 0 && ( */}
											<>
												<option value="2">
													Employee
												</option>
												<option value="1">
													Manager
												</option>
											</>
											{/* )} */}
											<option value="0">Admin</option>
										</select>
									</div>

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Contact Number{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="update-profile-form-1"
											value={data.contactno}
											required
											minLength={10}
											type="number"
											name="contactno"
											className="form-control"
											onChange={handlechange}
										/>
									</div>

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="date-of-birth"
											className="form-label"
										>
											Date of Birth{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="date-of-birth"
											value={data.dob}
											required
											type="date"
											name="dob"
											className="form-control"
											onChange={handlechange}
											aria-describedby="dob-error"
										/>
										{/* Error message, conditionally rendered */}
										{errors.dob && (
											<span
												id="dob-error"
												className="text-danger"
											>
												Please enter a valid date of
												birth.
											</span>
										)}
									</div>

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="date-of-joining"
											className="form-label"
										>
											Date of Joining{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="date-of-joining"
											value={data.date_of_joining}
											required
											type="date"
											name="date_of_joining"
											className="form-control"
											onChange={handlechange}
											aria-describedby="date-of-joining-error"
										/>
										{/* Error message, conditionally rendered */}
										{errors.date_of_joining && (
											<span
												id="date-of-joining-error"
												className="text-danger"
											>
												Please enter a valid joining
												date.
											</span>
										)}
									</div>

									{/* <div className="col-span-12 xl:col-span-6 mt-4 flex flex-col items-start space-y-2">
										<label
											htmlFor="update-profile-form-1"
											className="form-label text-sm font-medium w-full text-left text-gray-700"
										>
											Select Department
											<span className="text-danger">
												(*)
											</span>
										</label>
										<select
											className="form-select p-3 rounded-md border-gray-300 focus:ring-2 focus:ring-indigo-500"
											name="department"
											onChange={handleChange}
											value={data.department}
										>
											<option key={-1} value="0">
												All Departments
											</option>
											{dept &&
												dept.length > 0 &&
												dept.map((item) => (
													<option
														key={item.department_id}
														value={
															item.department_id
														}
													>
														{item.deptname}
													</option>
												))}
										</select>
									</div> */}
									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
										<label
											htmlFor="date-of-joining"
											className="form-label"
										>
											Date of Joining{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="date-of-joining"
											value={data.date_of_joining}
											required
											type="date"
											name="date_of_joining"
											className="form-control"
											onChange={handlechange}
										/>
									</div> */}

									{master === true ? (
										<>
											<div className="col-span-12 md:col-span-6 mt-3 mb-3">
												<label
													htmlFor="update-profile-form-2"
													className="form-label"
												>
													Organization{" "}
													<span className="text-danger">
														(*)
													</span>{" "}
												</label>
												<select
													// style={{
													//   fontSize: "14px",
													//   width: "100%",
													//   padding: "8px",
													//   borderRadius: "8px",
													//   borderColor: "red",
													// }}
													name="organization"
													value={data.organization}
													required
													className="form-select"
													onChange={handlechange}
												>
													<option value="" key={-1}>
														Select Organization
													</option>
													{getdata.length > 0 &&
														getdata.map(
															(ele, i) => {
																return (
																	<option
																		value={
																			ele.uuid
																		}
																		key={i}
																	>
																		{
																			ele.name
																		}
																	</option>
																);
															}
														)}
												</select>
											</div>
										</>
									) : null}

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Email Address{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="update-profile-form-1"
											value={data.email}
											type="email"
											required
											name="email"
											className="form-control"
											onChange={handlechange}
										/>
									</div>

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Password{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="update-profile-form-1"
											value={data.password}
											type="password"
											required
											name="password"
											className="form-control"
											onChange={handlechange}
										/>
									</div> */}

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<div className="">
											<label>Status</label>
											<div className="form-switch mt-2">
												{loading ? (
													<div className="text-center m-5 p-5">
														<HashLoader
															color="#5755d9"
															size={30}
															style={{
																position:
																	"absolute",
																right: "50%",
															}}
															HashLoader
														/>
													</div>
												) : data &&
												  data.status === false ? (
													<input
														type="checkbox"
														name="status"
														onChange={handlechange}
														className="form-check-input"
													/>
												) : (
													<input
														type="checkbox"
														name="status"
														onChange={handlechange}
														value={data.status}
														checked
														className="form-check-input"
													/>
												)}
											</div>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => savedata()}
									className="btn btn-primary w-20 mt-3"
								>
									Save
								</button>
								<button
									type="button"
									onClick={() => adddata(1)}
									className="btn btn-danger w-20 mt-3 ml-2"
								>
									Cancel
								</button>
								<br />
								<br />
								<span
									className={
										msg.type === "success"
											? "text-success"
											: "text-danger"
									}
								>
									{msg.msg ? msg.msg : null}
								</span>
							</form>
						</div>
					) : null}
				</div>
				<div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
					<div className="flex mt-5 sm:mt-0">
						<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
							<input
								id="tabulator-html-filter-value"
								type="text"
								onChange={filterdata}
								className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
								placeholder="Search..."
							/>
						</div>
					</div>
					{master === true ? (
						<div className="flex mt-5 sm:mt-0">
							<select
								name="uuid"
								onChange={handlechange}
								className="form-control"
								id=""
							>
								<option value="1">Active</option>
								<option value="2">In-Active</option>
							</select>
						</div>
					) : null}
					<div className="hidden md:block ml-auto text-slate-500">
						Showing {itemOffset + 1} to{" "}
						{userdata.length > itemOffset + 10
							? itemOffset + 10
							: userdata.length}{" "}
						of {userdata.length} entries
					</div>
				</div>
				<div className="overflow-x-auto">
					{loading ? (
						<HashLoader
							color="#5755d9"
							size={30}
							style={{ position: "absolute", right: "50%" }}
						/>
					) : (
						<table
							className="table table-bordered"
							style={{ width: "100%" }}
						>
							<thead>
								<tr>
									<th className="whitespace-nowrap">#</th>
									<th className="whitespace-nowrap">Name</th>
									<th className="whitespace-nowrap">
										Username
									</th>
									<th className="whitespace-nowrap">Email</th>
									<th className="whitespace-nowrap">
										Contact No.
									</th>
									<th className="whitespace-nowrap">
										{master !== 1
											? "Department"
											: "Organization"}
									</th>
									<th className="whitespace-nowrap">
										Status
									</th>
									<th className="whitespace-nowrap">
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{currentItems && currentItems.length === 0 && (
									<tr>
										<td colSpan="8">No data available</td>
									</tr>
								)}
								{currentItems &&
									currentItems.length > 0 &&
									currentItems.map((item, i) => {
										return (
											<tr key={i}>
												<td>
													{userdata[item].user_id}
												</td>
												<td>
													<Link
														target="_blank"
														to={
															"/employees/" +
															userdata[item].uuid
														}
													>
														<u>
															{
																userdata[item]
																	.name
															}
														</u>
													</Link>
												</td>
												<td>
													{userdata[item].username}
												</td>
												<td>{userdata[item].email}</td>
												<td>
													{userdata[item].contact_no}
												</td>
												<td>
													{master !== 1
														? userdata[item]
																.deptname
														: renderOrgName(
																userdata[item]
																	.company_uuid ||
																	userdata[
																		item
																	].uuid
														  )}
												</td>
												<td>
													{userdata[item].active === 1
														? "Active"
														: "Inactive"}
												</td>
												<td>
													<div className="flex lg:justify-center items-center cursor-pointer">
														{userdata[item].type !==
															0 ||
														master === 1 ? (
															<>
																{/* <FaEdit
																	title="Edit"
																	style={{
																		color: "gray",
																	}}
																	className="mr-2"
																	onClick={() =>
																		editdata(
																			userdata[
																				item
																			]
																		)
																	}
																/> */}
																<FaEdit
																	title="Edit"
																	style={{
																		color: "gray",
																	}}
																	className="mr-2"
																	onClick={() => {
																		const editUrl = `/employees/Editemployee/${userdata[item].uuid}`;
																		window.location.href =
																			editUrl;
																	}}
																/>
																<FaTrashAlt
																	onClick={() =>
																		deletedata(
																			userdata[
																				item
																			]
																				.uuid
																		)
																	}
																	title="Delete"
																	className="text-danger mr-2"
																/>
																<FaSignInAlt
																	onClick={() =>
																		signindata(
																			userdata[
																				item
																			]
																		)
																	}
																	title={
																		"Sign In as " +
																		userdata[
																			item
																		].name
																	}
																	style={{
																		color: "rgb(64 96 255)",
																	}}
																	className="mr-2"
																/>
															</>
														) : (
															"-"
														)}
													</div>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>

						// <table
						// 	className="table table-bordered"
						// 	style={{ width: "100%" }}
						// >
						// 	<thead>
						// 		<tr>
						// 			<th className="whitespace-nowrap">#</th>
						// 			<th className="whitespace-nowrap">Name</th>
						// 			<th className="whitespace-nowrap">
						// 				Username
						// 			</th>
						// 			<th className="whitespace-nowrap">Email</th>
						// 			<th className="whitespace-nowrap">
						// 				Contact No.
						// 			</th>
						// 			<th className="whitespace-nowrap">
						// 				{master !== 1
						// 					? "Department"
						// 					: "Organization"}{" "}
						// 			</th>
						// 			<th className="whitespace-nowrap">
						// 				Status
						// 			</th>
						// 			<th className="whitespace-nowrap">
						// 				Actions
						// 			</th>
						// 		</tr>
						// 	</thead>
						// 	<tbody>
						// 		{currentItems && currentItems.length === 0 && (
						// 			<tr>
						// 				<td colSpan="8">No data available</td>
						// 			</tr>
						// 		)}
						// 		{currentItems &&
						// 			currentItems.length > 0 &&
						// 			currentItems.map((item, i) => {
						// 				return (
						// 					<tr key={i}>
						// 						<td>
						// 							{userdata[item].user_id}
						// 						</td>
						// 						<td>
						// 							<Link
						// 								target="_blank"
						// 								to={
						// 									"/employees/" +
						// 									userdata[item].uuid
						// 								}
						// 							>
						// 								<u>
						// 									{
						// 										userdata[item]
						// 											.name
						// 									}
						// 								</u>
						// 							</Link>
						// 						</td>
						// 						<td>
						// 							{userdata[item].username}
						// 						</td>
						// 						<td>{userdata[item].email}</td>
						// 						<td>
						// 							{userdata[item].contact_no}
						// 						</td>
						// 						<td>
						// 							{master !== 1
						// 								? userdata[item]
						// 										.deptname
						// 								: userdata[item]
						// 										.companyname}
						// 						</td>
						// 						<td>
						// 							{userdata[item].active === 1
						// 								? "Active"
						// 								: "Inactive"}
						// 						</td>
						// 						<td>
						// 							<div className="flex lg:justify-center items-center cursor-pointer">
						// 								{/* <div
						//       className="edit flex items-center mr-3"
						//       onClick={() => editdata(userdata[item])}
						//       style={{ cursor: "pointer" }}
						//     >
						//       <svg
						//         xmlns="http://www.w3.org/2000/svg"
						//         width="24"
						//         height="24"
						//         viewBox="0 0 24 24"
						//         fill="none"
						//         stroke="currentColor"
						//         strokeWidth="2"
						//         strokeLinecap="round"
						//         strokeLinejoin="round"
						//         icon-name="check-square"
						//         data-lucide="check-square"
						//         className="lucide lucide-check-square w-4 h-4 mr-1"
						//       >
						//         <polyline points="9 11 12 14 22 4"></polyline>
						//         <path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"></path>
						//       </svg>
						//       Edit

						//     </div> */}
						// 								{userdata[item].type !==
						// 									0 ||
						// 								master === 1 ? (
						// 									<>
						// 										<FaEdit
						// 											title="Edit"
						// 											style={{
						// 												color: "gray",
						// 											}}
						// 											className="mr-2"
						// 											onClick={() =>
						// 												editdata(
						// 													userdata[
						// 														item
						// 													]
						// 												)
						// 											}
						// 										/>
						// 										<FaEdit
						// 											title="Edit"
						// 											style={{
						// 												color: "gray",
						// 											}}
						// 											className="mr-2"
						// 											onClick={() => {
						// 												const editUrl = `/employees/Editemployee/${userdata[item].uuid}`;
						// 												window.open(
						// 													editUrl,
						// 													"_blank"
						// 												);
						// 											}}
						// 										/>

						// 										<FaTrashAlt
						// 											onClick={() =>
						// 												deletedata(
						// 													userdata[
						// 														item
						// 													]
						// 														.uuid
						// 												)
						// 											}
						// 											title="Delete"
						// 											className="text-danger mr-2"
						// 										/>
						// 										<FaSignInAlt
						// 											onClick={() =>
						// 												signindata(
						// 													userdata[
						// 														item
						// 													]
						// 												)
						// 											}
						// 											title={
						// 												"Sign In as " +
						// 												userdata[
						// 													item
						// 												].name
						// 											}
						// 											style={{
						// 												color: "#rgb(64 96 255)",
						// 											}}
						// 											className="mr-2"
						// 										/>
						// 									</>
						// 								) : (
						// 									"-"
						// 								)}
						// 							</div>
						// 						</td>
						// 					</tr>
						// 				);
						// 			})}
						// 	</tbody>
						// </table>
					)}

					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
						<br />
						<nav className="w-full sm:w-auto sm:mr-auto">
							{!loading ? (
								<div className="row">
									<div className="col-md-3"></div>
									<div className="col-md-4">
										<ReactPaginate
											pageCount={pageCount}
											pageRangeDisplayed={10}
											marginPagesDisplayed={1}
											onPageChange={handlePageClick}
											containerClassName="pagination"
											activeClassName="active"
											pageLinkClassName="page-link"
											breakLinkClassName="page-link"
											nextLinkClassName="page-link"
											previousLinkClassName="page-link"
											pageClassName="page-item"
											breakClassName="page-item"
											nextClassName="page-item"
											previousClassName="page-item"
											previousLabel={<>&laquo;</>}
											nextLabel={<>&raquo;</>}
										/>
									</div>
									<div className="col-md-4"></div>
								</div>
							) : (
								<HashLoader
									color="#5755d9"
									size={30}
									style={{
										position: "absolute",
										right: "50%",
									}}
								/>
							)}
						</nav>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Departments;
