import * as xlsx from "xlsx";

export const exportToExcel=async(downloaddata,basketname="", type=0)=>{
    try {
        const ws = xlsx.utils.aoa_to_sheet([[]]);
        xlsx.utils.sheet_add_json(ws, downloaddata, {
            dateNF: "dd-mm-yyyy",
            cellDates: true,
          });
          const wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Data");
          if(type==0){
            if(basketname===""){
              xlsx.writeFile(wb, `Holdings-${localStorage.getItem('broker')}.xlsx`);
            }else{
              xlsx.writeFile(wb, `${basketname}-${localStorage.getItem('broker')}.xlsx`);
            }
          }else{
            xlsx.writeFile(wb, `${basketname}.xlsx`);
          }
    } catch (error) {
        console.log(error);
    }
    }