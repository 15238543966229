import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Inviter, UserAgent } from 'sip.js';
import { setupRemoteMedia } from '../redux/reducers/sipjs/sipjsService';
import { hideCallingScreeenFun, removeSessionData, setCallIncomingOutGoingSession, setSessionState, showCallingScreeenFun, toggleVideoCallingScreen } from '../redux/reducers/sipjs/sipjsSlice';
import { hideDialpadeSection, showDialpadeSection,setTimer } from '../redux/reducers/themeSlice';
import useCallTimerDuration from './useCallTimerDuration';
import { cleanupMedia, connectingCallRigton, detectAudioVideoPermission } from '../utils/Utils';
import { getConfigSettings } from '../data/userSettings';



export let sessionData = {};
const _accountCreds = JSON.parse(localStorage.getItem('_accountCreds'));

const usePhonedial = () => {
    const dispatch = useDispatch();
    const { userAgent, sessionState } = useSelector((state) => state.sipjs);
    const { accountCreds } = useSelector((state) => state.auth)
    const [calltimer, TimerAction] = useCallTimerDuration()
    const dialPhone = async (number, video = false) => {
        // console.log(_accountCreds);
        if (await detectAudioVideoPermission(video)) {
            if (number != "") {
                let _sipServer = _accountCreds._sipServer;
                console.log(_sipServer);
                // _sipServer = "switch.nyerhosmobile.com:7443"
                var uri = UserAgent.makeURI(`sip:${number}@${_sipServer}`);
                if (video) {
                    dispatch(toggleVideoCallingScreen(true))
                }
                let _extraHeaders = {};
                let _headers = [];
                let earlyMedia = [true, 'true', 'TRUE', '1', 1].includes(getConfigSettings('call.earlymedia')) ? true : false;
                for (let i in _extraHeaders) {
                    _headers.push(i + ": " + _extraHeaders[i]);
                }
                const inviteOptions = {
                    sessionDescriptionHandlerOptions: {
                        constraints: {
                            audio: true,
                            video: video
                        },
                    },
                    extraHeaders: _headers,
                    earlyMedia: earlyMedia,
                    // contactParams: { transport: 'wss' },
                }

                let session = await new Inviter(userAgent, uri, inviteOptions);
                let setSession = {
                    callDirection: "outgoing",
                    session: session,
                    sessionId: session.id,
                };

                dispatch(setCallIncomingOutGoingSession(setSession))
                dispatch(setSessionState('Initial'))
                

                session.stateChange.addListener(async (state) => {
                    // sessionTeminared(state);
                    dispatch(setSessionState(state))
                    // alert("session" + " _ " + state + " _ " + session._id)
                    switch (state) {
                        case "Initial":
                            console.warn("sesssion initial");
                            break;
                        case "Established":
                            // TimerAction('start')
                            dispatch(setTimer(1))
                            setupRemoteMedia(session, video)
                            break;
                        case "Terminated":
                            dispatch(setTimer(2))
                            // console.debug('session terminated id', session._id)
                            connectingCallRigton('pause')
                            dispatch(removeSessionData(session._id))
                            dispatch(showDialpadeSection())
                            dispatch(hideCallingScreeenFun())
                            removeSessionData(session.id)
                            dispatch(toggleVideoCallingScreen(false))
                            cleanupMedia();
                            break;
                        default:
                            break;
                    }
                });



                // Setup outgoing session delegate
                session.delegate = {
                    // Handle outgoing REFER request
                    onRefer(referral) {
                        //console.log("Handle outgoing REFER request");
                        referral.accept().then(() => {
                            referral.makeInviter().invite();
                        });
                    },
                };


                // Options including delegate to capture response messages
                const inviteOptionsOB = {
                    requestDelegate: {
                        onAccept: (response) => {
                            dispatch(setCallIncomingOutGoingSession(setSession))
                            console.warn("Positive response ....", response);

                        },
                        onReject: (response) => {
                            // alert('dkgvfdjnbgfncnbjcgfb ')
                            console.log("Negative response ....");
                            // console.log(response);
                            cleanupMedia();
                        }
                    },
                    sessionDescriptionHandlerOptions: {
                        constraints: {
                            audio: true,
                            video: video
                        }
                    },
                };

                // Send invitation
                var OBSession = session.invite(inviteOptionsOB).then((request) => {
                    console.log("Successfully sent INVITE ....");
                    // console.log(request);
                }).catch((error) => {
                    console.log("Failed to send INVITE ==> ", error);
                });



                sessionData[session.id] = session
                dispatch(showDialpadeSection())
                dispatch(showCallingScreeenFun())


            } else {
                toast.error('Enter Mobile Number To Dail Call')
            }
        }

    }
    return [dialPhone];
}

export const removeSessionRecord = (sessionId) => {
    sessionData = Object.keys(sessionData)
        .filter((fkey) => fkey !== sessionId)
        .reduce((obj, key) => {
            obj[key] = sessionData[key];
            return obj;
        }, {})
}
export default usePhonedial;
