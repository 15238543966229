import React, { useState } from "react";
import {
	FaBars,
	FaTimesCircle,
	FaChevronDown,
	FaChevronUp,
	FaHome,
	FaUser,
	FaClock,
	FaProjectDiagram,
	FaTasks,
	FaFile,
	FaUserCircle,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const MobileNav = () => {
	const location = useLocation();
	const [menu1, setmenu1] = useState(false);
	const [menu2, setmenu2] = useState(false);
	const [menu3, setmenu3] = useState(false);
	const [ismenu, setismenu] = useState(false);
	const [isAuth, setIsAuth] = useState(
		JSON.parse(localStorage.getItem("isAuth"))
	);
	const [master] = React.useState(
		localStorage.getItem("isAuth") &&
			JSON.parse(localStorage.getItem("isAuth")).master
	);

	return (
		<>
			<div
				className={
					ismenu === true
						? "mobile-menu mobile-menu--active"
						: "mobile-menu md:hidden"
				}
			>
				<div className="mobile-menu-bar">
					<Link to={"/"} className="flex mr-auto">
						<img
							alt="Midone - HTML Admin Template"
							className="w-6"
							src="../images/logo.svg"
						/>
						<span className="xl:block text-white text-lg ml-3">
							{isAuth &&
								isAuth.companydata &&
								isAuth.companydata[0].name}{" "}
							Tracking
						</span>
					</Link>
					<div className="mobile-menu-toggler">
						<FaBars
							onClick={() => setismenu(!ismenu)}
							className="text-white"
						></FaBars>
					</div>
				</div>
				<div className="scrollable" style={{ overflowY: "overlay" }}>
					<div className="mobile-menu-toggler">
						<FaTimesCircle
							onClick={() => setismenu(!ismenu)}
							className="text-white mt-2"
						></FaTimesCircle>
					</div>

					<ul>
						<li>
							<Link
								onClick={() => setismenu(!ismenu)}
								to={"/"}
								className={
									location.pathname === "/"
										? "menu menu--active"
										: "menu"
								}
							>
								<div className="menu__icon">
									<FaHome />
								</div>
								<div className="menu__title">
									Home
									<div className="menu__sub-icon transform rotate-180">
										<i data-lucide="chevron-down"></i>
									</div>
								</div>
							</Link>

							{isAuth.role === 0 ? (
								<>
									<div
										className="menu"
										onClick={() => setmenu3(!menu3)}
									>
										<div className="menu__icon">
											<FaProjectDiagram />
										</div>
										<div className="menu__title">
											Dashboard
											<div className="menu__sub-icon">
												{menu3 ? (
													<FaChevronUp />
												) : (
													<FaChevronDown />
												)}	
											</div>
										</div>
									</div>

									<ul
										className={
											menu3 ? "menu__sub-open" : null
										}
									>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/dashboard/summary"
												className={
													location.pathname ===
													"/dashboard/summary"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Summary
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/dashboard/desktop-activity"
												className={
													location.pathname ===
													"/dashboard/desktop-activity"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Desktop Activity
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/dashboard/app-usage"
												className={
													location.pathname ===
													"/dashboard/app-usage"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													App Usages
												</div>
											</Link>
										</li>
									</ul>

									{/* className={location.pathname==="/as" ? 'menu menu--active': 'menu'} */}
									<div
										className="menu"
										onClick={() => setmenu1(!menu1)}
									>
										<div className="menu__icon">
											<FaProjectDiagram />
										</div>
										<div className="menu__title">
											App Insights
											<div className="menu__sub-icon">
												{menu1 ? (
													<FaChevronUp />
												) : (
													<FaChevronDown />
												)}
											</div>
										</div>
									</div>

									<ul
										className={
											menu1 ? "menu__sub-open" : null
										}
									>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/insights/summary"
												className={
													location.pathname ===
													"/insights/summary"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Summary
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/insights/timeline"
												className={
													location.pathname ===
													"/insights/timeline"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Timeline
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/insights/productivity"
												className={
													location.pathname ===
													"/insights/productivity"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Productivity
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/insights/screenshots"
												className={
													location.pathname ===
													"/insights/screenshots"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Screenshots
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/insights/applications"
												className={
													location.pathname ===
													"/insights/applications"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Applications
												</div>
											</Link>
										</li>
									</ul>

									<Link
										onClick={() => setismenu(!ismenu)}
										to={"/employees"}
										className={
											location.pathname === "/employees"
												? "menu menu--active"
												: "menu"
										}
									>
										<div className="menu__icon">
											<FaUser />
										</div>
										<div className="menu__title">
											Employee
											<div className="menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link>
								</>
							) : null}
							{master !== 1 && (
								<>
									<Link
										onClick={() => setismenu(!ismenu)}
										to={"/leaves"}
										className={
											location.pathname === "/leaves"
												? "menu menu--active"
												: "menu"
										}
									>
										<div className="menu__icon">
											<FaUser />
										</div>
										<div className="menu__title">
											Leaves
											<div className="menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link>

									<Link
										to="#"
										className={"menu"}
										onClick={() => setmenu2(!menu2)}
									>
										<div className="menu__icon">
											<FaClock />
										</div>
										<div className="menu__title">
											Attendance
											<div className="menu__sub-icon transform rotate-180">
												{!menu2 ? (
													<FaChevronUp />
												) : (
													<FaChevronDown />
												)}
											</div>
										</div>
									</Link>

									<ul
										className={
											menu2 ? "menu__sub-open" : null
										}
									>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/attendance/attendance-details"
												className={
													location.pathname ===
													"/attendance/attendance-details"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Attendance Details
												</div>
											</Link>
										</li>
										<li>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to="/attendance/monthly-records"
												className={
													location.pathname ===
													"/attendance/monthly-records"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<i data-lucide="activity"></i>
												</div>
												<div className="menu__title">
													Monthly Records
												</div>
											</Link>
										</li>
									</ul>
								</>
							)}

							{isAuth.role === 0 ? (
								<>
									{master !== 1 && (
										<>
											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to={"/feeds"}
												className={
													location.pathname ===
													"/feeds"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<FaUser />
												</div>
												<div className="menu__title">
													Feeds
													<div className="menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link>

											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to={"/employee-activity"}
												className={
													location.pathname ===
													"/employee-activity"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<FaTasks />
												</div>
												<div className="menu__title">
													Employee Activity
													<div className="menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link>

											<Link
												onClick={() =>
													setismenu(!ismenu)
												}
												to={"/report"}
												className={
													location.pathname ===
													"/report"
														? "menu menu--active"
														: "menu"
												}
											>
												<div className="menu__icon">
													<FaFile />
												</div>
												<div className="menu__title">
													Report
													<div className="menu__sub-icon transform rotate-180">
														<i data-lucide="chevron-down"></i>
													</div>
												</div>
											</Link>
										</>
									)}

									<Link
										onClick={() => setismenu(!ismenu)}
										to={"/admin"}
										className={
											location.pathname === "/admin"
												? "menu menu--active"
												: "menu"
										}
									>
										<div className="menu__icon">
											<FaUserCircle />
										</div>
										<div className="menu__title">
											Admin
											<div className="menu__sub-icon transform rotate-180">
												<i data-lucide="chevron-down"></i>
											</div>
										</div>
									</Link>
								</>
							) : null}
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};

export default MobileNav;
