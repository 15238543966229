import React, { useEffect, useState } from "react";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useLoadingContext } from "react-router-loading";
const Leaves = () => {
  const loadingContext = useLoadingContext();

  const [data, setdata] = useState([]);
  const [bkpdata, setbkpdata] = useState([]);

  useEffect(() => {
    getData();
   
  }, []);

  let getData = () => {
    let arr = [
      {
        name: "Attendance",
        description:
          "Employee attendance report. This report will show the detailed attendance report per employee.",
      },

      {
        name: "Monthly Attendance",
        description:
          "This report will show the detailed monthly attendance view for each employee with deduction, present, absent, leave count and their details.",
      },
      {
        name: "Attendance Regularization",
        description:
          "This report will show the detailed attendance regularization view of the employee with their status such as pending approval, approved, rejected.",
      },
      {
        name: "Late Mark",
        description:
          "This report will show the detail view of the late coming employees containing date and time on which they came late.",
      },
      {
        name: "Leave",
        description: "Showing employee leaves",
      },
      {
        name: "Daily Productivity",
        description:
          "This report has employee wise daily productivity related details with date.",
      },

      {
        name: "App Usage By Day",
        description: "Application Usage Report by Employee and usage day wise.",
      },

      {
        name: "App Usage",
        description:
          "Desktop application usage report by Employee and show total time spent on each desktop application",
      },

      {
        name: "Employee Leave Balance",
        description:
          "This report shows all the leave details of each employee.",
      },
    ];
    setdata(arr);
    setbkpdata(arr);
    loadingContext.done();
  };

  const columns = [
    { title: "Name", field: "name", formatter: reactFormatter(<DetailVal />) },
    { title: "Description", field: "description" },
  ];

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  function DetailVal(props) {
    const rowData = props.cell._cell.row.data;
    let url = rowData.name
      .replaceAll(" ", "-")
      .replaceAll("/", "-")
      .toLowerCase();
    return (
      <a href={"report/" + url} target="_blank" rel="noreferrer" alt="">
        <u>{rowData.name}</u>
      </a>
    );
  }

  const filterdata = (event) => {
    if (event.target.value === "") {
      setdata(bkpdata);
    } else {
      let matches = bkpdata.filter(function (s) {
        return (
          s.name.toLowerCase().includes(`${event.target.value}`) ||
          s.description.toLowerCase().includes(`${event.target.value}`)
        );
      });
      if (matches.length > 0) {
        setdata(matches);
      } else {
        setdata(bkpdata);
      }
    }
  };

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center mt-3">
        <h2 className="text-lg font-medium mr-auto">Report Template</h2>
      </div>
      <div className="intro-y box p-5 mt-5">
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <div className="flex mt-5 sm:mt-0">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                onChange={filterdata}
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <div className="overflow-x-auto scrollbar-hidden">
          {data && data.length > 0 ? (
            <ReactTabulator
              columns={columns}
              data={data}
              options={options}
              className="mt-5 table-report table-report--tabulator"
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Leaves;
