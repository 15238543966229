
export let userSettings = {

};

export const AddSettings = (object) => {
    userSettings = { ...userSettings, ...object };
}
export const getConfigSettings = (alias) => {
    // console.debug('settings alias', alias)
    let settings = userSettings;
    let val = '';
    let divided = alias.split(".");
    let div1 = (typeof divided[0] !== 'undefined') ? divided[0] : '';
    let div2 = (divided[1] != '') ? divided[1] : '';
    if (typeof settings[div1] !== 'undefined' && typeof settings[div1][div2] !== 'undefined' && typeof settings[div1][div2]['value'] !== 'undefined') {
        return settings[div1][div2]['value'].toString();
    }
    return val;
}

export const getConfigSettingsOption = (alias) => {
    let val = '';
    let divided = alias.split(".");

    let div1 = (typeof divided[0] !== 'undefined') ? divided[0] : '';
    let div2 = (divided[1] != '') ? divided[1] : '';
    if (typeof userSettings[div1] !== 'undefined' && typeof userSettings[div1][div2] !== 'undefined' && typeof userSettings[div1][div2]['options'] !== 'undefined') {
        return userSettings[div1][div2]['options'].toString();
    }
    return val;
}
