import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BASEURL } from "../BASEURL";

function TestResult() {
	const [testData, setTestData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const params = useParams();

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = String(date.getFullYear()).slice(-2);

		return `${day}-${month}-${year}`;
	};

	const formatTime = (dateString) => {
		const date = new Date(dateString);
		let hours = date.getHours();
		const minutes = String(date.getMinutes()).padStart(2, "0");
		const ampm = hours >= 12 ? "PM" : "AM";

		hours = hours % 12;
		hours = hours ? String(hours).padStart(2, "0") : "12";

		return `${hours}:${minutes} ${ampm}`;
	};

	const getData = async () => {
		try {
			const formData = new FormData();
			formData.append("uuid", params.id);

			const res = await axios.post(BASEURL + "get-jobdata", formData, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (res.data.status === 201 && res.data.data.length > 0) {
				setTestData(res.data.data[0]);
			} else {
				setError("No test data found");
			}
		} catch (error) {
			setError("Failed to fetch data");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error}</div>;
	}

	if (!testData) {
		return <div>No test data available</div>;
	}

	const videoBaseUrl = "https://trackapi.rayvat.com/uploads/screenrecording/";
	const videoPath = `${videoBaseUrl}${testData.test_video_path}`;

	return (
		<>
			{testData.isTestCompleted === 1 ? (
				<div className="intro-y box px-5 pt-2 mt-2">
					<div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5">
						<div className="w-full lg:w-1/2 pr-4">
							<h2 className="font-medium text-lg">
								Assessment Test Result
							</h2>
							<div className="overflow-x-auto">
								<table
									className="min-w-full bg-white border border-gray-300"
									style={{
										width: "100%",
										maxWidth: "1000px",
									}}
								>
									<thead>
										<tr>
											<th className="border border-gray-300 p-2 text-left">
												Description
											</th>
											<th className="border border-gray-300 p-2 text-left">
												Value
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="border border-gray-300 p-2 font-medium">
												Total Marks
											</td>
											<td className="border border-gray-300 p-2">
												{testData?.test_outof}
											</td>
										</tr>
										<tr>
											<td className="border border-gray-300 p-2 font-medium">
												Obtained Marks
											</td>
											<td className="border border-gray-300 p-2">
												{testData.test_result}
											</td>
										</tr>
										<tr>
											<td className="border border-gray-300 p-2 font-medium">
												Test Percentage:
											</td>
											<td className="border border-gray-300 p-2">
												{testData.test_percentage}
											</td>
										</tr>
										<tr>
											<td className="border border-gray-300 p-2 font-medium">
												Test Given Date
											</td>
											<td className="border border-gray-300 p-2">
												{formatDate(
													testData.test_done_at
												)}{" "}
												{formatTime(
													testData.test_done_at
												)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="lg:w-1/2 mt-4 lg:mt-0">
							<div className="font-medium text-center">
								Watch the Test Video:
								<video width="100%" controls className="mt-2">
									<source src={videoPath} type="video/webm" />
									Your browser does not support the video tag.
								</video>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="text-center font-medium">No Data</div>
			)}
		</>
	);
}

export default TestResult;
