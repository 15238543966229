import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { format } from "date-fns";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator.min.css";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import HashLoader from "react-spinners/HashLoader";

const SummaryApplications = (props) => {
  let [loading, setLoading] = useState(false);
  const [appdata, setappdata] = useState([]);

  useEffect(() => {
    if (props.ldate !== undefined) {
    }
    getAppData(
      format(new Date(props.date), "yyyy-MM-dd"),
      props.ldate,
      props.username
    );
  }, []);

  useEffect(() => {
    if (props.ldate !== undefined) {
    }
    getAppData(
      format(new Date(props.date), "yyyy-MM-dd"),
      props.ldate,
      props.username
    );
  }, [props.username, props.date, props.ldate]);

  const getAppData = async (date, ldate, username) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-AppData/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date,
        ldate,
        username,
        company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        master:JSON.parse(localStorage.getItem("isAuth")).master
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setappdata(res.data);
          props.getappdata(res.data);
        } else {
          setappdata([{ appname: "No data" }]);
          props.getappdata([]);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  function AppName(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <div style={{ display: "Flex", padding: "8px" }}>
        <img src={rowData.icon} alt="" className="w-25" />{" "}
        <span className="mt-2 ml-2">{rowData.appname}</span>
      </div>
    );
  }
  function AppTime(props) {
    const rowData = props.cell._cell.row.data;
    var timeString;
    if (rowData.time !== undefined) {
      var date = new Date(0);
      date.setSeconds(rowData.time);
      timeString = date.toISOString().substring(11, 19);
    } else {
      timeString = "";
    }
    return <>{timeString}</>;
  }
  function AppUsage(props) {
    const rowData = props.cell._cell.row.data;
    if (rowData.usage !== undefined) {
      return (
        <>
          <div
            id="progressbar-height"
            className="p-1"
            style={{ width: "215px" }}
          >
            <div className="intro-y grid grid-cols-12 gap-5 mt-2">
              <div className="col-span-12 lg:col-span-11 2xl:col-span-10">
                <div className="preview">
                  <div className="progress h-2 mb-1 mt-2">
                    <div
                      className="progress-bar"
                      style={{ width: rowData.usage + "%" }}
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {" "}
                    </div>
                    {rowData.usage}%
                  </div>
                </div>
              </div>
              {/* <div className="col-span-12 lg:col-span-1 2xl:col-span-2">
                
              </div> */}
            </div>
          </div>
        </>
      );
    } else {
      return "";
    }
  }

  const columns = [
    {
      title: "Application Name",
      field: "appname",
      formatter: reactFormatter(<AppName />),
      width: "50%",
      
    },
    {
      title: "Usage Duration",
      field: "time",
      formatter: reactFormatter(<AppTime />),
      width: "25%",
    },
    {
      title: "App Usage in %",
      field: "usage",
      formatter: reactFormatter(<AppUsage />),
      width: "45%",
    },
  ];

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  return (
    <>
      {loading === true ? (
        <div className="mt-5 " style={{ display: "-webkit-inline-box" }}>
          <HashLoader color="#5755d9" size={30} />
        </div>
      ) : (
        <div className="intro-y grid grid-cols-12 gap-5 mt-2">
          <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
            {appdata && appdata.length > 0 ? (
              <ReactTabulator
                columns={columns}
                data={appdata}
                options={options}
                className="table-report table-report--tabulator"
              />
            ) : (
              "No Data"
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SummaryApplications;
