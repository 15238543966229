import React, { useState, useEffect } from "react";
import { SITEURL, BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Masteradmin from "../Masteradmin";
import Select from "react-select";

import {

  FaTrashAlt,
  FaEdit
} from "react-icons/fa";

import Swal from "sweetalert2";


const EvolutionPoints = () => {


  const [department, setdepartment] = useState(null);
  const [isadd, setisadd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [departmentemp, setdepartmentemp] = useState([])
  const [tabtype, settabtype] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [evolutionPoints, setEvolutionPoints] = useState([]);
  
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPoints, setFilteredPoints] = useState([]);
  const [updateShow, setUpdateShow] = useState(false)
  const [getdata, setgetdata] = useState([]);
  const [Formdata, setFormdata] = useState({
    Employee:"",
    Behaviour_on_Floor: "",
    Participate_in_activity : "",
    Leave_and_Reason: "",
    How_they_speak: "", 
    Late_Login_and_Lunch: "",
    New_Learnings: "",
    Timly_Completed_Work: "",
    Accuracy_in_Work:"",
    Help_to_Other:"",
    Desk_Management:"",
    Manager_Review:""
  }); 



  const PostevPoinst = async (data) => {
    try {
      const response = await fetch(BASEURL + 'addevolutionpoints', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({data, department})
      });
      if (!response.ok) {
        throw new Error('Failed to submit form data');
      }
  
      // Update the state with the new data after successful submission
      // const newData = await response.json();
      // setEvolutionPoints([...evolutionPoints, newData]);
  
      // Clear the form fields
      setFormdata({
        Employee: selectedEmployee, 
        Behaviour_on_Floor: "",
        Participate_in_activity: "",
        Leave_and_Reason: "",
        How_they_speak: "",
        Late_Login_and_Lunch: "",
        New_Learnings: "",
        Timly_Completed_Work: "",
        Accuracy_in_Work: "",
        Help_to_Other: "",
        Desk_Management: "",
        Manager_Review: ""
      });
  
      swal({
        title: "Success",
        text: "Evolution points added successfully",
        icon: "success",
        timer: 2000,
      });
    } catch (error) {
      console.error('Error submitting form data:', error);
      // swal({
      //   title: "Oops...",
      //   text: "Something went wrong!",
      //   icon: "error",
      //   timer: 2000,
      // });
    }
  };
  
  const GetempData = async () => {
    settabtype(false);
    setLoading(true);
    const myHeaders = new Headers();
  
    myHeaders.append("Content-Type", "application/json");
    let data = await fetch(BASEURL + `departments`, {
      method: "GET",
      headers: myHeaders,
    });
    let data2 = await data.json();
    // console.log(data2);
    
    setgetdata(data2);
    setLoading(false);
  }

  useEffect(() => {
    setFormdata(prevFormdata => ({
      ...prevFormdata,
      Employee: selectedEmployee
    }));
  }, [selectedEmployee]);
  


  const getdepartments = async (department_id) => {
    settabtype(false);
    setLoading(true);
    const myHeaders = new Headers();
    
    myHeaders.append("Content-Type", "application/json");
    let data = await fetch(BASEURL + `departments-emp/${department_id}`, {
      method: "GET",
      headers: myHeaders,
    });
    let data2 = await data.json();
    // console.log("response data from api of department employee", data2);
    setdepartmentemp(data2);
    setLoading(false);
  };


  const deletedata = ( id ) => {
    // console.log("my deleted id ", id);
    swal({
      title: "Delete",
      text: `Please confirm if you want to Delete Employee data?`,
      buttons: ["No", "Yes"],
      showCloseButton: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        const myHeaders = new Headers();
        // let userdata = JSON.parse(localStorage.getItem("isAuth"));
        // if (userdata != null) {
        //   let token = userdata.usertoken;
        //   myHeaders.append("Authorization", token);
        // }
        myHeaders.append("Content-Type", "application/json");
        fetch(BASEURL + `delete-emp-points/${id}`, {
          method: "DELETE",
          headers: myHeaders,
        })
          .then((response) => response.json())
          .then((res) => {
            // getUserData();
            GetevPoints();
            setLoading(false); // Move setLoading(false) inside the .then bloc k
            swal({
              title: "Success",
              text: res.msg,
              icon: "success",
              timer: 2000,
            });
          })
          .catch((err) => {
            console.log(err);
            setLoading(false); // Move setLoading(false) inside the .catch block
          });
      } else {
        setLoading(false); // Move setLoading(false) inside the else block if "No" is clicked
      }
    });
  };

  const updatedata = async (id) => {
    // e.preventDefault();
    console.log("updating data: " + id);
    try {
      const response = await fetch(BASEURL + `update-emp-points/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Formdata),
      });
      const data = await response.json();
     
      Swal.fire({
      
        icon: "success",
        title: "Evolution points updated successfully",
        showConfirmButton: false,
        timer: 1000
      });
    
      GetevPoints();
      setisadd(false)
     
      // Handle success or error response
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const editdata = (point) => {
    // console.log("editing data of emp id", point);
    setisadd(true)
    setUpdateShow(true)

    setFormdata({
      id: point.id,
      // Employee: point.Employee, 
      Behaviour_on_Floor: point.Behaviour_on_Floor, 
      Participate_in_activity : point.Participate_in_activity,
      Leave_and_Reason: point.Leave_and_Reason,
      How_they_speak: point.How_they_speak,
      Late_Login_and_Lunch: point.Late_Login_and_Lunch,
      New_Learnings: point.New_Learnings,
      Timly_Completed_Work: point.Timly_Completed_Work,
      Accuracy_in_Work: point.Accuracy_in_Work,
      Help_to_Other: point.Help_to_Other,
      Desk_Management: point.Desk_Management,
      Manager_Review: point.Manager_Review,
    })
  };



 
  const adddata = () => {
    
    settabtype(false);
    setisadd(!isadd);
    setUpdateShow(false)
   
  };

  
 
  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...Formdata, [name]: value });
  };




  const HandleSubmit = (e) => {
    e.preventDefault();
    setisadd(false);
    PostevPoinst(Formdata);
    GetevPoints();
    
  };

  useEffect(() => {
    filterData(searchQuery);
  }, [searchQuery, evolutionPoints]);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

    // Function to filter data based on search query
    const filterData = (query) => {
      const filtered = evolutionPoints.filter((point) =>
        point.Employee.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredPoints(filtered);
    };

  
  // console.log("evolution point data:",evolutionPoints)



    const GetevPoints = async () => {
      try {
        const response = await fetch(BASEURL+'evolutionpoints');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // console.log("evolution points api data:", data);
        setEvolutionPoints(data);
        setFilteredPoints(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

  
  const [currentPage, setCurrentPage] = useState(0);
  const [dataPerPage] = useState(10);

  const indexOfLastData = (currentPage + 1) * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredPoints.slice(indexOfFirstData, indexOfLastData);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };


// console.log("data of department employee",departmentemp)

useEffect(() => {
   
  
  GetempData();
  GetevPoints(currentData);
}, []);



  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">Evolution Points</h2>
       
     
        {isadd && (<div className="lg:ml-2  ">
          <Select className="basic-single text-center mr-2"
                        onChange={(e) => {
                          // console.log(e);
                          getdepartments(e.department_id);
                          setdepartment(e.department_id)
                        }}
                        options={getdata}
                        getOptionLabel={(Department) =>
                          `${Department.name}`
                        }                  
                        styles={{
                          option: (base) => ({
                            ...base,
                            height: "100%",
                            display: "flex",
                          }),
                        }}
                      />
         
          <Select
                        className="basic-single text-center mr-2"
                        options={departmentemp}
                        onChange={(e)=>setSelectedEmployee(e.name)
                          
                         
                        }
                        // isLoading={loading}
                        getOptionLabel={(active) =>
                          `${active.name}`  
                        }
                        styles={{
                          option: (base) => ({
                            ...base,
                            height: "100%",
                            display: "flex",
                          }),
                        }}
                      />
                      
          </div>)}

   
          <button
            type="button"
            onClick={() => adddata(0)}
            className="btn btn-primary w-50 lg:ml-2"
          >
            Add Evolution Points
          </button>
       
      </div>
      {tabtype ? <Masteradmin GetempData={GetempData}  /> : null}

      <div className="p-5">
        <div className="flex flex-col-reverse xl:flex-row flex-col text-left">
          {isadd === true ? (
            <div className="flex-1 mt-6 xl:mt-0">
              <form onSubmit={HandleSubmit}>  
                <div className="grid grid-cols-12 gap-x-5">
                  <div className="col-span-11 2xl:col-span-3 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Behaviour on Floor  (0/10)
                      </label>
                      <input
                        id="update-profile-form-1"
                        value={Formdata.Behaviour_on_Floor}
                        required
                        type="number"
                        name="Behaviour_on_Floor"
                        className="form-control"
                        onChange={handlechange}
                      />
                    </div>
                  </div>
               

                  <div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
                    <label
                      htmlFor="update-profile-form-2"
                      className="form-label"
                    >
                      Participate in activity (0/10)
                    </label>
                    <input
                        id="update-profile-form-1"
                        value={Formdata.Participate_in_activity}
                        required
                        type="number"
                        name="Participate_in_activity"
                        className="form-control"
                        onChange={handlechange}
                      />
                  </div>
                  
                  <div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
                    <label
                      htmlFor="update-profile-form-2"
                      className="form-label"
                    >
                      Leave and Reason (0/10)
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={Formdata.Leave_and_Reason}
                      required
                      minLength={10}
                      type="number"
                      name="Leave_and_Reason"
                      className="form-control"
                      onChange={handlechange}
                      
                    />
                  </div>
                 
                  <div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
                    <label  htmlFor="update-profile-form-2"
                      className="form-label">How they speak (0/10)  </label>
                    <input
                      id="update-profile-form-1"
                      value={Formdata.How_they_speak}
                      required
                      minLength={10}
                      type="number"
                      name="How_they_speak"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                 

                  <div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
                    <label
                      htmlFor="update-profile-form-2"
                      className="form-label"
                    >
                      Late Login and Lunch  (0/10)
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={Formdata.Late_Login_and_Lunch}
                      required
                      minLength={10}
                      type="number"
                      name="Late_Login_and_Lunch"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>

                  <div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
                    <label
                      htmlFor="update-profile-form-2"
                      className="form-label"
                    >
                      New Learnings (0/10) 
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={Formdata.New_Learnings}
                      type="number"
                      required
                      name="New_Learnings"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                  <div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
                    <label  htmlFor="update-profile-form-2"
                      className="form-label">Timly Completed Work (0/10)  </label>
                    <input
                      id="update-profile-form-1"
                      value={Formdata.Timly_Completed_Work}
                      required
                      minLength={10}
                      type="number"
                      name="Timly_Completed_Work"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                  <div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
                    <label  htmlFor="update-profile-form-2"
                      className="form-label">Accuracy in Work (0/10)  </label>
                    <input
                      id="update-profile-form-1"
                      value={Formdata.Accuracy_in_Work}
                      required
                      minLength={10}
                      type="number"
                      name="Accuracy_in_Work"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                  <div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
                    <label  htmlFor="update-profile-form-2"
                      className="form-label">Help to Other (0/5)</label>
                    <input
                      id="update-profile-form-1"
                      value={Formdata.Help_to_Other}
                      required
                      minLength={10}
                      type="number"
                      name="Help_to_Other"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                  <div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
                    <label  htmlFor="update-profile-form-2"
                      className="form-label">Desk Management (0/5)  </label>
                    <input
                      id="update-profile-form-1"
                      value={Formdata.Desk_Management}
                      required
                      minLength={10}
                      type="number"
                      name="Desk_Management"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                  <div className="col-span-12 2xl:col-span-3 mt-3 mb-3">
                    <label  htmlFor="update-profile-form-2"
                      className="form-label">Manager Review (0/10) </label>
                    <input
                      id="update-profile-form-1"
                      value={Formdata.Manager_Review}
                      required
                      minLength={10}
                      type="number"
                      name="Manager_Review"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>

                  
                </div>
                {updateShow && ( <button
                  type="submit"
                  onClick={() => updatedata(Formdata.id)}
                  className="btn btn-success text-white w-20 mt-3 "
                >
                  Update
                </button>)}
              {!updateShow && ( <button
                  type="submit"
                  // onClick={PostevPoinst}
                  className="btn btn-primary w-20 mt-3"
                >
                  Save
                </button>)}
                <button
                  type="submit"
                  onClick={() => adddata(1)}
                  className="btn btn-danger w-20 mt-3 ml-2"
                >
                  Cancel
                </button>
                <br />
                <br />
               
              </form>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
          <div className="flex mt-5 sm:mt-0">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
          </div>
       
          <div className="hidden md:block ml-auto text-slate-500">
          
          </div>
        </div>
        <div className="overflow-x-auto">
          {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <table className="table table-bordered" style={{ width: "160%" }}>
  <thead>
    <tr>
      <th className="whitespace-nowrap">#</th>
      <th className="whitespace-nowrap">Name</th>
      <th className="whitespace-nowrap">Behaviour<br/>on Floor</th>
     
      <th className="whitespace-nowrap">Participate<br/>in activity</th>
      <th className="whitespace-nowrap">Leave<br/>and Reason</th>
      <th className="whitespace-nowrap">How<br/>they speak</th>
      <th className="whitespace-nowrap">Late<br/>Login and Lunch</th>
      <th className="whitespace-nowrap">New<br/>Learnings</th>
      
      <th className="whitespace-nowrap">Timly<br/>Completed Work</th>
      <th className="whitespace-nowrap">Accuracy<br/>in Work</th>
      <th className="whitespace-nowrap">Help<br/>to Other</th>
      <th className="whitespace-nowrap">Desk<br/>Management</th>
      <th className="whitespace-nowrap">Manager<br/>Review</th>
      <th className="whitespace-nowrap">Action</th>
    </tr>
  </thead>
  <tbody>
    {currentData.length === 0 && (
      <tr>
        <td colSpan="12">No data available</td>
      </tr>
    )}
      {currentData.map((point, index) => (
        <tr key={index}>
          <td>{point.id}</td>    
          <td>{point.Employee}</td>
          <td>{point.Behaviour_on_Floor}</td> 
        
          <td>{point.Participate_in_activity}</td>
          <td>{point.Leave_and_Reason}</td>
          <td>{point.How_they_speak}</td>
          <td>{point.Late_Login_and_Lunch}</td>
          <td>{point.New_Learnings}</td>
        
          <td>{point.Timly_Completed_Work}</td>
          <td>{point.Accuracy_in_Work}</td>
          <td>{point.Help_to_Other}</td>
          <td>{point.Desk_Management}</td>
          <td>{point.Manager_Review}</td>
          <td><div className="flex lg:justify-center items-center cursor-pointer">
                              
                          
          <FaEdit title="Edit" style={{color:'gray'}} className="mr-2" onClick={() => editdata(point)} />
  <FaTrashAlt onClick={() => deletedata(point.id)} title="Delete" className="text-danger mr-2"/>                           




  </div></td>

        </tr>
      ))}
  </tbody>
</table>
          )}

          <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
            <br />
            <nav className="w-full sm:w-auto sm:mr-auto">
              {!loading ? (
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-4">
                  <ReactPaginate
        // previousLabel={"<<"}
        // nextLabel={">>"}
        breakLabel={"..."}
        pageCount={Math.ceil(evolutionPoints.length / dataPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        pageClassName={"page-item"} // Style for pagination numbers
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        previousLabel={<>&laquo;</>}
        nextLabel={<>&raquo;</>}
      />
                  </div>
                  <div className="col-md-4"></div>
                </div>
              ) : (
                <HashLoader
                  color="#5755d9"
                  size={30}
                  style={{ position: "absolute", right: "50%" }}
                />
              )}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvolutionPoints;
