import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedStatus, setConncetInfo, setRegisterer, setUserAgent } from '../redux/reducers/sipjs/sipjsSlice';
import dateFormat, { masks } from "dateformat";
const now = new Date();


const useDisconnectUa = () => {


    const { registerer, userAgent } = useSelector((state) => state.sipjs)
    const dispatch = useDispatch();

    const disconnectUA = () => {

        let connectedTime = dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT");
        try {
            registerer.unregister()
            userAgent.stop().then(() => {
                let connection_information = {
                    connectedTime: connectedTime,
                    connectingStatus: ConnectedStatus.failed,
                    connected: false
                }
                localStorage.setItem('connected', false);
                dispatch(setConncetInfo(connection_information))
            }).cath((error) => {
                console.debug('ncfsdjkfncvdjxnv ',error)
            })
        } catch (error) {
            console.log('wewefcdfn', error);
        }
    }
    return disconnectUA;
}

export default useDisconnectUa;
