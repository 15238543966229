import React,{useState, useEffect} from 'react'
import swal from "sweetalert";
import { BASEURL } from "../BASEURL";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom';

const JobAssessment = (props) => {
    const navigate = useNavigate();
    let [selectedposition, setselectedposition] = useState(""); 
    let [position, setposition] = useState(""); 
    let [loading, setLoading] = useState(false); 
    let [isshowoptionval, setisshowoption] = useState(true); 
    let [boxindex, setboxindex] = useState(""); 
    const [formData, setformData] = useState([{
        question: "",      
        question_type: "",      
        options: [],            
      }]);
      const params = useParams()
    
    useEffect(() => {
      getPositiondata()
    }, [])
    
    const getPositiondata = async() => {

      let appdata = JSON.parse(localStorage.getItem("isAuth"));
      let token;
      if (appdata != null) {
        token = appdata.usertoken;      
      }
      
        const formData = new FormData()
        formData.append('uuid',params.uuid)
        const res = await axios.post(BASEURL + "get-positionquestions", formData, {
            headers: {
              "Content-Type": "application/json",
              "Authorization":token
            },
        });

        if (res.data.status === 201) {
          if(res.data && res.data.data.length>0 ){
            setformData(JSON.parse(res.data.data[0].questions_data))
          }          
          if(res.data && res.data.positondata.length>0 ){
            setselectedposition(res.data?.positondata[0].name)
          }          
        }
    }
    
    const handlechange = (index, evnt,opitem="")=>{
        const { name, value } = evnt.target;
        const rowsInput = [...formData];
        if(name!=="options"){
            rowsInput[index][name] = value;
        }else{
            rowsInput[index][name][opitem] = value;            
        }
        setformData([...rowsInput]);
    }

    const addQuesion = async (elm) => {
       let rowsInput = {
            question: "",      
            question_type: "",      
            options: [],              
          }
        setformData([...formData, rowsInput])
    }
    const addoption = async (elm) => {
      //  let rowsInput = {
                
      //       }
        formData[elm].options.push([])
        setformData([...formData]);        
    }
    
    const removeoption = async (elm) => {
      //  let rowsInput = {
      //           option1:""
      //       }
        formData[elm].options.pop([])
        setformData([...formData]);        
    }

    const removeQuesion = async (elm) => {
        const rows = [...formData];
        rows.splice(elm, 1);
        setformData(rows);
    }
    const savedata = async () => {
        // console.log(position); 
        // console.log(formData); return
      // console.log(selectedFile); return
        if (
          formData[0].question === "" 
        ){
          swal({
            title: "Error",
            text: "Please Enter question",
            icon: "error",
            timer: 2000,
          });
          return;
        }
        // console.log(formData); return
        
        // setLoading(true);

        const formdata = new FormData();
        formdata.append("position_uuid", params.uuid);
        formdata.append("formdata", JSON.stringify(formData));        
        // console.log(formData);
 
        const res = await axios.post(BASEURL + "job-questions-add", formdata, {
            headers: {
              "Content-Type": "application/json",
            },
        });

            if (res.data.status === 201) {
              swal({
                title: "Job questions added successfully",
                text: res.message,
                icon: "success",
                timer: 2000,
              });
              navigate('/admin/13');
            } else {
              swal({
                title: "Error occured! Please try again",
                text: res.message,
                icon: "error",
                timer: 2000,
              });
            }
            setLoading(false);
        //   })
        //   .catch((err) => console.log(err));
        
      }; 

  const isshowoption = (i,e) =>{
    handlechange(i,e)
    if(e.target.value==3){
      setisshowoption(false)
      setboxindex(i)
    }else{
      setisshowoption(true)
      setboxindex("")
    }
    
  }    

  return (
    <div className="p-0">
    <div className="container " style={{
          height: "100vh",
          overflow: "scroll"
    }}>
      <div className="">
        

        <div className="xl:h-auto py-lg-5 xl:py-0 my-lg-10 xl:my-0" style={{overflow:"scroll"}}>
          <div className="">
            <div className="grid grid-cols-24 gap-12 mt-5">
                    <div className="intro-y col-span-12 lg:col-span-12">
                        
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-3 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="text-base mr-auto" style={{fontSize:"15px",fontWeight:500}}>
                                Job Questions                                </h2>                               
                            </div>
                            <div id="form-validation" className="p-5">
                                <div className="preview">
                                    
                                <div className="flex-1 mt-6 xl:mt-0 mb-5 pb-5">
                                <form className='pb-5 mb-5'>
                                    <div className="grid grid-cols-12 gap-x-5 text-left">
                                    
                                    <div className="col-span-12 2xl:col-span-12 mt-3 mb-3">
                                        <div>
                                        <label
                                            htmlFor="update-profile-form-1"
                                            className="form-label"
                                        >
                                            Selected Position <span className="text-danger">(*)</span> 
                                        </label>
                                          <input type="text" className='form-control' value={selectedposition}  readOnly/>
                                        
                                        </div>
                                    </div>
                                    </div>
                                    
                                    {formData && formData.length>0 && formData.map((item,i)=>{
                                        const {question, question_type, options}= item;
                                        return(
                                        <>
                                        <div className="grid grid-cols-12 gap-x-5 text-left">
                                        <div className="col-span-6 2xl:col-span-6">
                                        <label htmlFor="update-profile-form-2" className="form-label">
                                        
                                        </label>
                                        <br />
                                        <textarea
                                            name={'question'}
                                            value={question}
                                            placeholder='Enter Question'
                                            className="form-control"
                                            cols="80"
                                            rows="2"
                                            onChange={(e)=>handlechange(i,e)}                                            
                                        ></textarea>
                                        </div>
                                       <div className="col-span-3 2xl:col-span-3 mt-5">
                                        <select
                                            className="form-select"
                                            name={'question_type'}
                                            value={question_type}
                                            onChange={(e)=>isshowoption(i,e)}                                            
                                            >                                            
                                            <option value="0">Radiobox</option>
                                            <option value="1">Checkbox</option>
                                            <option value="2">Dropdown</option>
                                            <option value="3">Text</option>                          
                                            </select>
                                        </div>
                                    
                                        <div className="col-span-3 2xl:col-span-3 mt-2">
                                            {i+1===formData.length && 
                                            <button
                                            type="button"
                                            onClick={() => addQuesion()}
                                            className="btn btn-primary mt-3 mb-5"
                                            >
                                            + Add
                                            </button>   
                                        }
                                        { formData && formData.length>1 &&
                                        <button
                                        type="button"
                                        onClick={() => removeQuesion(item)}
                                        className="btn btn-danger  mt-3 mb-5 ml-2"
                                        >
                                        + Remove
                                        </button>   
                                        }
                                        </div>
                                        </div>
                                        <div className="grid grid-cols-12 gap-x-5 text-left">
                                        {/* && question_type!=="3" */}
                                        {options  && options.length>0 ? options.map((opitem,opindex)=>{
                                            return(
                                                <div className="col-span-2 2xl:col-span-2 mt-3 pt-2">
                                                
                                                    <input
                                                        id="update-profile-form-1"
                                                        placeholder='Enter Option'
                                                        required
                                                        type="text"
                                                        name={'options'}
                                                        value={opitem!=undefined?opitem:""}
                                                        className="form-control"
                                                        onChange={(e)=>handlechange(i,e,opindex)}                                            
                                                    />
                                                
                                                </div>
                                            );
                                        }) :null
                                     
                                        } 
                                        <div style={{display:"block"}} className="col-span-3 2xl:col-span-3 mt-2">
                                            <button
                                            type="button"
                                            onClick={() => addoption(i)}
                                            className="btn btn-default btn-sm w-50 pb-2 pt-2 mt-3 mb-5"
                                            >
                                            + Add Option
                                            </button>
                                            {options.length>1 &&
                                            <button
                                            type="button"
                                            onClick={() => removeoption(i)}
                                            className="btn btn-danger btn-sm w-50 pb-2 pt-2 mt-3 ml-2 mb-5"
                                            >
                                            + Remove Option
                                            </button>
                                            }
                                        </div>
                                        </div>
                                        </>
                                    )})}

                                    
                                    
                                    
                                    
                                    
                                    {loading ? (
                                <HashLoader
                                color="#5755d9"
                                size={30}
                                style={{ position: "absolute", left: "10%",paddingTop: "10%" }}
                                />
                            ) : 

                                    <div className="text-left">
                                    <button
                                    type="button"
                                    onClick={() => savedata()}
                                    className="btn btn-primary w-50 mt-3 mb-5"
                                    >
                                    Submit
                                    </button> 
                                    <Link
                                    type="button"
                                    to={"/admin/13"}
                                    className="ml-2 btn btn-default w-50 mt-3 mb-5"
                                    >
                                    Back
                                    </Link> 
                                    </div>        
                            }       
                                </form>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default JobAssessment