import React, { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import Select from "react-select";
import { name } from "sip.js";
import { BASEURL } from "./BASEURL";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Form } from "react-router-dom";

const Achievements = () => {
  const [updateShow, setUpdateShow] = useState(false)

	const [selectedImage, setSelectedImage] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [Achievement, setAchivement] = useState([]);
  const [badgeImage, setBadgeImage] = useState("")
	const [Formdata, setFormdata] = useState({
    message: "",
    earne_once_or_periodically: "",
    challenge_duration_day_type: "",
    challenge_duration_val: "",
    type_of_days_to_verify_val: "",
    karma_points: "",
    congratulation_message: "",
    // badeg_img: badgeImage
	});

console.log("badge image", badgeImage)

	const PosteAchievements = async (data) => {
		try {
			const response = await fetch(BASEURL + "addachievements", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({data,badgeImage}),
			});
			if (!response.ok) {
				throw new Error("Failed to submit form data");
			}
			setFormdata({
				message: "",
				earne_once_or_periodically: "",
        challenge_duration_day_type: "",
				challenge_duration_val: "",
				type_of_days_to_verify_val: "",
				karma_points: "",
				congratulation_message: "",
			});
      GeteAchievements();
			swal({  
				title: "Success",
				text: "Achievement points added successfully",
				icon: "success",
				timer: 2000,
			});
		} catch (error) {
			console.error("Error submitting form data:", error);
			// swal({
			//   title: "Oops...",
			//   text: "Something went wrong!",
			//   icon: "error",
			//   timer: 2000,
			// });
		}
	};

	useEffect(() => {
		GeteAchievements();
	}, []);
	const GeteAchievements = async () => {
		try {
			const response = await fetch(BASEURL + "achievement");
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const data = await response.json();
			// console.log("Achievements points api data:", data);
			setAchivement(data);
			// setFilteredAchivement(data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	const handleImageClick = (imageName) => {
		// if (selectedImage) {
		// 	const previousSelectedContainer = document.querySelector(
		// 		`[src="${selectedImage}"]`
		// 	);
		// 	previousSelectedContainer.classList.remove("selected");
		// }

		// const clickedContainer = document.querySelector(`[src="${imageUrl}"]`); 
		// clickedContainer.classList.add("selected");

    // const imageName = imageUrl.split("/").pop().split(".")[0];

   
    // console.log(imageName);
    setBadgeImage(imageName)
		// setSelectedImage(imageUrl);
	};

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	const handlechange = (e) => {
		const { name, value } = e.target;
		setFormdata({ ...Formdata, [name]: value });
	};


	const HandleSubmit = (e) => {
		e.preventDefault();
		PosteAchievements(Formdata);
    // console.log(Formdata)
	};
// console.log("achievment point",Achievement)


const editpoints = (item) => {
  console.log("challenge_duration_day_type",item.challenge_duration_day_type)
  setFormdata({
    id: item.id,
    Participate_in_activity : item.Participate_in_activity,
    Leave_and_Reason: item.Leave_and_Reason,
    message: item.message,
    earne_once_or_periodically: item.earne_once_or_periodically,
    challenge_duration_day_type: item.challenge_duration_day_type,
    challenge_duration_val: item.challenge_duration_val,
    type_of_days_to_verify_val: item.type_of_days_to_verify_val,
    karma_points: item.karma_points,
    congratulation_message: item.congratulation_message,
  })
  setUpdateShow(true)
}

const UpadatePoints = async (id) => {
  // e.preventDefault();
  console.log("updating points api: " + id);
  try {
    const response = await fetch(BASEURL + `update-achievements-point/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Formdata),
    });
    const data = await response.json();
   
    Swal.fire({
    
      icon: "success",
      title: "Evolution points updated successfully",
      showConfirmButton: false,
      timer: 1000
    });
  
    GeteAchievements()
   
   
    // Handle success or error response
  } catch (error) {
    console.error("Error:", error);
  }
};


	return (
		<>
			<div className="achivements-container">
				<div className="intro-y chat grid grid-cols-6 gap-2 mt-1  mr-2">
					<div className="col-span-12 lg:col-span-3 2xl:col-span-2">
						<div className="tab-content">
							<div
								id="chats"
								className="tab-pane active"
								role="tabpanel"
								aria-labelledby="chats-tab"
							>
								<div className="box p-3">
									<div className="  lg:pb-0 mt-0">
										<div className="relative text-slate-500 ">
											<input
												type="text"
												className="form-control py-3 px-4 border-transparent bg-slate-100 "
												placeholder="Search for users..."
												// onChange={(e) => filterdata(e)}
											/>  
										</div>
									</div>
								</div>
								<div className="chat__chat-list overflow-y-auto scrollbar-hidden ">
									<div
										className={
											"intro-x box relative flex items-center mt-2 p-2"
										}
									>
										<div className="ml-2 overflow-hidden">
											<div className="flex items-center">
												<div className="font-medium text-left d-flex  cursor-pointer">
													{Achievement.map(
														(item, index) => (
															<div
																style={{
																padding: "10px",
																}}
																key={index}
                                onClick={() => editpoints(item)}
															>
																<h1 >
																	{
																		item.message
																	}
																</h1>
															</div>
														)
													)}
												</div>
											</div>
										</div>
										<div className="ml-2 overflow-hidden">
											<div className="flex items-center">
												<div className="font-medium text-left d-flex"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<form method="POST" onSubmit={HandleSubmit}>
					<div className="badge-header">
						<div className="badge-header-section">
							<div className="badge-header-container">
								<label htmlFor="badge-input">Name</label>
								<input
									type="name"
									name="message"
									className="badge-input"
									onChange={handlechange}
									value={Formdata.message}
									placeholder=""
								></input>
							</div>
							<div className="badge-header-container">
								<label htmlFor="vehicle1">Active </label>
								<input
									type="checkbox"
									id="vehicle1"
									name="Active"
									value="Active"
								/>
							</div>
						</div>
						<div className="badge-header-section">
							<div className="badge-header-container">
								<div className="badge-delete-icon">
									<FaTrashAlt className="text-danger " />
								</div>
							</div>
							<div className="badge-header-container">
								<button
									type="button"
									className="btn btn-outline-dark btn-sm"
								>
									RESET
								</button>
							</div>
							<div className="badge-header-container">
								<button
									type="button"
									className="btn btn-outline-dark btn-sm"
									onClick={openModal}
								>
									PREVIEW
								</button>
							</div>
              {!updateShow && (<div className="badge-header-container">
            <button
									type="submit"
									className="btn btn-outline-dark btn-sm"
								>
									SAVE
								</button>
							</div>)}
              {updateShow && ( <div className="badge-header-container">
             <button
									type="button"
                  onClick={() => UpadatePoints(Formdata.id)}
									className="btn btn-outline-dark btn-sm"
								>
									UPDATE
								</button>
							</div>)}
						</div>
					</div>
					<div className="tw-panel-with-bg">
						<h1 className="tw-pseudo-label">Badge</h1>
						<div className="tw-ach-badge-select tw-ach--limit-width ">
							<div className="tw-flex-row ">
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick("trophy")
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/trophy.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"award"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/award.svg"
										alt=""
									/>
								</div>
								<div
									class=" badge-container"
									onClick={() =>
										handleImageClick(
											"superman"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/superman.svg"
										alt=""
									/>
								</div>
								<div
									class=" badge-container"
									onClick={() =>
										handleImageClick(
											"rocket"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/rocket.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"bird"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/bird.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										"owl"
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/owl.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"balloon"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/balloon.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"cake"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/cake.svg"
										alt=""
									/>
								</div>
								<div
									class=" badge-container"
									onClick={() =>
										handleImageClick(
											"candy"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/candy.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"coin"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/coin.svg"
										alt=""
									/>
								</div>
								<div
									class=" badge-container"
									onClick={() =>
										handleImageClick(
											"ninja"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/ninja.svg"
										alt=""
									/>
								</div>
								<div
									class=" badge-container"
									onClick={() =>
										handleImageClick(
											"crown"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/crown.svg"
										alt=""
									/>
								</div>
							</div>
							<div className="tw-flex-row">
								<div
									class=" badge-container"
									onClick={() =>
										handleImageClick(
											"rockstar"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/rockstar.svg"
										alt=""
									/>
								</div>
								<div
									class=" badge-container"
									onClick={() =>
										handleImageClick(
											"clock"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/clock.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"thumb-up"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/thumb-up.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"firecracker"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/firecracker.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"monster"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/monster.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"target"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/target.svg"
										alt=""
									/>
								</div>
								<div
									class=" badge-container"
									onClick={() =>
										handleImageClick(
											"palm"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/palm.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"angry-boss"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/angry-boss.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"pillow"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/pillow.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"pyramid"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/pyramid.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"shovel"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/shovel.svg"
										alt=""
									/>
								</div>
								<div
									class="badge-container"
									onClick={() =>
										handleImageClick(
											"sloth"
										)
									}
								>
									<img
										className="badge-image"
										src="https://jenya.trackabi.com/img/achievements/sloth.svg"
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>

					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel="Preview Image"
						className="modal-custom"
					>
						<div className="pop_bg_img">
							{selectedImage && (
								<div>
									<div className="pop_container">
										<img
											className="Selected_img"
											src={selectedImage}
											alt="Preview"
										/>
										<h1>
											You rock! You managed to add 8 hours
											daily during the last week!
										</h1>
									</div>
								</div>
							)}
						</div>
						<MdClose onClick={closeModal} />
					</Modal>

					<div class="input-group input-group-sm py-1 custom-input">
						<input
							type="text"
							name="congratulation_message"
							class="form-control"
							value={Formdata.congratulation_message}
							onChange={handlechange}
							placeholder="Congratulation message"
							aria-label="Sizing example input"
							aria-describedby="inputGroup-sizing-sm"
						/>
					</div>
					{/* Additional buttons for preview, reset, and save */}
					<div className="achievement-container">
						<div className="achievement-text-container">
							<h1 className="achievement-title">
								Can be earned once or periodically
							</h1>
							<h1>
								A user can earn the current achievement only
								once since joining the existing company account
								or regularly at the end of every period defined
								below
							</h1>
						</div>
						<div className="achievement-text-container">
							<div className="form-check">
								<input
									className="form-check-input"
									type="radio"
									name="earne_once_or_periodically"
									id="flexRadioDisabled"
									value="Can be earned once"
									defaultChecked={
										Formdata.earne_once_or_periodically ===
										"Can be earned once"
									}
                  
									onChange={handlechange}
								/>
								<label
									className="form-check-label"
									htmlFor="flexRadioDisabled"
								>
									Can be earned once
								</label>
							</div>
							<div className="form-check">
								<input
									className="form-check-input"
									type="radio"
									name="earne_once_or_periodically"
									id="flexRadioCheckedDisabled"
									value="Can be earned periodically"
                  checked={
										Formdata.earne_once_or_periodically ===
										"Can be earned periodically"
									}
									onChange={handlechange}
                 
								/>
								<label
									className="form-check-label"
									htmlFor="flexRadioCheckedDisabled"
								>
									Can be earned periodically
								</label>
							</div>
						</div>
					</div>
					<div className="achievement-container">
						<div className="achievement-text-container">
							<h1 className="achievement-title">
								Challenge duration
							</h1>
							<h1>
								The total time after which a user can get the
								current achievement if he successfully reaches
								the set goals. Not applicable to one-time
								achievements.
							</h1>
						</div>
						<div className="achievement-text-container">
							<div class="input-group input-group-sm mb-3 custom-input">
								<input
									type="text"
									name="challenge_duration_day_type"
									class="form-control"
									value={Formdata.challenge_duration_day_type}
									onChange={handlechange}
									aria-label="Sizing example input"
									aria-describedby="inputGroup-sizing-sm"
								/>
							</div>
							<div className="form-check">
								<select
									name="challenge_duration_val"
									class="custom-form-selection"
									value={Formdata.challenge_duration_val}
									onChange={handlechange}
									aria-label="Default select example"
								>
									<option selected>Select your option</option>
									<option value="1">Day(s)</option>
									<option value="2">Week(s)</option>
									<option value="3">Month(s)</option>
									<option value="4">Year(s)</option>
								</select>
							</div>
						</div>
					</div>
					<div className="achievement-container">
						<div className="achievement-text-container">
							<h1 className="achievement-title">
								Type of days to verify
							</h1>
							<h1>
								Days to take into account when validating the
								goals (business days, rest days, or all). E.g.,
								if you select Business days, the system will
								ignore all days off within the challenge
								duration period specified above.
							</h1>
						</div>
						<div className="achievement-text-container">
							<div className="form-check">
								<select
									class="custom-form-selection"
									name="type_of_days_to_verify_val"
									value={Formdata.type_of_days_to_verify_val}
									onChange={handlechange}
									aria-label="Default select example"
								>
									<option selected>Select your option</option>
									<option value="1">All days</option>
									<option value="2">Business days</option>
									<option value="3">Rest days</option>
								</select>
							</div>
						</div>
					</div>
					<div className="achievement-container">
						<div className="achievement-text-container">
							<h1 className="achievement-title">Karma points</h1>
							<h1>
								The number of points added to a user's karma
								after earning the current achievement. Use
								values between -100 and 100.
							</h1>
						</div>
						<div className="achievement-text-container">
							<div className="form-check">
								<div class="input-group input-group-sm mb-3 custom-input">
									<input
										type="text"
										class="form-control"
										name="karma_points"
										value={Formdata.karma_points}
										onChange={handlechange}
										aria-label="Sizing example input"
										aria-describedby="inputGroup-sizing-sm"
									/>
								</div>
							</div>
						</div>
					</div>

					{/* <div className="achievement-container">
      <div className="achievement-goal-container">
          <div className="goal-content">
          <h1 className="achievement-title">Goals</h1>
          <button type="button" class="btn btn-primary">Add new goal</button>
          </div>
          <div className="goal-content">
          <h1>Add at least one goal that should be reached to receive the current achievement</h1>
          </div>
      </div>
        </div> */}
					{/* <div className="achievement-container">
        <div className="p-2">
        <h1 className="achievement-title">Work at least the specified amount of time</h1>
        <h1>A user reaches this goal if he adds at least the specified amount of time into his timesheet, either daily or in total, during the current achievement challenge.</h1>
        <div className="custom-input flex">
        <input type="text" class="form-control " placeholder="Congratulation message" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"/>
        </div>
        <div className="p-2">
        <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDisabled"
              id="flexRadioCheckedDisabled"
            
             
            />
            <label
              className="form-check-label"
              htmlFor="flexRadioCheckedDisabled"
            >
              Can be earned periodically
            </label>
          </div>
          
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDisabled"
              id="flexRadioCheckedDisabled"
            
             
            />
            <label
              className="form-check-label"
              htmlFor="flexRadioCheckedDisabled"
            >
              Can be earned periodically
            </label>
          </div>
          </div>
        </div>
        </div>      */}
				</form>
			</div>
		</>
	);
};

export default Achievements;
