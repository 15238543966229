import axios from "axios";
import { API_BASEURL } from "../componunt/BASEURL";
import { getAccessTocken, getReferssTocken } from "./Utils";
import { BsGrid3X2GapFill } from "react-icons/bs";

let tocken = localStorage.getItem("access_tocken");









const Axios = axios.create({
    baseURL: API_BASEURL,
    headers: {
        "Content-type": "application/json",
    },

});

Axios.interceptors.request.use(async (config) => {
    const token = getAccessTocken();
    if (token) {
        config.headers["Authorization"] = ` Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

Axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry) {
            let refresh_tocken = localStorage.getItem('refresh_tocken')
            return axios
                .post(API_BASEURL + 'auth/token', {
                    refresh_token: refresh_tocken
                })
                .then(res => {
                    // console.log(res); return
                    const { status, acess_tocken } = res.data;
                    if (status == "SUCCESS") {
                        localStorage.setItem('access_tocken', acess_tocken);
                        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessTocken()
                        return Axios(originalRequest)
                    } else {
                        // alert('loout')
                    }

                    // if (res.status === 201) {
                    //     localStorage.setItem('access_tocken', refresh_tocken)
                    //     axios.defaults.headers.common['Authorization'] =
                    //         'Bearer ' + getAccessTocken()
                    //     return axios(originalRequest)
                    // }
                })
        }

        return Promise.reject(error);
    }
);

const instance = axios.create({
    baseURL: API_BASEURL
});
instance.interceptors.request.use(function () {/*...*/ });
export default Axios