import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { useParams } from "react-router-dom";
import { FaCheckCircle, FaCalendar, FaListAlt } from "react-icons/fa";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator.min.css";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { format } from "date-fns";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";


import { HashLoader } from "react-spinners";

const Leaves = () => {
  var today = new Date();
  var month = [];
  month[0] = "1";
  month[1] = "2";
  month[2] = "3";
  month[3] = "4";
  month[4] = "5";
  month[5] = "6";
  month[6] = "7";
  month[7] = "8";
  month[8] = "9";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";

  var monthNumber = month[today.getMonth()];

  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const [isVisible, setisVisible] = useState(false);
  const [monthYear, setmonthYear] = useState({});
  const currmonth = monthNumber;
  const curryear = today.getFullYear();
  const params = useParams();
  const [selectedmonth, setselectedmonth] = useState({year: curryear, month: Number(currmonth)});
  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value ? value.month : format(new Date(), "MM");
    const year = value ? value.year : format(new Date(), "yyyy");
    getData({ year: year, month: month });
  }, []);

  const columns = [
    { title: "Reason", field: "reason" },
    { title: "Day", field: "day" },
    {
      title: "Type",
      field: "type",
      width: 150,
      formatter: reactFormatter(<TypeVal />),
    },
    {
      title: "Status",
      field: "status",
      formatter: reactFormatter(<StatusVal />),
    },
    { title: "From", field: "from" },
    { title: "To", field: "to" },
    { title: "Applied On", field: "appliedon" },
  ];

  function StatusVal(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        {rowData.status === 1 ? (
          "Approved"
            // <FaCheckCircle style={{ fontSize: "20px" }} />
          
        ) : rowData.status === 0 ? (
          "Pending"          
            // <FaListAlt style={{ fontSize: "15px", color: "red" }} />
          
        ) : null}
      </>
    );
  }

  function TypeVal(props) {
    const rowData = props.cell._cell.row.data;
    let result = rowData.type.split(",");
    let resultcount = rowData.leavecountbytype.split(",");
    let typearr = [];
    result &&
      result.forEach((element) => {
        if (element === "LWP") {
          typearr.push({ type: element + " : " + resultcount[0] });
        } else if (element === "PL") {
          typearr.push({ type: element + " : " + resultcount[1] });
        } else if (element === "SL") {
          typearr.push({ type: element + " : " + resultcount[2] });
        } else if (element === "CL") {
          typearr.push({ type: element + " : " + resultcount[3] });
        } else if (element === "PTL") {
          typearr.push({ type: element + " : " + resultcount[4] });
        } else if (element === "MTL") {
          typearr.push({ type: element + " : " + resultcount[5] });
        } else {
          typearr.push({ type: "-" });
        }
      });

    return (
      <>
        {typearr &&
          typearr.map((item, i) => {
            return (
              <span className="ml-2">
                <span className="leavetype">{item.type}</span>
              </span>
            );
          })}
      </>
    );
  }

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async ({ year, month }) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-monthwise-leaves/${params.uuid}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        month,
        year,
        type: 0,
        uuid: params.uuid,
        // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          let attarr = [];
          if (res.data.length > 0) {
            res.data.forEach((element) => {
              let data = {
                reason: element.reason,
                type: element.leave_type,
                status: element.hrstatus,
                day:
                  element.noofdays > 1
                    ? element.noofdays + " Days"
                    : element.noofdays + " Day",
                from: format(new Date(element.start_date), "yyyy-MM-dd"),
                to: format(new Date(element.end_date), "yyyy-MM-dd"),
                appliedon: format(
                  new Date(element.request_date),
                  "yyyy-MM-dd HH:MM:SS"
                ),
                leavecountbytype: element.leavecountbytype,
              };

              attarr.push(data);
            });
          }
          setattdata(attarr);
        } else {
          setattdata([
            {
              reason: "No data Available",
              type: "",
              status: "",
              day: "",
              from: "",
              to: "",
              appliedon: "",
              leavecountbytype: "",
            },
          ]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const handleOnDismiss = () => {
    setisVisible(false);
  };

  const handleOnChange = (year, month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthname = monthNames[month - 1];
    localStorage.setItem(
      "monthyear",
      JSON.stringify({ year, monthname, month: month })
    );
    setisVisible(false);
    setmonthYear({ year, month: monthname });
    setselectedmonth({year, month: Number(month)})
    getData({ year, month });
  };

  const showMonthPicker = (e) => {
    setisVisible(true);
    e.preventDefault();
  };

  const getMonthValue = () => {
    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value
      ? value.monthname
      : monthYear && monthYear.month
      ? monthYear.month
      : 0;
    const year = value
      ? value.year
      : monthYear && monthYear.year
      ? monthYear.year
      : 0;

    var date = new Date();
    var curryear = date.getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return month && year
      ? `${month}-${year}`
      : monthNames[currmonth - 1] + "-" + curryear;
  };
  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center">
        <h2 className="text-lg font-medium mr-auto">All Leaves</h2>
      </div>
      <div className="intro-y box p-5 mt-5">
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <div className="flex mt-5 sm:mt-0">
            <div className="input-group mr-2 calenderdate">
              <input
                type="text"
                style={{ fontSize: "12px" }}
                onFocus={(e) => showMonthPicker(e)}
                value={getMonthValue()}
                className="form-control calenderdate1"
                id="basic-url"
                aria-describedby="basic-addon3"
              />
              <div className="input-group-append calendaricon">
                <FaCalendar
                  style={{ fontSize: "12px" }}
                  onClick={(e) => showMonthPicker(e)}
                />
              </div>
            </div>
            <ReactMonthPicker
              show={isVisible}
              lang={[
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ]}
              value={selectedmonth}
              onChange={handleOnChange}
              onDismiss={handleOnDismiss}
            />
          </div>
        </div>
        <div className="overflow-x-auto scrollbar-hidden">
          {loading ? (
            <div className="text-center m-5 p-5">
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            </div>
          ) : (
            <ReactTabulator
              columns={columns}
              data={attdata}
              options={options}
              className="mt-5 table-report table-report--tabulator"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Leaves;
