import React,{ useState } from 'react'

const Footer = () => {
  let [app_version, ] = useState(JSON.parse(localStorage.getItem("isAuth")).app_version);
  return (
    <>
    {/* <div data-url="https://rubick.left4code.com/page/side-menu/dark/dashboard-overview-1" className="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0 right-0 box border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 mr-10">
        <div className="mr-4 text-slate-600 dark:text-slate-200">Dark Mode</div>
        <div className="dark-mode-switcher__toggle  border"></div>
    </div> */}

            
    <div className="bottom-0 mt-5 box right-0  border h-8 px-5 flex items-center justify-center z-0 mb-0">
        <div className="mr-4 hidden sm:block text-slate-600 dark:text-slate-200">© 2023 | {app_version} Jenya</div>
        
        {/* <a href="1index0779.html?color_scheme=default" className="block w-8 h-8 cursor-pointer bg-blue-800 rounded-full border-4 mr-1 hover:border-slate-200 border-slate-300 dark:border-darkmode-800/80"></a> */}
        {/* <a href="indexcf7b.html?color_scheme=theme-1" className="block w-8 h-8 cursor-pointer bg-emerald-900 rounded-full border-4 mr-1 hover:border-slate-200 border-white dark:border-darkmode-600"></a>
        <a href="index4150.html?color_scheme=theme-2" className="block w-8 h-8 cursor-pointer bg-blue-900 rounded-full border-4 mr-1 hover:border-slate-200 border-white dark:border-darkmode-600"></a>
        <a href="indexc3da.html?color_scheme=theme-3" className="block w-8 h-8 cursor-pointer bg-cyan-900 rounded-full border-4 mr-1 hover:border-slate-200 border-white dark:border-darkmode-600"></a>
        <a href="index9fb4.html?color_scheme=theme-4" className="block w-8 h-8 cursor-pointer bg-indigo-900 rounded-full border-4 hover:border-slate-200 border-white dark:border-darkmode-600"></a> */}
    </div>
    </>
  )
}

export default Footer