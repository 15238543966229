import {
  GETSCREENSHOT,
  SET_SCREENSHOT,
  SET_ATTANDANCE,
  SHOW_LOADING,
  HIDE_LOADING,
  GETPRODUCTIVITY,
  GETATTANDANCE,
  GETLEAVES,
  SETLEAVES,
  SET_PRODUCTIVITY,
  TABTYPE,
  SET_STOPWATCH,
  SWITCH_THEME,
  GET_CURRENT_EMP
} from "../constants/Constant";

export const GetScreenshot = (data) => {
  
  return {
    type: GETSCREENSHOT,
    payload: data,
  };
};

export const SetScreenshot = (message) => {
  return {
    type: SET_SCREENSHOT,
    message,
  };
};

export const GetProductivity = (data) => {
  return {
    type: GETPRODUCTIVITY,
    payload: data,
  };
};

export const GetAttendance = (data) => {
  return {
    type: GETATTANDANCE,
    payload: data,
  };
};

export const GetLeaves = (data) => {
  return {
    type: GETLEAVES,
    payload: data,
  };
};

export const SetProductivity = (message) => {
  return {
    type: SET_PRODUCTIVITY,
    message,
  };
};

export const SetAttandance = (message) => {
  return {
    type: SET_ATTANDANCE,
    message,
  };
};

export const SetLeaves = (message) => {
  return {
    type: SETLEAVES,
    message,
  };
};

export const SetTabType = (data) => {
  return {
    type: TABTYPE,
    payload: [data],
  };
};

export const SetStopwatchTimer = (data) => {
  return {
    type: SET_STOPWATCH,
    payload: [data],
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
  };
};

export function onSwitchTheme(currentTheme) {
  // console.log(currentTheme);
  return {
    type: SWITCH_THEME,
    currentTheme
  };
}

export function onCurrentEmp(currentEmp) {
  // console.log(currentTheme);
  return {
    type: GET_CURRENT_EMP,
    currentEmp
  };
}