import React, { useEffect } from "react";
import { useLoadingContext } from "react-router-loading";

const HomeEmpliyee = () => {
  const loadingContext = useLoadingContext();
  useEffect(() => {
    const loading = async () => {
      loadingContext.done();
    };
    loading();
  }, []);

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 2xl:col-span-12">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 lg:col-span-12 mt-8 tblborder">
            <div className="intro-y block sm:flex items-center h-10">
              <h2 className="text-md font-medium truncate pt-5 mr-5 pb-5">
                Welcome to Jenya Tracking
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeEmpliyee;
