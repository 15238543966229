import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { useParams } from "react-router-dom";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import "react-month-picker/css/month-picker.css";
import { HashLoader } from "react-spinners";

const LeaveSummary = () => {
  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const params = useParams();

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { title: "Type", field: "type" },
    { title: "Total", field: "total", width: 200 },
    { title: "Taken", field: "taken" },
    { title: "Available", field: "available" },
  ];

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-leave-summary/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        uuid: params.uuid,
        role:userdata.role
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setattdata(res.data);
        } else {
          setattdata([
            {
              type: "No data Available",
              total: "",
              taken: "",
              available: "",
            },
          ]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center">
        <h2 className="text-lg font-medium mr-auto">Leave Summary</h2>
        <br />
      </div>
      <div className="intro-y box">
        <div className="scrollbar-hidden">
          {loading ? (
            <div className="text-center m-5 p-5">
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            </div>
          ) : (
            <ReactTabulator
              columns={columns}
              data={attdata}
              options={options}
              className="mt-5 table-report table-report--tabulator"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LeaveSummary;
