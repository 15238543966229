import React, { useState, useEffect } from "react";
import { BASEURL } from "./BASEURL";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";

import { FaPlus } from "react-icons/fa";

const Departments = () => {
	const [bkpdata, setbkpdata] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentItems, setCurrentItems] = useState(null);
	const [items, setitems] = useState([]);
	const [attdata, setattdata] = useState(null);
	const [punchtype, setpunchtype] = useState(0);

	const [data, setdata] = useState({
		id: "",
		start_time: "00:00:00",
		end_time: "00:00:00",
		description: "",
		date: "",
	});

	const [isadd, setisadd] = useState(false);
	let [loading, setLoading] = useState(false);
	let [msg, setmsg] = useState({
		type: "",
		msg: "",
	});

	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

	useEffect(() => {
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
		getData();
	}, []);

	useEffect(() => {
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
	}, [itemOffset]);

	const getData = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(
			BASEURL +
				`getUserDetails/` +
				JSON.parse(localStorage.getItem("isAuth")).uuid,
			{
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					type: 0,
					uuid: JSON.parse(localStorage.getItem("isAuth"))
						.companydata[0].uuid,
				}),
			}
		)
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					let attarr = [];
					if (res.attdata.length > 0) {
						res.attdata.forEach((element) => {
							// console.log(element);
							let date1 = new Date(
								format(new Date(element.date), "yyyy-MM-dd") +
									" " +
									element.start_time
							);
							let date2 = new Date(
								format(new Date(element.date), "yyyy-MM-dd") +
									" " +
									element.end_time
							);
							let diff = date2.getTime() - date1.getTime();
							let msec = diff;
							let hh = Math.floor(msec / 1000 / 60 / 60);
							msec -= hh * 1000 * 60 * 60;

							let mm = Math.floor(msec / 1000 / 60);
							msec -= mm * 1000 * 60;
							let ss = Math.floor(msec / 1000);
							msec -= ss * 1000;

							let mmm = mm >= 10 ? mm : "0" + mm;
							let hhh = hh >= 10 ? hh : "0" + hh;
							let sss = ss >= 10 ? ss : "0" + ss;

							let data = {
								id: element.id,
								uuid: element.uuid,
								name: element.name,
								user_id: element.username,
								date:
									element.date &&
									format(
										new Date(element.date),
										"yyyy-MM-dd"
									),
								attendance_id: element.attendance_id,
								ticketstatus: element.ticketstatus,
								// ticketStarttime: element.ticketStarttime!=null && format(new Date(element.ticketStarttime), "HH:mm:ss"),
								// ticketEndtime: element.ticketEndtime!=null && format(new Date(element.ticketEndtime), "HH:mm:ss"),
								ticketStarttime: element.ticketStarttime
									? element.ticketStarttime
									: "00:00:00",
								ticketEndtime: element.ticketEndtime
									? element.ticketEndtime
									: "00:00:00",
								in:
									element.start_time !== "00:00:00"
										? element.start_time
										: "00:00:00",
								out:
									element.end_time !== "00:00:00"
										? element.end_time
										: "00:00:00",
								duration:
									element.end_time !== "00:00:00"
										? hhh + ":" + mmm + ":" + sss
										: "00:00:00",
								details: "More Details",
							};
							// console.log(data);
							attarr.push(data);
						});
					}

					let arr = [];
					let arr1 = [];
					for (const key in attarr) {
						arr.push(key);
						if (key < 9) {
							arr1.push(key);
						}
					}

					setitems(arr);
					setCurrentItems(arr1);
					setPageCount(Math.ceil(arr.length / 10));

					setattdata(attarr);
					setbkpdata(attarr);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};

	const handlechange = (e) => {
		let value = e.target.value;
		setdata({
			...data,
			[e.target.name]: value,
		});
	};

	const savedata = async (elm) => {
		// console.log(punchtype);return
		if (
			punchtype === "" ||
			// data.start_time === "" ||
			// data.end_time === "" ||
			data.description === ""
		) {
			swal({
				title: "Error",
				text: "Please Enter value Required value",
				icon: "error",
				timer: 2000,
			});
			return;
		}
		// console.log(data); return
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `create-ticket/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				company_uuid: userdata.companydata[0].uuid,
				user_uuid: userdata.uuid,
				data,
				punchtype,
				tickettype: 0,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					userdata.companydata[0].name = data.name;
					userdata.companydata[0].support_access = data.supportaccess;
					localStorage.setItem("isAuth", JSON.stringify(userdata));
					setmsg({
						type: res.type,
						msg: res.message,
					});
					getData();
					setdata({
						id: "",
						start_time: "00:00:00",
						end_time: "00:00:00",
						description: "",
						date: "",
					});

					swal({
						title: "Success",
						text: res.message,
						icon: "success",
						timer: 2000,
					});
					setTimeout(() => {
						setmsg({
							type: "",
							msg: "",
						});
						setisadd(!isadd);
					}, 1000);
				} else {
					swal({
						title: "Error",
						text: res.message,
						icon: "error",
						timer: 2000,
					});
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};

	const editdata = (data) => {
		setisadd(true);
		setdata({
			id: data.id,
			start_time: data.in,
			end_time: data.out,
			description: "",
			date: data.date,
		});
	};

	const adddata = (data) => {
		setisadd(!isadd);
		setdata({
			id: "",
			start_time: "00:00:00",
			end_time: "00:00:00",
			description: "",
			date: "",
		});
	};

	const removeTicket = (id) => {
		swal({
			title: "Delete",
			text: `Please confirm if you want to Delete Ticket?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				setLoading(true);
				const myHeaders = new Headers();
				let userdata = JSON.parse(localStorage.getItem("isAuth"));
				if (userdata != null) {
					let token = userdata.usertoken;
					myHeaders.append("Authorization", token);
				}
				myHeaders.append("Content-Type", "application/json");
				fetch(BASEURL + `delete-ticket/${id}`, {
					method: "DELETE",
					headers: myHeaders,
				})
					.then((response) => response.json())
					.then((res) => {
						getData();
						swal({
							title: "Success",
							text: res.msg,
							icon: "success",
							timer: 2000,
						});
					})
					.catch((err) => console.log(err));
				setLoading(false);
			}
		});
	};

	const filterdata = (event) => {
		if (event.target.value === "") {
			loading ? <HashLoader /> : setattdata(bkpdata);

			let arr = [];
			for (const key in bkpdata) {
				arr.push(key);
			}
			setitems(arr);

			setCurrentItems(arr);
			setPageCount(Math.ceil(arr.length / 10));
		} else {
			var matches = bkpdata.filter(function (s) {
				return s.date.includes(`${event.target.value}`);
			});

			let arr = [];
			for (const key in matches) {
				arr.push(key);
			}
			setitems(arr);

			const newOffset = (matches.length * 10) % matches.length;
			setItemOffset(newOffset);
			setattdata(matches);
			const endOffset = itemOffset + 10;
			setCurrentItems(arr.slice(itemOffset, endOffset));
			setPageCount(Math.ceil(matches.length / 10));
		}
	};

	const handlePageClick = (event) => {
		const newOffset = (event.selected * 10) % items.length;

		setItemOffset(newOffset);
	};

	return (
		<div className="intro-y box lg:mt-5">
			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
				<h2 className="font-medium text-base mr-auto">
					All Punch Data
				</h2>
			</div>
			<div className="p-5">
				<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
					{isadd === true ? (
						<div
							className="flex-1 mt-6 xl:mt-0 p-3 mb-2"
							style={{
								border: "1px solid lightgray",
								borderRadius: "5px",
							}}
						>
							<form>
								<div className="grid grid-cols-12 gap-x-5">
									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Select Punch Type{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<select
											className="form-select"
											name="department"
											onChange={(e) =>
												setpunchtype(e.target.value)
											}
											value={punchtype}
										>
											<option key={0} value="0">
												Punch In
											</option>
											<option key={1} value="1">
												Punch Out
											</option>
										</select>
									</div>
									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-1"
												className="form-label"
											>
												Enter Start Time{" "}
												<span className="text-danger">
													(*)
												</span>
											</label>
											<input
												id="update-profile-form-1"
												value={data.start_time}
												type="text"
												required
												name="start_time"
												className="form-control"
												onChange={handlechange}
											/>
										</div>
									</div>

									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-1"
												className="form-label"
											>
												Enter End Time{" "}
												<span className="text-danger">
													(*)
												</span>
											</label>
											<input
												id="update-profile-form-1"
												value={
													data.end_time !== "-"
														? data.end_time
														: "00:00:00"
												}
												type="text"
												required
												name="end_time"
												className="form-control"
												onChange={handlechange}
											/>
										</div>
									</div>

									<div className="col-span-12 2xl:col-span-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Reason{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<br />
										<textarea
											name="description"
											className="form-control"
											cols="80"
											rows="2"
											onChange={handlechange}
											value={data && data.description}
										></textarea>
									</div>

									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Date
										</label>
										<input
											id="update-profile-form-1"
											value={data.date}
											readOnly
											required
											minLength={10}
											type="text"
											name="contactno"
											className="form-control"
											onChange={handlechange}
										/>
									</div>
								</div>

								<button
									type="button"
									onClick={() => savedata()}
									className="btn btn-primary w-20 mt-3"
								>
									Save
								</button>
								<button
									type="button"
									onClick={() => adddata(1)}
									className="btn btn-danger w-20 mt-3 ml-2"
								>
									Cancel
								</button>
								<br />
								<br />
								<span
									className={
										// msg.type === "success" ? "text-success" : "text-danger"
										"text-success"
									}
								>
									{msg.msg ? msg.msg : null}
								</span>
							</form>
						</div>
					) : null}
				</div>
				<div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
					<div className="flex mt-5 sm:mt-0">
						<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
							<input
								id="tabulator-html-filter-value"
								type="text"
								onChange={filterdata}
								className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
								placeholder="Search..."
							/>
						</div>
					</div>
					{/* <div className="hidden md:block ml-auto text-slate-500">
            Showing {itemOffset + 1} to  {attdata.length > itemOffset + 10 ? itemOffset + 10 : attdata.length} of {attdata.length} entries
          </div> */}
				</div>
				<div className="overflow-x-auto">
					{loading ? (
						<HashLoader
							color="#5755d9"
							size={30}
							style={{ position: "absolute", right: "50%" }}
						/>
					) : (
						<table
							className="table table-bordered"
							style={{ width: "100%" }}
						>
							<thead>
								<tr>
									<th className="whitespace-nowrap">Date</th>
									<th className="whitespace-nowrap">In</th>
									<th className="whitespace-nowrap">Out</th>
									<th className="whitespace-nowrap">
										Duration
									</th>
									<th className="whitespace-nowrap">
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								{currentItems && currentItems.length === 0 && (
									<tr>
										<td colSpan="8">No data available</td>
									</tr>
								)}
								{currentItems &&
									currentItems.length > 0 &&
									currentItems.map((item, i) => {
										return (
											<tr key={i}>
												<td>{attdata[item].date}</td>
												<td>{attdata[item].in}</td>
												<td>{attdata[item].out}</td>
												<td>
													{attdata[item].duration}
												</td>
												<td>
													<div className="flex lg:justify-center items-center cursor-pointer">
														<button
															className="btn btn-primary btn-sm mr-2"
															onClick={() =>
																editdata(
																	attdata[
																		item
																	]
																)
															}
														>
															<FaPlus
																title="Create Ticket"
																style={{
																	color: "white",
																}}
															/>
														</button>
														{attdata[item].id !==
															attdata[item]
																.attendance_id ||
														attdata[item]
															.ticketstatus ===
															3 ? (
															<></>
														) : attdata[item]
																.ticketstatus ===
														  0 ? (
															<button
																className="btn btn-warning btn-sm"
																title={
																	"Start Time: " +
																	attdata[
																		item
																	]
																		.ticketStarttime +
																	" || End Time: " +
																	attdata[
																		item
																	]
																		.ticketEndtime
																}
															>
																Pending{" "}
																<button
																	onClick={() =>
																		removeTicket(
																			attdata[
																				item
																			]
																				.attendance_id
																		)
																	}
																	title="Delete Ticket"
																	className="btn btn-danger btn-sm ml-2 p-2 pt-1 pb-0"
																>
																	x
																</button>
															</button>
														) : attdata[item]
																.ticketstatus ===
														  1 ? (
															<button className="btn btn-success text-white btn-sm">
																Approved
															</button>
														) : (
															<button className="btn btn-danger text-white btn-sm">
																Rejected
															</button>
														)}
														{/* <FaTrashAlt onClick={() => deletedata(userdata[item].uuid)} title="Delete" className="text-danger mr-2"/>                            
                            <FaSignInAlt onClick={() => signindata(userdata[item])} title={"Sign In as "+ userdata[item].name} style={{color:'#1E40AF'}} className="mr-2"/> 
                            </>:"-" } */}
													</div>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					)}

					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
						<br />
						<nav className="w-full sm:w-auto sm:mr-auto">
							{!loading ? (
								<div className="row">
									<div className="col-md-3"></div>
									<div className="col-md-4">
										<ReactPaginate
											pageCount={pageCount}
											pageRangeDisplayed={10}
											marginPagesDisplayed={1}
											onPageChange={handlePageClick}
											containerClassName="pagination"
											activeClassName="active"
											pageLinkClassName="page-link"
											breakLinkClassName="page-link"
											nextLinkClassName="page-link"
											previousLinkClassName="page-link"
											pageClassName="page-item"
											breakClassName="page-item"
											nextClassName="page-item"
											previousClassName="page-item"
											previousLabel={<>&laquo;</>}
											nextLabel={<>&raquo;</>}
										/>
									</div>
									<div className="col-md-4"></div>
								</div>
							) : null}
						</nav>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Departments;
